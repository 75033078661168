import {
    ApiClient
} from './web.service';
import {
    APIPATH
} from '../utils/constants/constants';

const apiClient = () => {
    return new ApiClient();
}

export const fetchCouponGrid = async (payload) => {
    return apiClient().post(`${APIPATH.COUPON}/list`, payload);
}

export const createCoupon = async (payload) => {
    return apiClient().post(APIPATH.COUPON, payload);
}

export const updateCoupon = async (id, payload) => {
    return apiClient().put(`${APIPATH.COUPON}/${id}`, payload);
}

export const fetchByIdCoupon = async (id) => {
    return apiClient().get(`${APIPATH.COUPON}/${id}`);
}

export const deleteCoupon = async (id) => {
    return apiClient().delete(`${APIPATH.COUPON}/${id}`)
}