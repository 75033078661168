import { useContext, useEffect } from "react";

import {
    AppContext
} from "../../core/context/appContextProvider";

const useDashboard = () => {

    const {
        setTitle
    } = useContext(AppContext)

    useEffect(() => {
        setTitle('Dashboard');
    }, [setTitle]);

    return {
        data: null
    }
}

export default useDashboard;