import { Box, styled } from '@mui/material';

export const ColorSelectorContainer =styled(Box)(({
    theme
  })=>({
    display: "flex",
    justifyContent: "space-between",
    margin: theme.spacing(4, 0),
    "&:first-of-type": {
      marginTop: 0
    },
    "&:last-child": {
      marginBottom: 0,
    }
  }));