
import { memo } from "react";
import { useNavigate } from "react-router-dom";

import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";

import AddIcon from "@mui/icons-material/Add";

import Button from "../../components/Buttons/Button/Button";
import DataGrid from "../../components/DataGrid/DataGrid";
import DeleteConfirmationDialog from "../../components/Modal/DeleteConfirmationDialog/DeleteConfirmationDialog";

import UseCouponHooks from "./coupon.hooks";
import { HeaderRow } from "./coupon.styles";

const Module = () => {
    const {
        list,
        headerList,
        rightForCoupon,
        showDeleteModal,
        handleDelete,
        setShowDeleteModal
    } = UseCouponHooks();
    const navigate = useNavigate();
    return (
        <Container maxWidth={false}>
            <Box textAlign={'left'}>
                <HeaderRow>
                    <Typography variant="pageTitle">Coupon</Typography>
                    {rightForCoupon.add ?
                        <Button
                            id="btn_add_new_role"
                            variant="contained"
                            icon={<AddIcon />}
                            text="New Coupon"
                            color="primary"
                            onClick={() => navigate('/coupon/add')}
                        />
                    : null}
                </HeaderRow>
                <DataGrid
                    id="client_data_grid"
                    dataList={list || []}
                    headerList={headerList || []}
                    minHeight="70vh"
                    checkboxSelection={false}
                    // noDataMessage={(loadingClients || loading) ? "Fetching Data..." : undefined}
                    autoHeight={false}
                    // handleOnView={handleOnView}
                    activeHeaderFields={6}
                // handleUpdateHeader={handleUpdateHeader}
                />
            </Box>
            <DeleteConfirmationDialog
                open={showDeleteModal}
                id="delete_confirmation_modal"
                primaryButtonAction={handleDelete}
                secondaryButtonAction={() => setShowDeleteModal(false)}
            />
        </Container>
    );
}

export default memo(Module);