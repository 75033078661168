import { Box, Chip as ChipCell, Popover, styled } from "@mui/material";

export const PopoverColumnArrow = styled(Popover)`
  & .MuiPaper-root {
    border-radius: 10px;
    background-color: transparent;
    box-shadow: 1px 21px 23px -5px rgb(0 0 0 / 20%),
      -0px 8px 14px -10px rgb(0 0 0 / 0%), 3px 0.3px 30px 25px rgb(0 0 0 / 1%) !important;
    -webkit-box-shadow: 0 0 22px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 0 22px rgba(0, 0, 0, 0.3);
  }
`;

export const ChipBox = styled(Box)`
        position: "relative",
        mt: "10px",
        "&::before": {
          backgroundColor: "white",

          content: '""',
          display: "block",
          position: "absolute",
          width: 12,
          height: 12,
          top: -6,
          transform: "rotate(45deg)",
          left: "calc(30% - 6px)",
        },
`;

export const Chip = styled(ChipCell)(
  ({ theme, display }) => ({
    marginRight: display === "block" ? theme.spacing(1.2) : "",
    marginBottom: display === "block" ? theme.spacing(1.2) : "",
  })
);
export const StyledBox = styled(Box)(
  ({ theme, display }) => ({
    display: display,
    justifyContent: "flex-start",
    gridTemplateColumns: "0.2fr 0.2fr auto",
  })
);
