import { ErrorBoundary } from "react-error-boundary";
import { LicenseInfo } from "@mui/x-license-pro";
// import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import Routes from './routes';
import ThemeConfig from "./styles/theme/index";

import FallbackScreen from "./components/Fallback/FallbackScreen";

import { AppProvider } from "./core/context/appContextProvider";
import { NavBarProvider } from "./core/context/navbarContext";

import './App.css';

function App() {
  LicenseInfo.setLicenseKey(process.env.REACT_APP_DATA_GRID_X_KEY);
  return (
    // <Router>
      <AppProvider>
        <ThemeConfig>
          <ErrorBoundary FallbackComponent={FallbackScreen}>
            <NavBarProvider>
              <div className="App container">
                <Routes/>
              </div>
            </NavBarProvider>
          </ErrorBoundary>
        </ThemeConfig>
      </AppProvider>
    // </Router>
  );
}

export default App;
