import { useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import { useForm, useFieldArray } from 'react-hook-form';
import moment from 'moment'

import { AppContext } from '../../../core/context/appContextProvider';
import {
    getFormData,
    onlinePayList,
    socialMediaDefaultValue
} from '../../../utils/helpers';

import {
    createClient,
    updateClient,
    fetchByIdClient,
    updateClientDetail,
    fetchClientService,
    addClientService,
    updateClientService,
    deleteClientService,
    fetchClientDocument,
    updateClientDocument,
    deleteClientDocument,
    updateClientGallery,
    fetchClientGallery,
    deleteClientGallery,
    updateClientBankDetail,
    fetchClientBankDetail,
    deleteClientBankDetail,
    updateClientSocialMedia,
    fetchClientSocialMedia,
    updateClientOnlinePay,
    fetchClientOnlinePay,
    updateClientVideoLink,
    fetchClientVideoLink,
    deleteClientVideoLink,
} from '../../../services/client.service';

import {
    fetchDropDownCardTheme
} from '../../../services/cardTheme.service';


const UseClientHooks = () => {
    const {
        loginUser,
        setLoading,
        informationAlert
    } = useContext(AppContext);

    const navigate = useNavigate();
    const { mode, id } = useParams();

    const [openId, setOpenId] = useState(0);
    const [clientData, setClientData] = useState(null);
    const [serviceList, setServiceList] = useState(null);
    const [openServiceBox, setOpenServiceBox] = useState(false);
    const [galleryList, setGalleryList] = useState([]);
    const [themeList, setThemeList] = useState([]);

    const handleOpenId = (id) => {
        if(openId === id) {
            setOpenId(0);
        } else {
            setOpenId(id);
        }
    }

    const basicDetailForm = useForm({
        defaultValues: {
            firstName: '',
            lastName: '',
            email: '',
            phoneNumber: ''
        },
        mode: 'onBlur'
    })

    // Client Detail Form

    const clientDetailForm = useForm({
        defaultValues: {
            cardThemeID: 1,
            feviconIcon: '',
            logo: '',
            whatsappNumber: '',
            officeLocation: [{
                address: ''
            }],
            email2: null,
            website: '',
            phoneNumber2: null,
            companyName: '',
            gstNo: null,
            establishDate: moment(new Date()).format('yyyy-MM-DD'),
            natureOfBusiness: '',
            description: ''
        },
        mode: 'onBlur'
    });

    const officeLocationArrayField = useFieldArray({
        control: clientDetailForm.control,
        name: 'officeLocation'
    });

    const handleAddOfficeLocation = () => {
        const index = clientDetailForm.getValues('officeLocation').length;
        officeLocationArrayField.append({
            index: (index + 1),
            address: ''
        })
    }

    const handleRemoveOfficeLocation = (index) => {
        officeLocationArrayField.remove(index);
    }

    // Document Detail Form

    const documentDetailForm = useForm({
        defaultValues: {
            documentList: [{
                id: null,
                docID: null,
                index: 0,
                title: '',
                document: ''
            }]
        }
    });

    const documentDetailArrayField = useFieldArray({
        control: documentDetailForm.control,
        name: 'documentList'
    });

    const handleAddDocument = () => {
        const index = documentDetailForm.getValues('documentList').length;
        documentDetailArrayField.append({
            id: null,
            docID: null,
            index: (index + 1),
            title: '',
            document: ''
        })
    }

    const handleRemoveDocument = async (index) => {
        const selectedData = documentDetailForm.getValues(`documentList.${index}`);
        if(selectedData.docID) {
            const {success, message} = await deleteClientDocument(selectedData.docID);
            if(success) {
                informationAlert(message, 'success');
                fetchClientDocumentById(id);
            } else {
                informationAlert(message, 'error');
            }
        } else {
            documentDetailArrayField.remove(index);
        }
    }

    // Service Form

    const serviceForm = useForm({
        defaultValues: {
            id: '',
            type: 'SERVICE',
            title: '',
            description: '',
            image: null,
            discountPrice: '',
            price: ''
        }
    });

    const handleAddService = () => {
        setOpenServiceBox(true);
        serviceForm.reset({
            id: '',
            type: 'SERVICE',
            title: '',
            description: '',
            image: '',
            discountPrice: '',
            price: ''
        });
    }

    const handleEditService = (data) => {
        serviceForm.reset({
            id: data.id,
            type: data.type,
            title: data.title,
            description: data.description,
            image: data.image,
            discountPrice: data.discountPrice,
            price: data.price
        });
        setOpenServiceBox(true);
    }

    const handleRemoveService = async (id) => {
        const {success, message} = await deleteClientService(id);
        if(success) {
            informationAlert(message, 'success');
            fetchClientServiceById(id);
        } else {
            informationAlert(message, 'error');
        }
    }

    // Gallery Form

    const galleryForm = useForm({
        defaultValues: {
            id: null,
            images: []
        }
    });

    const handleRemoveSelectedFiles = (index) => {
        const updatesFiles = galleryForm.getValues('images')?.filter((res, ind) => index !== ind);
        galleryForm.setValue('images', updatesFiles);
    }

    const handleRemoveUploadedFiles = async (galleryId) => {
        try {
            setLoading(true);
            const { success, message } = await deleteClientGallery(galleryId);
            if(success) {
                fetchClientGalleryById(id);
            } else {
                informationAlert(message, "error");
            }
        } catch(err) {
            informationAlert(err.message, "error");
        } finally {
            setLoading(false);
        }
    }

    const videoLinkForm = useForm({
        defaultValues: {
            videoLinkList: [{
                index: 0,
                videoID: null,
                link: ''
            }]
        }
    });

    const videoLinkArrayField = useFieldArray({
        control: videoLinkForm.control,
        name: 'videoLinkList'
    });

    const handleAddVideoLink = () => {
        const index = videoLinkForm.getValues('videoLinkList').length;
        videoLinkArrayField.append({
            index: (index + 1),
            videoID: null,
            link: ''
        });
    };

    const handleRemoveVideoLink = async (index) => {
        const selectedData = videoLinkForm.getValues(`videoLinkList.${index}`);
        if(selectedData.videoID) {
            const {success, message} = await deleteClientVideoLink(selectedData.videoID);
            if(success) {
                informationAlert(message, 'success');
                fetchClientVideoLinkById(id);
            } else {
                informationAlert(message, 'error');
            }
        } else {
            videoLinkArrayField.remove(index);
        }
    }

    // Bank Detail Form

    const bankDetailForm = useForm({
        defaultValues: {
            bankDetailList: [{
                bankDetailId: null,
                index: 0,
                bankName: '',
                accountNumber: '',
                accountHolderName: '',
                accountType: '',
                ifscCode: ''
            }]
        }
    });

    const bankDetailArrayField = useFieldArray({
        control: bankDetailForm.control,
        name: 'bankDetailList'
    });

    const handleAddBankDetail = () => {
        const index = bankDetailForm.getValues('bankDetailList').length;
        bankDetailArrayField.append({
            bankDetailId: null,
            index: (index + 1),
            bankName: '',
            accountNumber: '',
            accountHolderName: '',
            accountType: '',
            ifscCode: ''
        })
    }

    const handleRemoveBankDetail = async (index) => {
        const selectedData = bankDetailForm.getValues(`bankDetailList.${index}`);
        if(selectedData.bankDetailId) {
            const {success, message} = await deleteClientBankDetail(selectedData.bankDetailId);
            if(success) {
                informationAlert(message, 'success');
                fetchClientBankDetailById(id);
            } else {
                informationAlert(message, 'error');
            }
        } else {
            bankDetailArrayField.remove(index);
        }
    }

    // Online Payment Form

    const onlinePayForm = useForm({
        defaultValues: {
            onlinePayList: onlinePayList
        }
    });

    const onlinePayArrayField = useFieldArray({
        control: onlinePayForm.control,
        name: 'onlinePayList'
    });

    // Social Media Form
    const socialMediaForm = useForm({
        defaultValues: {
            socialMediaList: socialMediaDefaultValue
        }
    });

    const socialMediaArrayField = useFieldArray({
        control: socialMediaForm.control,
        name: 'socialMediaList'
    });

    const fetchById = async (id) => {
        try {
            if(id) {
                setLoading(true);
                const { success, message, data } = await fetchByIdClient(id);
                if(success) {
                    setClientData(data);
                    clientDetailForm.setValue('officeLocation', []);
                    basicDetailForm.setValue('firstName', data?.px_user?.firstName);
                    basicDetailForm.setValue('lastName', data?.px_user?.lastName);
                    basicDetailForm.setValue('email', data?.px_user?.email);
                    basicDetailForm.setValue('phoneNumber', data?.px_user?.phoneNumber);
                    clientDetailForm.setValue('cardThemeID', data?.px_card_theme?.id);
                    clientDetailForm.setValue('companyName', data?.companyName);
                    clientDetailForm.setValue('description', data?.description);
                    clientDetailForm.setValue('email2', data?.email2);
                    clientDetailForm.setValue('establishDate',moment(new Date(data?.establishDate)).format('yyyy-MM-DD'));
                    clientDetailForm.setValue('gstNo', data?.gstNo);
                    clientDetailForm.setValue('natureOfBusiness', data?.natureOfBusiness);
                    clientDetailForm.setValue('officeLocation', JSON.parse(data.officeLocation))
                    clientDetailForm.setValue('phoneNumber2', data?.phoneNumber2);
                    clientDetailForm.setValue('website', data?.website);
                    clientDetailForm.setValue('whatsappNumber', data?.whatsappNumber);
                    clientDetailForm.setValue('logo', data?.logo);
                    clientDetailForm.setValue('feviconIcon', data?.feviconIcon);
                } else {
                    informationAlert(message, "error");
                }
            }
        } catch(err) {
            informationAlert(err.message, "error");
        } finally {
            setLoading(false);
        }
    }

    const fetchClientDocumentById = async (id) => {
        try {
            documentDetailForm.setValue('documentList', []);
            setLoading(true);
            const { success, message, data } = await fetchClientDocument(id);
            if(success) {
                documentDetailForm.setValue('documentList', data?.map((item, index) => ({docID: item.id, index: index, title: item.title, document: item.link})));
            } else {
                informationAlert(message, "error");
            }
        } catch(err) {
            informationAlert(err.message, "error");
        } finally {
            setLoading(false);
        }
    }

    const fetchClientServiceById = async (id) => {
        try {
            serviceForm.setValue('serviceList', []);
            setLoading(true);
            const { success, message, data } = await fetchClientService(id);
            if(success) {
                setServiceList(data);
            } else {
                informationAlert(message, "error");
            }
        } catch(err) {
            informationAlert(err.message, "error");
        } finally {
            setLoading(false);
        }
    }

    const fetchClientGalleryById = async (id) => {
        try {
            galleryForm.setValue('galleryList', []);
            setLoading(true);
            const { success, message, data } = await fetchClientGallery(id);
            if(success) {
                setGalleryList(data);
                galleryForm.reset();
            } else {
                informationAlert(message, "error");
            }
        } catch(err) {
            informationAlert(err.message, "error");
        } finally {
            setLoading(false);
        }
    }

    const fetchClientBankDetailById = async (id) => {
        try {
            setLoading(true);
            bankDetailForm.setValue('bankDetailList', []);
            const {success, message, data} = await fetchClientBankDetail(id);
            if(success) {
                bankDetailForm.setValue('bankDetailList',
                    data?.map((item, index) => ({
                        bankDetailId: item.id,
                        index: index,
                        bankName: item.bankName,
                        accountNumber: item.accountNumber,
                        accountHolderName: item.accountHolderName,
                        accountType: item.accountType,
                        ifscCode: item.ifscCode
                    }))
                )
            } else {
                informationAlert(message, "error");
            }
        } catch(err) {
            informationAlert(err.message, "error");
        } finally {
            setLoading(false);
        }
    }

    const fetchClientSocialMediaById = async (id) => {
        try {
            setLoading(true);
            socialMediaForm.setValue('socialMediaList', []);
            const {success, message, data} = await fetchClientSocialMedia(id);
            if(success) {
                if(data && data.length > 0) {
                    socialMediaForm.setValue('socialMediaList',
                        data?.map((item, index) => ({
                            socialMediaID: item.id,
                            index: index,
                            name: item.name,
                            link: item.link
                        }))
                    );
                } else {
                    socialMediaForm.setValue('socialMediaList', socialMediaDefaultValue);
                }
            } else {
                informationAlert(message, "error");
            }
        } catch(err) {
            informationAlert(err.message, "error");
        } finally {
            setLoading(false);
        }
    }

    const fetchClientOnlinePayById = async (id) => {
        try {
            setLoading(true);
            onlinePayForm.setValue('onlinePayList', []);
            const {success, message, data} = await fetchClientOnlinePay(id);
            if(success) {
                if(data && data.length > 0) {
                    onlinePayForm.setValue('onlinePayList',
                        data?.map((item, index) => ({
                            onlinePayID: item.id,
                            index: index,
                            appName: item.appName,
                            number: item.number,
                            upi: item.upi,
                            qrCode: item.qrCode || null
                        }))
                    );
                } else {
                    onlinePayForm.setValue('onlinePayList', onlinePayList);
                }
            } else {
                informationAlert(message, "error");
            }
        } catch(err) {
            informationAlert(err.message, "error");
        } finally {
            setLoading(false);
        }
    }

    const fetchClientVideoLinkById = async (id) => {
        try {
            setLoading(true);
            videoLinkForm.setValue('videoLinkList', []);
            const {success, message, data} = await fetchClientVideoLink(id);
            if(success) {
                if(data && data.length > 0) {
                    videoLinkForm.setValue('videoLinkList',
                        data?.map((item, index) => ({
                            videoID: item.id,
                            index: index,
                            link: item.link
                        }))
                    )
                } else {
                    videoLinkForm.setValue('videoLinkList', []);
                }
            } else {
                informationAlert(message, "error");
            }
        } catch(err) {
            informationAlert(err.message, "error");
        } finally {
            setLoading(false);
        }
    }

    const fetchList = async () => {
        try {
            const response = await fetchDropDownCardTheme();
            if(response.success) {
                setThemeList(response.data);
            } else {
                informationAlert(response.message, "error");
            }
        } catch(err) {
            informationAlert(err.message, "error");
        } finally {
            setLoading(false);
        }
    }

    const isAddMode = useMemo(() => {
        return mode.toLowerCase() === 'add';
    }, [mode]);

    useEffect(() => {
        if(id) {
            fetchById(id);
            fetchClientDocumentById(id);
            fetchClientServiceById(id);
            fetchClientGalleryById(id);
            fetchClientBankDetailById(id);
            fetchClientSocialMediaById(id);
            fetchClientOnlinePayById(id);
            fetchClientVideoLinkById(id);
        }
        fetchList();
        // eslint-disable-next-line
    }, [id]);

    const basicSubmit = async (info) => {
        const payload = {
            ...info,
            userType: 'CUSTOMER',
            createdBy: loginUser?.id
        };
        const { success, message } = id ? await updateClient(payload) : await createClient(payload);
        if(success) {
            informationAlert(message, "success");
            navigate('/client');
        } else {
            informationAlert(message, "error");
        }
    };

    const clientDetailSubmit = async (info) => {
        const body = {
            ...info,
            officeLocation: JSON.stringify(info.officeLocation), //info.officeLocation?.map((res) => res.address).join(','),
            logo: typeof info?.logo === 'string' ? info.logo : info.logo[0],
            feviconIcon: typeof info?.feviconIcon === 'string' ? info.feviconIcon : info?.feviconIcon[0],
        }
        const {success, message } = await updateClientDetail(id, getFormData(body));
        if(success) {
            informationAlert(message, "success");
            fetchById(id);
        } else {
            informationAlert(message, "error");
        }
    };

    const documentDetailSubmit = async (info) => {
        const documentFormData = new FormData();
        const body = [];
        info.documentList?.forEach((item) => {
            if(typeof item.document !== 'string') {
                documentFormData.append('document', item.document);
            }
            body.push({
                id: item.docID,
                clientID: id,
                title: item.title,
                link: typeof item.document === 'string' ? item.document : '',
                createdBy: loginUser?.id
            });
        })
        documentFormData.append('body', JSON.stringify(body));
        const {success, message } = await updateClientDocument(documentFormData);
        if(success) {
            informationAlert(message, "success");
            fetchClientDocumentById(id);
        } else {
            informationAlert(message, "error");
        }
    }

    const serviceSubmit = async (info) => {
        const serviceFormData = new FormData();
        serviceFormData.append('clientID', id);
        serviceFormData.append('type', info.type);
        serviceFormData.append('title', info.title);
        serviceFormData.append('description', info.description);
        serviceFormData.append('discountPrice', info.discountPrice);
        serviceFormData.append('price', info.price);
        if(typeof info.image !== 'string') {
            serviceFormData.append('serviceImage', info.image);
        } else {
            serviceFormData.append('image', info.image);
        }
        const {success, message} = info.id ? await updateClientService(info.id, serviceFormData) : await addClientService(serviceFormData);
        if(success) {
            informationAlert(message, "success");
            setOpenServiceBox(false);
            fetchClientServiceById(id);
        } else {
            informationAlert(message, "error");
        }
    }

    const gallerySubmit = async (info) => {
        const galleryFormData = new FormData();
        const body = [];
        info.images?.forEach((item) => {
            body.push({
                id: null,
                clientID: id,
                createdBy: loginUser?.id
            });
        });
        galleryFormData.append('body', JSON.stringify(body));
        info.images?.forEach((item) => {
            if(typeof item !== 'string') {
                galleryFormData.append('galleryImage', item)
            }
        })
        const {success, message} = await updateClientGallery(id, galleryFormData);
        if(success) {
            informationAlert(message, "success");
            fetchClientGalleryById(id);
        } else {
            informationAlert(message, "error");
        }
    }

    const videoLinkSubmit = async (info) => {
        const body = [];
        info.videoLinkList?.forEach((item) => {
            body.push({
                id: item.videoID,
                clientID: id,
                link: item.link,
                createdBy: loginUser?.id
            })
        });
        const {success, message} = await updateClientVideoLink(body);
        if(success) {
            informationAlert(message, "success");
            fetchClientVideoLinkById(id);
        } else {
            informationAlert(message, "error");
        }
    }

    const bankDetailSubmit = async (info) => {
        const body = [];
        info.bankDetailList?.forEach((item) => {
            body.push({
                id: item.bankDetailId,
                clientID: id,
                bankName: item.bankName,
                accountNumber: item.accountNumber,
                accountHolderName: item.accountHolderName,
                accountType: item.accountType,
                ifscCode: item.ifscCode,
                createdBy: loginUser?.id
            });
        });
        const {success, message} = await updateClientBankDetail(body);
        if(success) {
            informationAlert(message, "success");
            fetchClientBankDetailById(id);
        } else {
            informationAlert(message, "error");
        }
    }

    const onlinePaySubmit = async (info) => {
        const onlinePayFormData = new FormData();
        const body = [];
        info.onlinePayList?.forEach((item) => {
            body.push({
                id: item.onlinePayID,
                clientID: id,
                appName: item.appName,
                number: item.number,
                upi: item.upi,
                qrCode: (item.qrCode && typeof item.qrCode === 'string') ? item.qrCode : '',
                createdBy: loginUser?.id
            });
        });
        onlinePayFormData.append('body', JSON.stringify(body));
        info.onlinePayList?.forEach((item) => {
            if(item.qrCode && typeof item.qrCode !== 'string') {
                onlinePayFormData.append('qrCode', item.qrCode)
            }
        });
        const {success, message} = await updateClientOnlinePay(onlinePayFormData);
        if(success) {
            informationAlert(message, "success");
            fetchClientOnlinePayById(id);
        } else {
            informationAlert(message, "error");
        }
    }

    const socialMediaSubmit = async (info) => {
        const body = [];
        info.socialMediaList?.forEach((item) => {
            body.push({
                id: item.socialMediaID,
                clientID: id,
                name: item.name,
                link: item.link,
                createdBy: loginUser?.id
            })
        });
        const {success, message} = await updateClientSocialMedia(body);
        if(success) {
            informationAlert(message, "success");
            fetchClientSocialMediaById(id);
        } else {
            informationAlert(message, "error");
        }
    }

    const downloadQrCode = () => {
        const canvas = document.getElementById("react-qrcode-logo");
        if(canvas) {
            const img = canvas.toDataURL("image/png");
            const link = document.createElement('a');
            link.href = img;
            link.download = `qr-code`;
            document.body.appendChild(link);
            link.click();
        }
    }

    return {
        mode,
        openId,
        isAddMode,
        themeList,
        clientData,
        serviceList,
        serviceForm,
        galleryForm,
        galleryList,
        videoLinkForm,
        onlinePayForm,
        openServiceBox,
        bankDetailForm,
        basicDetailForm,
        socialMediaForm,
        clientDetailForm,
        documentDetailForm,
        onlinePayArrayField,
        videoLinkArrayField,
        bankDetailArrayField,
        socialMediaArrayField,
        documentDetailArrayField,
        officeLocationArrayField,
        basicSubmit,
        handleOpenId,
        serviceSubmit,
        gallerySubmit,
        downloadQrCode,
        onlinePaySubmit,
        videoLinkSubmit,
        bankDetailSubmit,
        handleAddService,
        handleEditService,
        socialMediaSubmit,
        handleAddDocument,
        setOpenServiceBox,
        clientDetailSubmit,
        handleAddVideoLink,
        handleAddBankDetail,
        handleRemoveService,
        documentDetailSubmit,
        handleRemoveDocument,
        handleRemoveVideoLink,
        handleRemoveBankDetail,
        handleAddOfficeLocation,
        handleRemoveSelectedFiles,
        handleRemoveUploadedFiles,
        handleRemoveOfficeLocation,
    }

}

export default UseClientHooks;