import {
  Box,
  styled,
} from "@mui/material";

export const StyledCell = styled(Box)`
  width: 100%;
  display: grid;
  grid-template-columns: 30px 1fr 30px;
  align-items: center;
`;
