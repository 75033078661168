import { useNavigate } from "react-router-dom";
import { Controller } from "react-hook-form";

import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

import Button from "../../../components/Buttons/Button/Button";
import ColorSelector from "../../../components/ColorSelector/ColorSelector";

import { HeaderRow } from "../cardTheme.styles";

import UseAddEditCardThemeHook from "./addEditCardTheme.hooks";
import { ColorSelectorContainer } from "./addEditCardTheme.styles";

const AddEditCardTheme = () => {
    const {
        mode,
        control,
        onSubmit,
        handleSubmit
    } = UseAddEditCardThemeHook();
    const navigate = useNavigate();
    return (
        <Container maxWidth={false}>
            <Box textAlign={'left'}>
                <Card>
                    <CardContent>
                        <HeaderRow>
                            <Typography variant="pageTitle" textTransform={"capitalize"}>{mode} Card Theme</Typography>
                        </HeaderRow>
                        <Box>
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <Controller
                                            control={control}
                                            name="name"
                                            render={({
                                                field: { onBlur, onChange, value },
                                                fieldState: { error },
                                            }) => (
                                                <FormControl size="small" variant="standard">
                                                    <TextField
                                                        label="Name*"
                                                        size="small"
                                                        name="name"
                                                        value={value}
                                                        variant="outlined"
                                                        onChange={onChange}
                                                        inputProps={{ style: { textTransform: 'capitalize'}}}
                                                        onBlur={onBlur}
                                                        error={!!error}
                                                        helperText={error?.message}
                                                    />
                                                </FormControl>
                                            )}
                                            rules={{
                                                required: "Please Enter Name",
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Controller
                                            control={control}
                                            name="colorPalette1"
                                            render={({
                                                field: { onBlur, onChange, value },
                                                fieldState: { error },
                                            }) => (
                                                <ColorSelectorContainer>
                                                    <ColorSelector id="main_color_1" value={value} title="Color 1" subTitle="Backgroud" onChange={onChange} />
                                                </ColorSelectorContainer>
                                            )}
                                            rules={{
                                                required: "Please Enter Color1 Hexa Code",
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Controller
                                            control={control}
                                            name="colorPalette2"
                                            render={({
                                                field: { onBlur, onChange, value },
                                                fieldState: { error },
                                            }) => (
                                                <ColorSelectorContainer>
                                                    <ColorSelector id="main_color_2" value={value} title="Color 2" subTitle="Primary" onChange={onChange} />
                                                </ColorSelectorContainer>
                                            )}
                                            rules={{
                                                required: "Please Enter Color2 Hexa Code",
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Controller
                                            control={control}
                                            name="colorPalette3"
                                            render={({
                                                field: { onBlur, onChange, value },
                                                fieldState: { error },
                                            }) => (
                                                <ColorSelectorContainer>
                                                    <ColorSelector id="main_color_3" value={value} title="Color 3" subTitle="Secondary" onChange={onChange} />
                                                </ColorSelectorContainer>
                                            )}
                                            rules={{
                                                required: "Please Enter Color3 Hexa Code",
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Controller
                                            control={control}
                                            name="colorPalette4"
                                            render={({
                                                field: { onBlur, onChange, value },
                                                fieldState: { error },
                                            }) => (
                                                <ColorSelectorContainer>
                                                    <ColorSelector id="main_color_4" value={value} title="Color 4" subTitle="Highlight" onChange={onChange} />
                                                </ColorSelectorContainer>
                                            )}
                                            rules={{
                                                required: "Please Enter Color4 Hexa Code",
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                                <Box sx={{ display: 'flex', gap: '12px', padding: '12px 0px'}}>
                                    <Button
                                        id="btn_back"
                                        variant="contained"
                                        type="button"
                                        text="Back"
                                        color="primary"
                                        onClick={() => navigate('/card-theme')}
                                    />
                                    <Button
                                        id="btn_back"
                                        variant="contained"
                                        type="submit"
                                        text={mode}
                                        color="primary"
                                    />
                                </Box>
                            </form>
                        </Box>
                    </CardContent>
                </Card>
            </Box>
        </Container>
    )
}

export default AddEditCardTheme;