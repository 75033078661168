import { StyledListItem } from "./ListItem.styles"

// eslint-disable-next-line react/prop-types
const ListItem = ({ ...props }) => {
  return (
    <StyledListItem {...props} disablePadding>
      {props.children}
    </StyledListItem>
  )
}

export default ListItem
