import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Typography
} from "@mui/material"
import React, { useEffect, useState } from "react"

import { TransactionFilter } from "../../../utils/constants/constants"
import SearchBar from "../../SearchBarWithDebounce/SearchBar"
import {
  ButtonText,
  FilterAll,
  FilterCheckBox,
  FilterItem,
  FormControlCheckAll,
  FundsContent,
  SelectableLabel
} from "./Filter.style"

const OptionItem = ({ id, label, onSelect, selected = false }) => {
  const onCheck = e => {
    const isChecked = e.target.checked

    onSelect(
      {
        id,
        name: label
      },
      isChecked
    )
  }

  return (
    <FilterItem>
      <FormControlLabel
        control={
          <FilterCheckBox
            id={`${id}_check`}
            name={label}
            checked={selected}
            onChange={onCheck}
          />
        }
        label={label}
      />
    </FilterItem>
  )
}

const ButtonItem = ({
  id,
  label,
  onSelect,
  selected = false,
  formComponent = false,
  shouldOptionReturnValue = false
}) => {
  return (
    <FilterItem>
      {!formComponent ? (
        <Button
          variant={selected ? "outlined" : "text"}
          onClick={() => {
            if (!shouldOptionReturnValue) {
              return onSelect(label)
            }
            onSelect({ id, name: label })
          }}
        >
          {label}
        </Button>
      ) : (
        <SelectableLabel
          onClick={() => {
            if (!shouldOptionReturnValue) {
              return onSelect(label)
            }
            onSelect({ id, name: label })
          }}
        >
          <Typography variant={selected ? "filterChecked" : "filterUnchecked"}>
            {label}
          </Typography>
        </SelectableLabel>
      )}
    </FilterItem>
  )
}

const Filter = ({
  options = [],
  selectedOptions,
  handleFilter = () => {},
  handleSelection = () => {},
  handleAllSelection = () => {},
  idField,
  labelField,
  clearable = true,
  filterName = TransactionFilter.FundName,
  enableApplyAction = true,
  applyOnSelect = false,
  popoverStyle = {},
  scrollContainerStyle = {},
  hideButtons = false,
  singleSelect = false,
  optionFormComponent = false,
  showFilterAll = false,
  shouldOptionReturnValue = false,
  emptySelectionOnClear = false
}) => {
  const [search, setSearch] = useState("")
  const [selectedOptionList, setSelectedOptionList] = useState([])
  const [optionsList, setOptionsList] = useState([])

  const isAllSelected =
    selectedOptionList.length === options.length && options.length !== 0

  useEffect(() => {
    let subscribe = true

    // eslint-disable-next-line require-await
    const asyncCall = async () => {
      if (subscribe && selectedOptions) {
        setSelectedOptionList(selectedOptions)
      }
    }

    asyncCall()

    return () => {
      subscribe = false
    }
  }, [selectedOptions])

  useEffect(() => {
    let subscribe = true

    // eslint-disable-next-line require-await
    const asyncCall = async () => {
      if (subscribe && options && options.length) {
        setOptionsList(options)
      }
    }

    asyncCall()

    return () => {
      subscribe = false
    }
  }, [options])

  const onSearch = value => {
    const regex = new RegExp(value, "igm")
    const listFilter = options.filter(
      item => item[labelField].search(regex) !== -1
    )

    setOptionsList(listFilter)
    setSearch(value)
  }

  const onClear = () => {
    setSearch("")
    setSelectedOptionList(
      emptySelectionOnClear ? [] : options?.map(item => item[idField]) ?? []
    )
  }

  const handleApply = selected => {
    const type = isAllSelected ? "All" : "Partial"

    if (singleSelect && selected) {
      handleFilter(filterName, [selected], type)
    } else if (!search) {
      handleFilter(filterName, selectedOptionList, type)
    } else {
      const currentSelectedList = optionsList
        .filter(item => selectedOptionList.includes(item[idField]))
        .map(item => item[idField])

      handleFilter(filterName, currentSelectedList, type)
    }
  }

  const onSelect = (item, checked) => {
    const new_option_id = item.id
    let selected_options = [...selectedOptionList]

    if (checked) {
      selected_options.push(new_option_id)
    } else {
      selected_options = selected_options.filter(
        option => option !== new_option_id
      )
    }

    setSelectedOptionList(selected_options)
    if (applyOnSelect) {
      handleSelection(item, checked)
    }
  }

  const onSelectAll = (_, checked) => {
    const convertToChips =
      options?.map(item => ({
        id: item[idField],
        name: item[labelField]
      })) ?? []

    if (checked) {
      setSelectedOptionList(options?.map(item => item[idField]) ?? [])
    } else {
      setSelectedOptionList([])
    }
    if (applyOnSelect) handleAllSelection(convertToChips, checked)
  }

  return (
    <div id="popover_filter_text" style={popoverStyle}>
      <SearchBar id="filter_search" onChange={onSearch} value={search} />
      <FormGroup>
        {!singleSelect && !showFilterAll && (
          <FilterAll>
            <b>
              <FormControlCheckAll
                id="check_all"
                key="All"
                control={
                  <Checkbox
                    checked={isAllSelected}
                    indeterminate={
                      options &&
                      selectedOptionList.length > 0 &&
                      selectedOptionList.length < options?.length
                    }
                    onChange={onSelectAll}
                  />
                }
                label="All"
              />
            </b>
          </FilterAll>
        )}
        {showFilterAll && (
          <FilterAll>
            <SelectableLabel onClick={e => onSelectAll(e, !isAllSelected)}>
              <Typography
                variant={isAllSelected ? "filterChecked" : "filterUnchecked"}
              >
                All
              </Typography>
            </SelectableLabel>
          </FilterAll>
        )}
        <FundsContent sx={scrollContainerStyle}>
          {options && options?.length > 0 ? (
            optionsList.reduce((accumulator, cur) => {
              return singleSelect
                ? [
                    ...accumulator,
                    <ButtonItem
                      key={cur[idField]}
                      label={cur[labelField]}
                      id={cur[idField]}
                      shouldOptionReturnValue={shouldOptionReturnValue}
                      onSelect={handleApply}
                      selected={
                        shouldOptionReturnValue
                          ? selectedOptionList.includes(cur[idField])
                          : selectedOptionList.includes(cur[labelField])
                      }
                      formComponent={optionFormComponent}
                    />
                  ]
                : [
                    ...accumulator,
                    <OptionItem
                      key={cur[idField]}
                      label={cur[labelField]}
                      id={cur[idField]}
                      onSelect={onSelect}
                      selected={selectedOptionList.includes(cur[idField])}
                    />
                  ]
            }, [])
          ) : (
            <div>No records</div>
          )}
        </FundsContent>
      </FormGroup>
      {!hideButtons && (
        <Box display="flex" justifyContent="flex-end">
          {enableApplyAction && (
            <ButtonText
              id="btn_apply"
              variant="text"
              onClick={() => handleApply("")}
            >
              Apply Filter
            </ButtonText>
          )}
          {clearable && (
            <ButtonText id="btn_clear" variant="text" onClick={onClear}>
              Clear Filter
            </ButtonText>
          )}
        </Box>
      )}
    </div>
  )
}

export default Filter
