import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import {
  Collapse,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText
} from "@mui/material";
import React, { useCallback, useContext, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";

import { NavBarContext } from "../../core/context/navbarContext";
// import LockConfirmationDialog from "../LockConfirmationDialog/LockConfirmationDailog";
import {
  SideBarListItemButton,
  SideBarListItemText,
  StyledListItemIcon,
} from "./Sidebar.styles";

const MenuOption = ({
  label,
  redirectionLink,
  icon,
  options = [],
  id,
  isDisabled,
  color,
  rightIcon,
  isLocked
}) => {
  const [open, setOpen] = React.useState(false);
  // const [showLockedModal, setShowLockedModal] = React.useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const {
    isNavBarOpen
  } = useContext(NavBarContext);

  useEffect(() => {
    if (!isNavBarOpen) {
      setOpen(false);
    }
  }, [isNavBarOpen]);

  const handleClick = () => {
    // if(isLocked) {
    //   setShowLockedModal(true);
    // } else
    if (options && options.length > 0) {
      setOpen(!open);
    } else {
      navigate(redirectionLink);
    }
  };

  useEffect(() => {
    if(options.length > 0) {
      if(options?.map(res => location.pathname.includes(res.redirectionLink) ? true : false).includes(true)) {
        setOpen(true);
      } else {
        setOpen(false);
      }
    } else {
      setOpen(false);
    }
  }, [options, location])

  const activeTab = useCallback((redirectionLink) => {
    return location.pathname === redirectionLink;
  }, [location]);

  return (
    <>
      <ListItemButton onClick={handleClick} id={id} disabled={isDisabled} selected={options.length === 0 ? activeTab(redirectionLink) : false}>
        <ListItemIcon>{icon}</ListItemIcon>
        <SideBarListItemText
          isnavbaropen={isNavBarOpen?.toString()}
          textcolor={color}
          primary={label}
        />
        {rightIcon && <StyledListItemIcon>{rightIcon}</StyledListItemIcon>}
        {options && options.length > 0 && isNavBarOpen ? (
          open ? (
            <ExpandLess />
          ) : (
            <ExpandMore />
          )
        ) : null}
      </ListItemButton>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {options &&
            options?.map((option) => (
              <SideBarListItemButton
                id={option.id}
                key={option.id}
                isnavbaropen={isNavBarOpen?.toString()}
                disabled={option.isDisabled}
                component={Link}
                to={option.redirectionLink}
                selected={activeTab(option.redirectionLink)}
              >
                {/* <ListItemIcon>{option.icon}</ListItemIcon> */}
                <ListItemText primary={option.label} />
              </SideBarListItemButton>
            ))}
        </List>
      </Collapse>
      {/* <LockConfirmationDialog
        lockedPrompt={showLockedModal}
        setLockedPrompt={setShowLockedModal}
      /> */}
    </>
  );
};

export default React.memo(MenuOption);
