import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

import { StyledContentBox } from "./FallbackScreen.styles";

function FallbackScreen() {
  return (
    <StyledContentBox role="alert" id={'fallback_screen'}>
      <Typography variant="h3">Something went wrong. Please refresh.</Typography>
      <br />
      <Button
        id={"btn_fallback_reload"}
        variant={"outlined"}
        text={"Refresh"}
        color={"primary"}
        onClick={() => window.location.reload()}
      />
    </StyledContentBox>
  );
}

export default FallbackScreen;
