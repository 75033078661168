import { Box, TextField, Typography } from "@mui/material"
import { SketchPicker } from 'react-color';

import { ColorPickerWrapper, ColorSelectorBoxMain } from "./ColorSelector.style"
import { useState } from "react";

const ColorSelector = ({
  id,
  title,
  subTitle,
  onChange,
  value = "",
  defaultValue
}) => {
  const [displayColorPicker, setDisplayColorPicker] = useState(false);

  const handleClick = () => {
    setDisplayColorPicker(true);
  }

  const handleClose = () => {
    setDisplayColorPicker(false);
  }

  const handleChange = newValue => {
    onChange(typeof newValue === "string" ? newValue : newValue?.hex)
  }

  return (
    <ColorSelectorBoxMain>
      <Typography variant="formTitle2">{title}</Typography>
      <Typography variant="subInfo">{subTitle}</Typography>
      <ColorPickerWrapper>
        <Box sx={{
          padding: '3px',
          background: '#fff',
          borderRadius: '1px',
          boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
          display: displayColorPicker ? 'none' : 'inline-block',
          cursor: 'pointer',
        }} onClick={handleClick}>
          <Box sx={{ width: '36px', height: '36px', borderRadius: '2px', background: value }}/>
        </Box>
        {displayColorPicker ?
          <Box sx={{
            position: 'absolute',
            zIndex: '2',
          }}>
            <Box sx={{
              position: 'fixed',
              top: '0px',
              right: '0px',
              bottom: '0px',
              left: '0px',
            }} onClick={handleClose}/>
            <SketchPicker color={value ? value : ''}  onChange={handleChange}/>
          </Box>
        : null}
        {/* <ColorPicker
          value={value ? value : ""}
          hideTextfield
          onChange={handleChange}
          disableTextfield
        /> */}
        <TextField
          variant="outlined"
          id={`${id}_text_field`}
          value={value || ""}
          size="small"
          label="Hex"
          onChange={e => onChange(e.target.value)}
          inputProps={{ style: { textTransform: 'uppercase'}}}
          // InputProps={{
          //   startAdornment: <InputAdornment position="start">#</InputAdornment>
          // }}
        />
      </ColorPickerWrapper>
    </ColorSelectorBoxMain>
  )
}

export default ColorSelector
