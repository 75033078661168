import {
    ApiClient
} from './web.service';
import {
    APIPATH
} from '../utils/constants/constants';

const apiClient = () => {
    return new ApiClient();
}

export const getLoginUser = async () => {
    return apiClient().get(APIPATH.GETLOGINUSER);
}

export const login = async (payload) => {
    return apiClient().post(APIPATH.LOGIN, payload);
}

export const changePassword = async (payload) => {
    return apiClient().post(APIPATH.CHANGEPASSWORD, payload);
}