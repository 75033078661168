import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  TextField
} from "@mui/material"
import React from "react"

import { Action } from "./constants"
import {
  StyledDialog,
  StyledDialogActions,
  StyledDialogContentText,
  StyledDialogTitle,
  StyledInputCaption,
  StyledInputWrapper
} from "./DeleteConfirmationDialog.styles"

const DeleteConfirmationDialog = ({
  open,
  onClose,
  title = "Are you sure you want to delete?",
  content = "This action is permanent and cannot be undone. It is recommended that you export and save these records first.",
  primaryButtonLabel = "Delete Permanently",
  secondaryButtonLabel = "Cancel",
  primaryButtonAction = () => {},
  secondaryButtonAction = () => {},
  primaryButtonVariant = "contained",
  secondaryButtonVariant = "outlined",
  primaryButtonColor = "primary",
  secondaryButtonColor = "error",
  primaryButtonDisabled = false,
  secondaryButtonDisabled = false,
  primaryButtonId = "delete-confirmation-dialog-confirm-button",
  secondaryButtonId = "delete-confirmation-dialog-cancel-button",
  actionDisplayType = "stacked",
  id,
  actionType = Action.DELETE
}) => {
  const [inputValue, setInputValue] = React.useState("")

  const DialogActionContainer =
    actionDisplayType === "inline" ? DialogActions : StyledDialogActions

  return (
    <StyledDialog
      open={open}
      onClose={onClose}
      id={id}
      aria-labelledby="confirmation-dialog-title"
      aria-describedby="confirmation-dialog-description"
      PaperProps={{
        elevation: 0
      }}
    >
      {title && <StyledDialogTitle>{title}</StyledDialogTitle>}
      {content && (
        <DialogContent>
          <StyledDialogContentText>{content}</StyledDialogContentText>
          <StyledInputWrapper>
            <StyledInputCaption variant="caption" component="p">
              Type <strong>{actionType}</strong> in the text box below.
            </StyledInputCaption>
            <TextField
              id="delete-confirmation-text-field"
              size="small"
              fullWidth
              aria-describedby="Confirmation Text"
              label="Confirmation Text"
              placeholder="Confirmation Text"
              variant="outlined"
              value={inputValue}
              onChange={e => setInputValue(e.target.value)}
            />
          </StyledInputWrapper>
        </DialogContent>
      )}
      <Box display="flex" justifyContent="center">
        <DialogActionContainer disableSpacing={actionDisplayType === "stacked"}>
          <Button
            key={primaryButtonId}
            id={primaryButtonId}
            variant={primaryButtonVariant}
            color={primaryButtonColor}
            onClick={() => {
              if (inputValue === actionType) {
                setInputValue("")
                primaryButtonAction?.()
              }
            }}
            disabled={primaryButtonDisabled || inputValue !== actionType}
            fullWidth
          >
            {primaryButtonLabel}
          </Button>
          <Button
            key={secondaryButtonId}
            id={secondaryButtonId}
            variant={secondaryButtonVariant}
            color={secondaryButtonColor}
            onClick={() => {
              setInputValue("")
              secondaryButtonAction?.()
            }}
            disabled={secondaryButtonDisabled}
            fullWidth
          >
            {secondaryButtonLabel}
          </Button>
        </DialogActionContainer>
      </Box>
    </StyledDialog>
  )
}

export default DeleteConfirmationDialog
