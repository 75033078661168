import ImgIcon from "../ImgIcon/ImgIcon"
import { StyledListItemIcon } from "./ListItemIcon.styles"

const ListItemIcon = props => {
  const { id, icon } = props

  return (
    <StyledListItemIcon id={id}>
      <ImgIcon icon={icon} />
    </StyledListItemIcon>
  )
}

export default ListItemIcon
