import { useContext, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useForm } from 'react-hook-form';

import { AppContext } from '../../../core/context/appContextProvider';

import { createCardTheme, fetchByIdCardTheme, updateCardTheme } from '../../../services/cardTheme.service';

const UseAddEditCardThemeHook = () => {
    const {
        loginUser,
        setLoading,
        informationAlert
    } = useContext(AppContext);

    const navigate = useNavigate();
    const { mode, id } = useParams();

    const {
        control,
        setValue,
        handleSubmit
    } = useForm({
        defaultValues: {
            name: '',
            colorPalette1: '#FFFFFF',
            colorPalette2: '#FFFFFF',
            colorPalette3: '#FFFFFF',
            colorPalette4: '#FFFFFF'
        },
        mode: 'onBlur'
    });

    const fetchById = async (id) => {
        try {
            if(id) {
                setLoading(true);
                const { success, message, data } = await fetchByIdCardTheme(id);
                if(success) {
                    setValue('name', data?.name);
                    setValue('colorPalette1', data?.colorPalette1);
                    setValue('colorPalette2', data?.colorPalette2);
                    setValue('colorPalette3', data?.colorPalette3);
                    setValue('colorPalette4', data?.colorPalette4);
                } else {
                    informationAlert(message, "error");
                }
            }
        } catch(err) {
            informationAlert(err.message, "error");
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        if(id) {
            fetchById(id);
        }
        // eslint-disable-next-line
    }, [id]);

    const onSubmit = async (info) => {
        try {
            setLoading(true);
            const response = id ? await updateCardTheme(id, {...info, updatedBy: loginUser?.id}) : await createCardTheme({...info, createdBy: loginUser?.id});
            if(response.success) {
                navigate('/card-theme');
            } else {
                informationAlert(response.message, "error");
            }
        } catch(err) {
            console.error(err);
            informationAlert(err.message, "error");
        } finally {
            setLoading(false);
        }
    }
    return {
        mode,
        control,
        onSubmit,
        handleSubmit,
    }
}

export default UseAddEditCardThemeHook;