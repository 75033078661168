import { Fade, useTheme } from "@mui/material"

import { StyledButton } from "./Button.styles"

const Button = props => {
  const {
    id,
    icon = null,
    text,
    color,
    size,
    hidden = false,
    variant = "contained",
    onClick,
    fullWidth = false,
    width = '130px',
    addSpaceBetweenButtons = false,
    disabled = false,
    type
  } = props;

  const theme = useTheme();

  return (
    <Fade in={!hidden}>
      <StyledButton
        width={width}
        buttoncolor={color}
        backgroundcolor={theme.palette.background}
        textcolor={theme.palette.text}
        id={id}
        variant={variant}
        disableElevation
        startIcon={icon}
        onClick={onClick}
        size={size}
        fullWidth={fullWidth}
        type={type}
        spacebetween={addSpaceBetweenButtons.toString()}
        disabled={disabled}
        disableRipple={true}
        disableTouchRipple={true}
        disableFocusRipple={true}
      >
        {text}
      </StyledButton>
    </Fade>
  )
}

export default Button
