export const CurrencyFormat = (currency, decimalPlaces = 2) => {
  return new Intl.NumberFormat(undefined, {
    style: "currency",
    currency: currency,
    minimumFractionDigits: decimalPlaces,
    maximumFractionDigits: decimalPlaces
  })
}

export const NegativeCurrencyFormat = (currency, decimalPlaces = 2) => {
  return new Intl.NumberFormat(undefined, {
    style: "currency",
    currency: currency,
    currencySign: "accounting",
    minimumFractionDigits: decimalPlaces,
    maximumFractionDigits: decimalPlaces
  })
}

export const NumberFormat = (decimalPlaces = 2) => {
  return new Intl.NumberFormat(undefined, {
    minimumFractionDigits: decimalPlaces,
    maximumFractionDigits: decimalPlaces
  })
}

export const DateTimeFormat = {
  longDateTime: date => {
    if (!date) return

    const d =
      typeof date === "string" || typeof date === "number"
        ? new Date(date)
        : date
    const dateOptions = {
      month: "long",
      day: "numeric",
      year: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true
    }
    const formattedDate = d.toLocaleDateString("en-US", dateOptions)

    return formattedDate
  },
  shortDate: date => {
    const d =
      typeof date === "string" || typeof date === "number"
        ? new Date(date)
        : date
    const dateOptions = {
      timeZone: "UTC",
      month: "2-digit",
      day: "2-digit",
      year: "numeric"
    }
    const formattedDate = d.toLocaleDateString("en-US", dateOptions)

    return formattedDate
  },
  shortDateString: date => {
    const d = new Date(new Date(date).toISOString())

    const dateOptions = {
      timeZone: "UTC",
      month: "2-digit",
      day: "2-digit",
      year: "numeric"
    }
    const formattedDate = d.toLocaleDateString("en-US", dateOptions)

    return formattedDate
  },
  isoDateString: d => {
    const isoDateTimeStr = DateTimeFormat.ensureAsDate(d).toISOString()
    const dateParts = isoDateTimeStr.split("T")
    const dateIso = dateParts[0]

    return dateIso
  },
  noMilliseconds: d => {
    return d.toISOString().replace(".000Z", "Z")
  },
  getDateOnly: d => {
    const dateToUse = DateTimeFormat.ensureAsDate(d)

    dateToUse.setHours(0, 0, 0, 0)

    return dateToUse
  },
  ensureAsDate: d => {
    let dateToUse

    if (typeof d === "string" || d instanceof String) {
      const dateStr = DateTimeFormat.shortDateString(d)

      dateToUse = new Date(dateStr)
    } else {
      dateToUse = d
    }

    return dateToUse
  },
  getToday: () => {
    return DateTimeFormat.getDateOnly(new Date())
  },
  compareDateOnly: (d1, d2) => {
    const date1 = DateTimeFormat.getDateOnly(d1)
    const date1Ms = date1.getTime()
    const date2 = DateTimeFormat.getDateOnly(d2)
    const date2Ms = date2.getTime()

    if (date1Ms === date2Ms) {
      return 0
    } else if (date1Ms < date2Ms) {
      return -1
    } else {
      return 1
    }
  },
  getFormattedDate: (d, separator) => {
    const year = d.getFullYear()
    const month = (1 + d.getMonth()).toString().padStart(2, "0")
    const day = d
      .getDate()
      .toString()
      .padStart(2, "0")

    return `${month}${separator || "-"}${day}${separator || "-"}${year}`
  }
}

export const getCurrencySymbol = currency =>
  (0)
    .toLocaleString("en-US", {
      style: "currency",
      currency,
      minimumFractionDigits: 0,
      maximumFractionDigits: 0
    })
    .replace(/\d/g, "")
    .trim()

export const formatPhoneNumber = phoneNumberString => {
  const match = ("" + phoneNumberString)
    .replace(/\D/g, "")
    .match(/^(\d*|)(\d{3})(\d{3})(\d{4})$/)

  if (match) {
    return `${match[1] ? `+${match[1]}` : ""} ${match[2]}-${match[3]}-${
      match[4]
    }`
  } else {
    return phoneNumberString
  }
}
