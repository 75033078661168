export const DEBOUNCE_THRESHOLD = 500;
export const PAGE_SIZE = 10;
export const DISPLAY_DATE_FORMAT = 'MM/dd/yyyy';
export const DISPLAY_TIME = 'hh:mm a';
export const API_DATE_FORMAT = 'yyyy-MM-dd';
export const CLIENT_PROXY_TOKEN_KEY = "clientProxyToken";
export const TOKEN_KEY = "clientToken";
export const LOGIN_PAYLOAD_KEY = "loginPayload";
export const M_DASH_UNICODE = '\u2014';
export const EMAIL_REGEX = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
export const PHONE_REGEX = /^\d{10}$/i;
export const DIGIT_REGEX = /^\d*(\.\d{0,2})?$/i; // /^[0-9]+([\.,][0-9]+)?/i;
export const GST_REGEX = /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/i;
export const IFSC_REGEX = /^[A-Z]{4}0[A-Z0-9]{6}$/i;
export const UPI_REGEX = /^[\w.-]+@[\w.-]+$/i; // /^[a-zA-Z0-9.-]{2, 256}@[a-zA-Z][a-zA-Z]{2, 64}$/i;
export const ENTER_KEY_CODE = 13;
export const CLIENT_URL = 'https://www.facebook.com';
export const DEV_API_URL = process.env.REACT_APP_DEV_API_ENDPOINT_URL;
export const PROD_API_URL = process.env.REACT_APP_API_ENDPOINT_URL;
export const SCOPEROLE = {
    SUPER_ADMIN: 'SUPER_ADMIN',
    ADMIN: 'ADMIN',
    BASIC_USER: 'BASIC_USER',
    CUSTOMER: 'CUSTOMER',
    FRANCHISE: 'FRANCHISE'
}
export const APIPATH = {
    CLIENT: 'client',
    GETLOGINUSER: 'auth/get-login-user',
    LOGIN: 'auth/login-via-email',
    CHANGEPASSWORD: 'auth/change-password',
    USER: 'user',
    ROLE: 'role',
    SETTING: 'setting',
    MODULE: 'module',
    RIGHT: 'right',
    CARDTHEME: 'card-theme',
    PLAN: 'plan',
    COUPON: 'coupon',
    ADDON: 'add-on'
}

export const CustomType = {
    Currency: "currency",
    PositiveCurrency: "positiveCurrency",
    NegativeCurrency: "negativeCurrency",
    Action: "action",
    Percent: "percent"
}

export const TransactionFilter = {
    FundName: 'fundName',
    InvestorName: 'investorName',
    TransactionType: 'transactionType',
    TransactionName: 'transactionName',
    TransactionDate: 'transactionDate',
}

export const TransactionAction = {
    ExportSelected: 'export_selected',
    ExportAll: 'export_all',
    DeleteSelected: 'delete_selected',
    DeleteAll: 'delete_all',
}

export const TransactionType = {
    New: 'new',
    Edit: 'edit',
}
export const TransactionCRUDStatus = {
    Adding: 'Adding',
    Updating: 'Updating',
    Deleting: 'Deleting',
}