import {
    KeyboardDoubleArrowLeft,
    KeyboardDoubleArrowRight,
} from "@mui/icons-material";

import {
    Box,
    List,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    styled
} from '@mui/material';

export const SideBarContainer = styled(Box)(({ theme, isnavbaropen, background }) => ({
    height: "calc(100vh - 80px)",
    display: "grid",
    gridTemplateRows: "auto 1fr auto",
    backgroundColor: background || theme.palette.common.sidebarBackground,
    minWidth: isnavbaropen === "true" ? 290 : 60,
    maxWidth: 380,
    overflow: isnavbaropen === "true" ? "auto" : "hidden",
    paddingTop: theme.spacing(1),
}));

export const StyledListItemIcon = styled(ListItemIcon)(({ theme }) => ({
    marginLeft: "25px",
    marginRight: "-27px",
}));

export const SideBarListItemText = styled(ListItemText)(({ isnavbaropen, textcolor }) => ({
    "&.MuiListItemText-root .MuiTypography-root": {
        whiteSpace: "nowrap",
        ...(isnavbaropen === "false"
            ? {
                color: "transparent",
            }
            : {
                color: textcolor || "inherit",
            }),
    },
}));

export const SideBarListItemButton = styled(ListItemButton)(({ isnavbaropen, theme }) => ({
    paddingLeft: isnavbaropen === "true" ? theme.spacing(10) : 0,
}));

export const SideBarList = styled(List)(({ isnavbaropen }) => ({
    width: "100%",
    maxWidth: 380,
    overflow: isnavbaropen === "true" ? "auto" : "hidden",
}));

export const StyledKeyboardDoubleArrowLeft = styled(KeyboardDoubleArrowLeft)(({ textcolor }) => ({
    color: textcolor || "inherit",
}));

export const StyledKeyboardDoubleArrowRight = styled(KeyboardDoubleArrowRight)(({ textcolor }) => ({
    color: textcolor || "inherit",
}));
