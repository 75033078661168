import { useContext, useEffect } from "react";
import { useNavigate, useLocation } from "react-router";

import {
    AppContext
} from "../../core/context/appContextProvider";

const ProtectedRoute = ({ path, Component }) => {
    const {
        accessControls
    } = useContext(AppContext);
    const navigate = useNavigate();
    const location = useLocation();

    const rights = accessControls(`/${path}`);

    useEffect(() => {
        if(location.pathname !== '/') {
            if (!rights.view) {
                navigate("/", { replace: true });
            }
        }
        // eslint-disable-next-line
    }, [rights.view]);
    return Component;
};

export default ProtectedRoute;