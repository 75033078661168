import React, { createContext, useEffect, useMemo, useState } from "react";

import InformationAlert from "../../components/InformationAlert/InformationAlert";
import Progress from "../../components/Progress/ProgressModal/ProgressModal";

import { setTitleAndSuffix } from '../../utils/helpers';
import { TOKEN_KEY } from '../../utils/constants/constants';
import { useEffectAsync } from '../../utils/hooks/useEffectAsync.hook';
import { getLoginUser } from "../../services/login.service";

const AppContext = createContext({
    isMobileView: false,
    loginUser: null,
    SetLoginUser: () => null,
    loading: false,
    setLoading: () => null,
    setTitle: (title) => null,
    accessModules: [],
    accessControls: (pathName) => null,
    informationAlertMessage: {
      text: "",
      open: false,
      severity: "info",
    },
    onLogout: () => null,
    handleSetLoginUser: (info) => null,
    informationAlert: (
      text = '', severity, title = undefined, actionTitle = undefined, action = () => {}
    ) => null,
    token: null
});

const AppProvider = ({children}) => {
    const [loginUser, setLoginUser] = useState(null);
    const [token, setToken] = useState(localStorage.getItem(TOKEN_KEY) || null)
    const [loading, setLoading] = useState(false);
    const [isMobileView, setIsMobileView] = useState(false);
    const [informationAlertMessage, setInformationAlertMessage] = useState({
      text: "",
      open: false,
      severity: "info",
    });

    useEffectAsync(async () => {
      if(localStorage.getItem(TOKEN_KEY)) {
        const { success, message, data } = await getLoginUser();
        if(success) {
          handleSetLoginUser(data);
        } else {
          informationAlert(message, "info");
        }
      }
    }, []);

    const accessModules = useMemo(() => {
      return loginUser?.accessModules ? loginUser.accessModules : []
    }, [loginUser]);

    const accessControls = (pathName) => {
      let returnValue = {
        add: false,
        edit: false,
        view: false,
        delete: false
      }
      accessModules?.forEach((item) => {
        const child = JSON.parse(item.px_module?.childRoute);
        if(child.length > 0) {
          if(child?.map((res) => res.routerLink === pathName).includes(true)) {
            returnValue = {
              add: item.add,
              edit: item.edit,
              view: item.view,
              delete: item.delete
            }
          }
        } else if(item.px_module?.routerLink === pathName) {
          returnValue = {
            add: item.add,
            edit: item.edit,
            view: item.view,
            delete: item.delete
          };
        }
      });
      return returnValue;
    };

    useEffect(() => {
      const handleResize = () => {
        if (window.innerWidth < 768) {
          setIsMobileView(true);
        } else {
          setIsMobileView(false);
        }
      };

      window.addEventListener("resize", handleResize);

      // Initial screen size on component mount
      // setScreenSize({ width: window.innerWidth, height: window.innerHeight });

      // Cleanup the event listener on component unmount
      return () => {
        window.removeEventListener("resize", handleResize);
      };
    }, []);

    const setTitle = (title) => {
      document.title = setTitleAndSuffix(title)
    }

    const handleSetLoginUser = (info) => {
      const { token } = info;
      localStorage.setItem(TOKEN_KEY, token);
      setToken(token);
      delete info['token'];
      setLoginUser(info);
    }

    const informationAlert = (text = '', severity, title = undefined, actionTitle = undefined, action = () => {}) => {
      setInformationAlertMessage({
        text: text,
        severity: severity,
        open: true,
        title: title,
        actionTitle: actionTitle,
        action: action,
      });
    };

    const handleInformationAlertMessageClose = () => {
      setInformationAlertMessage({
        text: "",
        severity: informationAlertMessage.severity,
        open: false,
      });
    };

    const onLogout = async () => {
      localStorage.removeItem(TOKEN_KEY);
      setToken(null);
      setLoginUser(null);
    }

    return (
      <AppContext.Provider
        value={{
          loginUser,
          token,
          setToken,
          setLoginUser,
          setTitle,
          loading,
          setLoading,
          informationAlertMessage,
          informationAlert,
          isMobileView,
          handleSetLoginUser,
          onLogout,
          accessModules,
          accessControls
        }}
      >
        <Progress
          id="global_loader"
          showProgress={loading}
        />
        {children}
        <InformationAlert
          id={"ark_notification"}
          text={informationAlertMessage.text}
          open={informationAlertMessage.open}
          severity={informationAlertMessage.severity}
          title={informationAlertMessage?.title}
          actionTitle={informationAlertMessage.actionTitle}
          handleClose={handleInformationAlertMessageClose}
          action={informationAlertMessage.action}
        />
      </AppContext.Provider>
    );
}

export {
    AppProvider, AppContext
};
