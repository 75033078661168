import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';

import ProgressPanel from "../ProgressPanel/ProgressPanel";
import { StyledModal } from "./ProgressModal.styles";

const ProgressModal = ({ id, showProgress, text = "Loading..." }) => {
  return (
    <StyledModal
      id={id}
      open={showProgress}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Box>
        <ProgressPanel id={`${id}_panel`} showProgress={showProgress} text={text} />
      </Box>
    </StyledModal>
  );
};

export default ProgressModal;
