import axios from 'axios';
import {
    CLIENT_PROXY_TOKEN_KEY,
    TOKEN_KEY,
    DEV_API_URL,
    PROD_API_URL
} from '../utils/constants/constants';

export class ApiClient {
    client;
    constructor() {
        this.client = this.getCachedClient();
    }

    get BearerToken() {
        const token = localStorage.getItem(CLIENT_PROXY_TOKEN_KEY) || localStorage.getItem(TOKEN_KEY);
        let bearer;

        if (token && token !== 'null' && token !== 'undefined') {
            bearer = token;
        } else {
            bearer = null;
        }

        return bearer;
    }

    get BaseUrl() {
        if(window?.location.host.includes('localhost') || window?.location.host.includes('admin-vbc.kuldeepakbari.com')) {
            return DEV_API_URL;
        } else {
            return PROD_API_URL;
        }
    }

    getCachedClient() {
        const axiosInstance = axios.create({
            baseURL: this.BaseUrl
        });

        axiosInstance.interceptors.request.use(
            config => {
                config.headers['x-api-key'] = process.env.REACT_APP_API_KEY;
                if (this.BearerToken) {
                    //config.headers.Authorization = `Bearer ${JSON.parse(this.BearerToken)}`;
                    config.headers.Authorization = this.BearerToken;
                }
                return config;
            },
            error => Promise.reject(error)
        );

        // Added interceptor to handle 401 errors
        axiosInstance.interceptors.response.use(
            response => response,
            error => {
                if (error?.response?.status === 401) {
                    localStorage.removeItem(TOKEN_KEY);
                    // localStorage.removeItem(LOGIN_PAYLOAD_KEY);
                    localStorage.removeItem(CLIENT_PROXY_TOKEN_KEY);
                    window.location.reload();
                    return Promise.reject(error);
                }
                return Promise.resolve(error?.response);
            }
        );

        return axiosInstance;
    }

    async get(path, config = {}) {
        const response = await this.client.get(path, config);
        return response.data;
    }

    async post(path, data, config = {}) {
        const response = await this.client.post(path, data, config);
        return response.data;
    }

    async put(path, data, config = {}) {
        const response = await this.client.put(path, data, config);
        return response.data;
    }

    async patch(path, data, config = {}) {
        const response = await this.client.patch(path, data, config);
        return response.data;
    }
    async delete(path, data) {
        const response = await this.client.delete(path, data);
        return response.data;
    }
}