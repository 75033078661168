import { Box, List, styled } from "@mui/material";

export const OptionsBox = styled(Box)`
  display: flex;
  align-content: flex-start;
    
  .MuiIcon-root > img {
    margin-right: 4px !important;
  }
`;

export const StyledList = styled(List)(() => ({
  maxHeight: '400px !important',
}));
