
import { useNavigate } from "react-router-dom";
import { Controller } from "react-hook-form";

import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

import UseAddEditUserHook from "./addEditUser.hooks";

import { HeaderRow } from "../user.styles";

import Button from "../../../components/Buttons/Button/Button";
import { EMAIL_REGEX, PHONE_REGEX } from "../../../utils/constants/constants";
import { InputLabel, MenuItem, Select } from "@mui/material";



const AddEditUser = () => {
    const {
        mode,
        control,
        roleList,
        onSubmit,
        handleSubmit
    } = UseAddEditUserHook();
    const navigate = useNavigate();
    return (
        <Container maxWidth={false}>
            <Box textAlign={'left'}>
                <HeaderRow>
                    <Typography variant="pageTitle" textTransform={"capitalize"}>{mode} User</Typography>
                </HeaderRow>
                <Box>
                <form onSubmit={handleSubmit(onSubmit)}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={4}>
                                <Controller
                                    control={control}
                                    name="firstName"
                                    render={({
                                        field: { onBlur, onChange, value },
                                        fieldState: { error },
                                    }) => (
                                        <FormControl size="small" fullWidth variant="standard">
                                            <TextField
                                                label="First Name*"
                                                size="small"
                                                name="firstName"
                                                value={value}
                                                variant="outlined"
                                                onChange={onChange}
                                                inputProps={{ style: { textTransform: 'capitalize'}}}
                                                onBlur={onBlur}
                                                error={!!error}
                                                helperText={error?.message}
                                            />
                                        </FormControl>
                                    )}
                                    rules={{
                                        required: "Please Enter First Name",
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <Controller
                                    control={control}
                                    name="lastName"
                                    render={({
                                        field: { onBlur, onChange, value },
                                        fieldState: { error },
                                    }) => (
                                        <FormControl size="small" fullWidth variant="standard">
                                            <TextField
                                                label="Last Name*"
                                                size="small"
                                                name="lastName"
                                                value={value}
                                                variant="outlined"
                                                onChange={onChange}
                                                inputProps={{ style: { textTransform: 'capitalize'}}}
                                                onBlur={onBlur}
                                                error={!!error}
                                                helperText={error?.message}
                                            />
                                        </FormControl>
                                    )}
                                    rules={{
                                        required: "Please Enter Last Name",
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <Controller
                                    control={control}
                                    name="email"
                                    render={({
                                        field: { onBlur, onChange, value },
                                        fieldState: { error },
                                    }) => (
                                        <FormControl size="small" fullWidth variant="standard">
                                            <TextField
                                                label="Email*"
                                                size="small"
                                                name="email"
                                                value={value}
                                                variant="outlined"
                                                onChange={onChange}
                                                onBlur={onBlur}
                                                error={!!error}
                                                helperText={error?.message}
                                            />
                                        </FormControl>
                                    )}
                                    rules={{
                                        required: "Please Enter Email",
                                        pattern: {
                                            value: EMAIL_REGEX,
                                            message: 'Please enter a valid email',
                                        }
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <Controller
                                    control={control}
                                    name="phoneNumber"
                                    render={({
                                        field: { onBlur, onChange, value },
                                        fieldState: { error },
                                    }) => (
                                        <FormControl size="small" fullWidth variant="standard">
                                            <TextField
                                                label="Phone Number*"
                                                size="small"
                                                name="phoneNumber"
                                                value={value}
                                                variant="outlined"
                                                onChange={onChange}
                                                onBlur={onBlur}
                                                error={!!error}
                                                helperText={error?.message}
                                            />
                                        </FormControl>
                                    )}
                                    rules={{
                                        required: "Please Enter Phone Number",
                                        minLength: {
                                            value: 10,
                                            message: 'Please enter minimum 10 digit'
                                        },
                                        maxLength: {
                                            value: 10,
                                            message: 'Please enter maximum 10 digit'
                                        },
                                        pattern: {
                                            value: PHONE_REGEX,
                                            message: 'Please enter valid Phone Number',
                                        }
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <Controller
                                    control={control}
                                    name="roleID"
                                    render={({
                                        field: { onBlur, onChange, value },
                                        fieldState: { error },
                                    }) => (
                                        <FormControl size="small" fullWidth>
                                            <InputLabel size="small" id="role-select">Select Role</InputLabel>
                                            <Select
                                                variant="outlined"
                                                labelId="role-select"
                                                label="Select Role"
                                                size="small"
                                                value={value}
                                                onChange={onChange}
                                                onBlur={onBlur}
                                            >
                                                {roleList?.map((option, key) => (
                                                    <MenuItem key={key} value={option?.id}>
                                                        {option?.name}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    )}
                                    rules={{
                                        required: "Please Select Role"
                                    }}
                                />
                            </Grid>
                            {mode === 'add' ?
                            <Grid item xs={12} sm={4}>
                                <Controller
                                    control={control}
                                    name="password"
                                    render={({
                                        field: { onBlur, onChange, value },
                                        fieldState: { error },
                                    }) => (
                                        <FormControl size="small" fullWidth variant="standard">
                                            <TextField
                                                label="Password"
                                                size="small"
                                                name="password"
                                                value={value}
                                                variant="outlined"
                                                onChange={onChange}
                                                onBlur={onBlur}
                                                error={!!error}
                                                helperText={error?.message}
                                                disabled
                                            />
                                        </FormControl>
                                    )}
                                />
                            </Grid>
                            : null}
                        </Grid>
                        <Box sx={{ display: 'flex', gap: '12px', padding: '12px 0px'}}>
                            <Button
                                id="btn_back"
                                variant="contained"
                                type="button"
                                text="Back"
                                color="primary"
                                onClick={() => navigate('/user')}
                            />
                            <Button
                                id="btn_back"
                                variant="contained"
                                type="submit"
                                text={mode}
                                color="primary"
                            />
                        </Box>
                    </form>
                </Box>
            </Box>
        </Container>
    )
};

export default AddEditUser;