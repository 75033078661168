import Typography from "@mui/material/Typography"

import { M_DASH_UNICODE } from "../../utils/constants/constants"
import {
  CurrencyFormat,
  DateTimeFormat,
  NegativeCurrencyFormat,
  NumberFormat
} from "../../utils/helpers/format.helper"
import { CustomType } from "../../utils/constants/constants"
import ChipCellStack from "./ChipsCell/ChipCellStack"
import { StringCell } from "./DataGrid.styles"

export const getValue = (fieldName, rowValues) => {
  const childProperty = fieldName.split(".")

  if (!rowValues[childProperty[0]]) return

  switch (childProperty.length) {
    case 2:
      return rowValues[childProperty[0]][childProperty[1]]
    case 3:
      return rowValues[childProperty[0]][childProperty[1]][childProperty[2]]
    default:
      return rowValues[fieldName]
  }
}

export const getComponent = (header, row) => {
  const value = getValue(header.field, row)
  const decimal = header.decimalPlaces !== undefined ? header.decimalPlaces : 2

  switch (header.type) {
    case "number": {
      let numValue =
        (typeof value === "string" ? parseFloat(value) : value) || 0
      let formatter = NumberFormat(decimal)

      if (
        [
          CustomType.Currency,
          CustomType.PositiveCurrency,
          CustomType.NegativeCurrency
        ].includes(header?.customType)
      ) {
        const currencyCode = header?.currencyCodeField
          ? getValue(header?.currencyCodeField, row)
          : "USD"

        formatter =
          header.customType === CustomType.NegativeCurrency
            ? NegativeCurrencyFormat(currencyCode || "USD", decimal)
            : CurrencyFormat(currencyCode || "USD", decimal)
      }

      if (decimal !== 2) {
        numValue = Number(
          Math.round(parseFloat(numValue + "e" + decimal)) + "e-" + decimal
        )
      }

      return (
        <StringCell>
          {formatter.format(
            header?.customType === CustomType.PositiveCurrency
              ? Math.abs(numValue)
              : numValue
          ) || M_DASH_UNICODE}
        </StringCell>
      )
    }
    case "chips": {
      const items = value ?? []

      if (header.renderChipForFilesOnly) {
        if (row.type === "folder") {
          return <Typography variant="cells">{M_DASH_UNICODE}</Typography>
        }
      }
      if (header.renderChipForMultiItemsOnly) {
        if (Array.isArray(items) && items.length < 2) {
          return (
            <Typography variant="cells">
              {items.length
                ? items[0][header.chipLabelField || ""]
                : M_DASH_UNICODE}
            </Typography>
          )
        } else if (typeof items === "object") {
          return (
            <Typography variant="cells">
              {items[header.chipLabelField ?? ""]}
            </Typography>
          )
        }
      }

      return <ChipCellStack header={header} items={items} row={row} />
    }
    case "percent":
      return (
        <StringCell>
          {value
            ? `${Number(
                Math.round(parseFloat(value + "e" + decimal)) + "e-" + decimal
              )}%`
            : M_DASH_UNICODE}
        </StringCell>
      )
    case "date":
      return (
        <StringCell>
          {value ? DateTimeFormat.shortDate(new Date(value)) : M_DASH_UNICODE}
        </StringCell>
      )
    default:
      return <StringCell>{value || M_DASH_UNICODE}</StringCell>
  }
}
