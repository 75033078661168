import AccountCircle from "@mui/icons-material/AccountCircle";
import MoreHoriz from "@mui/icons-material/MoreHoriz";
import { AppBar, styled } from "@mui/material";

export const NavBarContainer = styled(AppBar)(
    ({ theme, bgcolor }) => ({
        display: "grid",
        gridTemplateColumns: "350px 1fr auto",
        justifyContent: "space-between",
        gridGap: theme.spacing(2),
        padding: theme.spacing(1),
        alignItems: "center",
        height: "80px",
        backgroundColor: bgcolor || "transparent",
    })
);

export const StyledImage = styled("img")(
    ({ theme, fullwidth }) => ({
        maxHeight: 68,
        maxWidth: fullwidth === "true" ? 350 : 90,
        minWidth: 72,
        marginRight: theme.spacing(2),
        marginLeft: theme.spacing(2),
    })
);

export const StyledAccountCircle = styled(AccountCircle)(({ textcolor, theme }) => ({
    color: textcolor || theme.palette.text.primary,
}));

export const StyledMoreHoriz = styled(MoreHoriz)(
    ({ textcolor, theme }) => ({
        color: textcolor || theme.palette.text.primary,
    })
);