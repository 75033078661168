import { useContext, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useForm } from 'react-hook-form';

import { AppContext } from '../../../core/context/appContextProvider';

import { createAddOn, fetchByIdAddOn, updateAddOn } from '../../../services/addOn.service';

const UseAddEditAddOnHook = () => {
    const {
        loginUser,
        setLoading,
        informationAlert
    } = useContext(AppContext);

    const navigate = useNavigate();
    const { mode, id } = useParams();

    const {
        control,
        setValue,
        getValues,
        handleSubmit
    } = useForm({
        defaultValues: {
            name: '',
            description: '',
            discountPrice: '0',
            price: '',
        },
        mode: 'onBlur'
    });

    const fetchById = async (id) => {
        try {
            if(id) {
                setLoading(true);
                const { success, message, data } = await fetchByIdAddOn(id);
                if(success) {
                    setValue('name', data?.name);
                    setValue('description', data?.description);
                    setValue('discountPrice', data?.discountPrice);
                    setValue('price', data?.price);
                } else {
                    informationAlert(message, "error");
                }
            }
        } catch(err) {
            informationAlert(err.message, "error");
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        if(id) {
            fetchById(id);
        }
        // eslint-disable-next-line
    }, [id]);

    const onSubmit = async (info) => {
        const payload = JSON.parse(JSON.stringify(info));
        payload['discountPrice'] = parseFloat(info['discountPrice']);
        payload['price'] = parseFloat(info['price']);
        try {
            setLoading(true);
            const response = id ? await updateAddOn(id, {...payload, updatedBy: loginUser?.id}) : await createAddOn({...payload, createdBy: loginUser?.id});
            if(response.success) {
                navigate('/add-on');
            } else {
                informationAlert(response.message, "error");
            }
        } catch(err) {
            console.error(err);
            informationAlert(err.message, "error");
        } finally {
            setLoading(false);
        }
    }
    return {
        mode,
        control,
        onSubmit,
        getValues,
        handleSubmit
    }
}

export default UseAddEditAddOnHook;