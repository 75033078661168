import { Box } from "@mui/material"
import React, { useEffect, useState } from "react"

import SearchBar from "../../SearchBarWithDebounce/SearchBar"
import { ButtonText } from "./Filter.style"

const FilterSearch = ({
  selectedOptions,
  handleFilter = () => {},
  filterName
}) => {
  const [search, setSearch] = useState("")

  useEffect(() => {
    if (selectedOptions?.[0]) {
      setSearch(selectedOptions[0])
    }
  }, [selectedOptions])

  const onSearch = value => {
    setSearch(value)
  }

  const onClear = () => {
    setSearch("")
  }

  const handleApply = () => {
    if (!filterName) {
      return
    }
    handleFilter(filterName, [search])
  }

  return (
    <div id="popover_filter_text">
      <SearchBar id="filter_search" onChange={onSearch} value={search} />
      <Box display="flex" justifyContent="flex-end">
        <ButtonText id="btn_apply" variant="text" onClick={handleApply}>
          Apply Filter
        </ButtonText>
        <ButtonText id="btn_clear" variant="text" onClick={onClear}>
          Clear Filter
        </ButtonText>
      </Box>
    </div>
  )
}

export default FilterSearch
