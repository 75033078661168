import { Box, RadioGroup,styled } from "@mui/material";

export const DateRangeContainer = styled(Box)(() => ({
  display: "grid",
  width: "100%",
  gridTemplateColumns: "50px 3fr 20px 3fr",
  justifyContent: "center",
  alignItems: "center",
  alignContent: "center",
  justifyItems: "center",
}));


export const StyledRadioContainer = styled(RadioGroup)(() => ({
  display: "flex",
  flexDirection: "column",
}));
