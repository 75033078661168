import UnfoldMoreIcon from "@mui/icons-material/UnfoldMore"
import VisibilityIcon from "@mui/icons-material/Visibility"
import {
  Box,
  Button as ButtonMui,
  LinearProgress,
  Typography
} from "@mui/material"
import React, { useMemo } from "react"

import {
  DATA_GRID_DEFAULT_MIN_WIDTH,
  DATA_GRID_DEFAULT_WIDTH,
  DATA_GRID_ROW_LENGTH
} from "../../utils/constants/styles.constants"
import ColumnHeaderCell from "./columnHeaderCell/ColumnHeaderCell"
import { getComponent } from "./DataGrid.helper"
import { StyledDataGridPro } from "./DataGrid.styles"
import HeaderSelectionPopover from "./headerSelectionPopover/HeaderSelectionPopover"

const DataGrid = ({
  id,
  dataList,
  headerList,
  selectionModel,
  setSelectionModel,
  activeHeaderFields = 0,
  handleUpdateHeader = () => {},
  handleOnView = () => {},
  handleFilter = () => {},
  minHeight = "85vh",
  autoHeight = true,
  onNextPage,
  disableVirtualization = false,
  scrollEndThreshold = 500,

  pinnedColumns = {
    right: ["action"]
  },

  checkboxSelection = true,
  loading = false,
  noDataMessage = "No Data Available",
  hideColumnToggleAction = false,
  actionName = "View",
  ...props
}) => {
  const columns = useMemo(() => {
    return (
      headerList?.map(item => {
        return {
          flex: activeHeaderFields < 5 ? 1 : 0,
          rowLength: DATA_GRID_ROW_LENGTH,
          width: item?.width ?? DATA_GRID_DEFAULT_WIDTH,
          sortable: item?.sortable ?? true,
          filterable: item?.filterable ?? false,
          disableColumnMenu: item?.disableColumnMenu ?? true,
          minWidth: item?.minWidth ?? DATA_GRID_DEFAULT_MIN_WIDTH,
          name: item.headerName,
          renderCell: params => {
            if (item.type !== "action") {
              return getComponent(item, params.row)
            } else {
              const selectedID = params.row.id

              return (
                <ButtonMui
                  id={`btn_data_grid_${selectedID}`}
                  variant="text"
                  disableElevation
                  startIcon={<VisibilityIcon />}
                  onClick={handleOnView ? () => handleOnView(selectedID, params.row) : () => {}}
                  name="View button"
                >
                  {actionName}
                </ButtonMui>
              )
            }
          },
          renderHeader: () => {
            if (item.type !== "action") {
              return (
                <ColumnHeaderCell item={item} handleFilter={handleFilter} />
              )
            } else if (!hideColumnToggleAction) {
              return (
                <HeaderSelectionPopover
                  id="data_grid_column_selector"
                  headerFields={headerList}
                  handleUpdateHeader={handleUpdateHeader}
                />
              )
            }
          },
          ...item
        }
      }) || []
    )
  }, [headerList, handleOnView, actionName, activeHeaderFields, handleFilter, handleUpdateHeader, hideColumnToggleAction])

  return (
    <Box id={id}>
      <StyledDataGridPro
        rowHeight={75}
        rows={dataList}
        loading={loading}
        minHeight={minHeight}
        columns={columns}
        columnBuffer={8}
        density="compact"
        aria-label="Data List"
        autoPageSize
        disableRowSelectionOnClick
        disableVirtualization={disableVirtualization}
        onSelectionModelChange={selectedModel =>
          setSelectionModel ? setSelectionModel(selectedModel) : null
        }
        selectionModel={selectionModel}
        checkboxSelection={checkboxSelection}
        disableSelectionOnClick
        hideFooter
        autoHeight={autoHeight}
        scrollEndThreshold={scrollEndThreshold}
        onRowsScrollEnd={onNextPage}
        components={{
          // eslint-disable-next-line react/prop-types
          ColumnUnsortedIcon: ({ sortingOrder, ...other }) => (
            <UnfoldMoreIcon {...other} />
          ),
          LoadingOverlay: LinearProgress,
          NoRowsOverlay: () => (
            <Typography id="no_data" align="center">
              {noDataMessage}
            </Typography>
          ),
          ...props.components
        }}
        initialState={{
          pinnedColumns: pinnedColumns
        }}
        {...props}
      />
    </Box>
  )
}

export default DataGrid
