import { createContext, useEffect } from 'react';

import useLocalStorage from '../../utils/helpers/useLocalStorage';

const NavBarContext = createContext({
    isNavBarOpen: false,
    setIsNavBarOpen: () => { },
    isNavBarPinned: false,
    setIsNavBarPinned: () => { },
});

const NavBarProvider = ({ children }) => {
    const [isOpen, setIsOpen] = useLocalStorage('navbarOpen', true);
    const [isPinned, setIsPinned] = useLocalStorage('navbarPinned', false);

    useEffect(() => {
        if (!isOpen) {
            setIsPinned(false);
        }
    }, [isOpen, setIsPinned]);

    const setIsNavBarOpen = (open) => {
        setIsOpen(open);
        if (!open) {
            setIsPinned(false);
        }
    };

    const setIsNavBarPinned = (pinned) => {
        setIsPinned(pinned);
    };

    const value = {
        isNavBarOpen: isOpen,
        setIsNavBarOpen,
        isNavBarPinned: isPinned,
        setIsNavBarPinned,
    };

    return <NavBarContext.Provider value={value}>{children}</NavBarContext.Provider>;
};

export {
    NavBarContext,
    NavBarProvider
};