import Fade from "@mui/material/Fade";

import {
  ProgressText,
  StyledBox,
  StyledCircularProgress,
} from "./ProgressPanel.styles";

const FullScreenModal = ({ id, showProgress, text }) => {
  return (
    <Fade in={showProgress} timeout={500}>
      <StyledBox id={id}>
        <StyledCircularProgress
          variant="indeterminate"
          disableShrink
          size={20}
          thickness={4}
        />
        <ProgressText variant="h5">{text}</ProgressText>
      </StyledBox>
    </Fade>
  );
};

export default FullScreenModal;
