import { useContext, useEffect, useState, useMemo } from 'react';
import { useNavigate } from "react-router-dom";

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Switch from '@mui/material/Switch';

import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete"

import Button from "../../components/Buttons/Button/Button";
import { StringCell } from '../../components/DataGrid/DataGrid.styles';

import { M_DASH_UNICODE } from '../../utils/constants/constants';
import { AppContext } from '../../core/context/appContextProvider';
import { listPayload } from '../../utils/helpers';

import {
    updateClient,
    deleteClient,
    fetchClientGrid,
} from '../../services/client.service';

const UseClientHooks = () => {
    const {
        setLoading,
        informationAlert,
    } = useContext(AppContext);

    const [list, setList] = useState([]);
    const [page, setPage] = useState(0);
    const [count, setCount] = useState(0);
    const [selectedId, setSelectedId] = useState(null);
    const [showDeleteModal, setShowDeleteModal] = useState(false);

    const navigate = useNavigate();

    const fetchClientList = async () => {
        try {
            setLoading(true);
            const payload = listPayload(page);
            const { success, message, data } = await fetchClientGrid(payload);
            if (success) {
                setList(data?.rows);
                setCount(data?.count);
            } else {
                setList([]);
                setCount(0);
                informationAlert(message, "error");
            }
        } catch (err) {
            console.error(err);
            informationAlert(err.message, "error");
        } finally {
            setLoading(false);
        }
    };

    const handleChangeStatus = async (status, id) => {
        try {
            const { success, message } = await updateClient(id, {
                isActive: status
            })
            if (success) {
                informationAlert(message, 'info');
            } else {
                informationAlert(message, 'error');
            }
        } catch (err) {
            console.error(err);
            informationAlert(err.message, "error");
        } finally {
            await fetchClientList();
        }
    };

    const handleDelete = async () => {
        try {
            setLoading(true);
            const {success, message} = await deleteClient(selectedId);
            if(success) {
                setShowDeleteModal(false);
                informationAlert(message, 'info');
            } else {
                informationAlert(message, 'error');
            }
        } catch (err) {
            console.error(err);
            informationAlert(err.message, "error");
        } finally {
            setLoading(false);
            await fetchClientList();
        }
    }

    const headerList = useMemo(() => [
        {
            field: "name",
            headerName: "Name",
            hide: false,
            index: 1,
            renderCell: ({ row }) => {
                const name = `${row?.px_user?.firstName} ${row?.px_user?.lastName}`;

                return (
                    <StringCell>
                        <Typography>{name || M_DASH_UNICODE}</Typography>
                    </StringCell>
                );
            },
            type: 'string',
            align: 'left',
        },
        {
            field: "phone",
            headerName: "Phone Number",
            hide: false,
            index: 2,
            renderCell: ({ row }) => {
                const phone = `${row?.px_user?.phoneNumber ?? ''}`;

                return (
                    <StringCell>
                        <Typography>{phone || M_DASH_UNICODE}</Typography>
                    </StringCell>
                );
            },
            type: 'string',
            align: 'left',
        },
        {
            field: "email",
            headerName: "Email",
            hide: false,
            index: 2,
            renderCell: ({ row }) => {
                const mail = `${row?.px_user?.email ?? ''}`;

                return (
                    <StringCell>
                        <Typography>{mail || M_DASH_UNICODE}</Typography>
                    </StringCell>
                );
            },
            type: 'string',
            align: 'left',
        },
        {
            field: 'isActive',
            headerName: 'Status',
            hide: false,
            index: 3,
            renderCell: ({ row }) => {
                return (
                    <StringCell>
                        <Switch
                            checked={row.isActive}
                            onChange={(e) => handleChangeStatus(e.target.checked, row.id)}
                        />
                    </StringCell>
                )
            },
            align: 'left',
        },
        {
            field: 'action',
            headerName: 'Action',
            hide: false,
            index: 4,
            renderCell: ({row}) => {
                return (
                    // <StringCell>
                        <Box>
                            <Button
                                id="btn_add_edit_role"
                                variant="contained"
                                icon={<EditIcon />}
                                color="primary"
                                fullWidth={false}
                                size="small"
                                onClick={() => navigate(`/client/edit/${row.id}`)}
                            />
                            <Button
                                id="btn_add_delete_role"
                                variant="contained"
                                icon={<DeleteIcon />}
                                color="primary"
                                fullWidth={false}
                                addSpaceBetweenButtons={true}
                                size="small"
                                onClick={() => [setShowDeleteModal(!showDeleteModal), setSelectedId(row?.id)]}
                            />
                        </Box>
                    // </StringCell>
                )
            },
            align: 'left'
        }
        // eslint-disable-next-line
    ], []);

    useEffect(() => {
        fetchClientList()
        // eslint-disable-next-line
    }, []);

    return {
        list,
        page,
        count,
        headerList,
        showDeleteModal,
        setPage,
        handleDelete,
        setShowDeleteModal
    }
}

export default UseClientHooks;