import { useContext, useState } from 'react';
import { AppContext } from '../../core/context/appContextProvider';
import { changePassword } from '../../services/login.service';

const UseNavbarHook = () => {
    const {
        loading,
        loginUser,
        onLogout,
        informationAlert
    } = useContext(AppContext);

    const [anchorEl, setAnchorEl] = useState(null);
    const [openChangePasswordBox, setOpenChangePasswordBox] = useState(false);

    const open = Boolean(anchorEl);

    const toggleChangePasswordBox = () => {
        setOpenChangePasswordBox(!openChangePasswordBox);
    }

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleLogout = () => {
        onLogout();
        window.location.reload();
    }

    const handleChangePassword = async (info) => {
        console.log(info);
        const payload = {
            id: loginUser?.id,
            oldPassword: info.oldPassword,
            newPassword: info.newPassword
        }
        const { success, message } = await changePassword(payload);
        if(success) {
            setOpenChangePasswordBox(false);
            informationAlert(message, 'info');
            handleLogout();
        } else {
            informationAlert(message, 'error');
        }
    }

    return {
        open,
        loading,
        anchorEl,
        loginUser,
        openChangePasswordBox,
        handleClick,
        handleClose,
        handleLogout,
        handleChangePassword,
        toggleChangePasswordBox,
        setOpenChangePasswordBox,
    }
};

export default UseNavbarHook;