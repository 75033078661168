import { useNavigate } from "react-router-dom";
import { Controller } from "react-hook-form";

import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

import Button from "../../../components/Buttons/Button/Button";

import { HeaderRow } from "../role.styles";

import UseAddEditRoleHook from "./addEditRole.hooks";

const AddEditRole = () => {
    const {
        mode,
        control,
        onSubmit,
        handleSubmit
    } = UseAddEditRoleHook();
    const navigate = useNavigate();
    return (
        <Container maxWidth={false}>
            <Box textAlign={'left'}>
                <HeaderRow>
                    <Typography variant="pageTitle" textTransform={"capitalize"}>{mode} Role</Typography>
                    {/* <Button
                        id="btn_back"
                        variant="contained"
                        text="Back"
                        color="primary"
                        onClick={() => navigate('/role')}
                    /> */}
                </HeaderRow>
                <Box>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <Controller
                                    control={control}
                                    name="name"
                                    render={({
                                        field: { onBlur, onChange, value },
                                        fieldState: { error },
                                    }) => (
                                        <FormControl size="small" variant="standard">
                                            <TextField
                                                label="Role Name*"
                                                size="small"
                                                name="roleName"
                                                value={value}
                                                variant="outlined"
                                                onChange={onChange}
                                                inputProps={{ style: { textTransform: 'capitalize'}}}
                                                onBlur={onBlur}
                                                error={!!error}
                                                helperText={error?.message}
                                            />
                                        </FormControl>
                                    )}
                                    rules={{
                                        required: "Please Enter Role Name",
                                    }}
                                />
                            </Grid>
                        </Grid>
                        <Box sx={{ display: 'flex', gap: '12px', padding: '12px 0px'}}>
                            <Button
                                id="btn_back"
                                variant="contained"
                                type="button"
                                text="Back"
                                color="primary"
                                onClick={() => navigate('/role')}
                            />
                            <Button
                                id="btn_back"
                                variant="contained"
                                type="submit"
                                text={mode}
                                color="primary"
                            />
                        </Box>
                    </form>
                </Box>
            </Box>
        </Container>
    )
}

export default AddEditRole;