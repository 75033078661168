import { redirect } from "react-router-dom";
import { QRCode } from 'react-qrcode-logo';
import { TOKEN_KEY, DEV_API_URL, PROD_API_URL, CLIENT_URL } from "../constants/constants";

export const imagePath = (path) => {
    //  || window?.location.host.includes('api-vbc.kuldeepakbari.com')
    if(window?.location.host.includes('localhost') || window?.location.host.includes('admin-vbc.kuldeepakbari.com')) {
        return `${DEV_API_URL.split('/api/')[0]}/${path}`;
    } else {
        return `${PROD_API_URL.split('/api/')[0]}/${path}`;
    }
}

export const createQrCode = (value, logo = '') => {
    return <QRCode enableCORS={true} removeQrCodeBehindLogo={true} value={`${CLIENT_URL}/${value}`} logoImage={logo} logoWidth={50} logoHeight={50} logoOpacity={1} ecLevel="H"/>
}

export const getFormData = (object) => {
    const formData = new FormData();
    Object.keys(object).forEach(key => formData.append(key, object[key]));
    return formData;
  }

export const listPayload = (page, where = {}, rows = 10, pagination = {}) => {
    return {
      where: {
        isDeleted: false,
        ...where,
      },
      pagination: {
        sortBy: "createdAt",
        descending: true,
        rows: rows,
        page: page + 1,
        ...pagination,
      },
    };
  };

export const setTitleAndSuffix = (title) => {
    return `${title} - VB Card`;
}

export const getAuthToken = () => {
    const value = localStorage.getItem(TOKEN_KEY);
    if (value) {
        return value;
    } else {
        return null;
    }
};

export const checkIsAuthenticated = () => {
    const value = getAuthToken();
    if (value === null) {
        return redirect("/login");
    }
    return null;
};

export const rightsAccess = (accessModules, pathname) => {
    if (accessModules && accessModules.length > 0) {
        const selectedModule = accessModules.find(
            (res) => res.px_module.path === pathname
        );
        return {
            add: selectedModule.add || false,
            edit: selectedModule.edit || false,
            delete: selectedModule.delete || false,
        };
    } else {
        return {
            add: false,
            edit: false,
            delete: false,
        };
    }
};

export const socialMediaDefaultValue = [
    {
        index: 0,
        socialMediaID: null,
        name: 'location',
        link: ''
    },
    {
        index: 1,
        socialMediaID: null,
        name: 'youtube',
        link: ''
    },
    {
        index: 2,
        socialMediaID: null,
        name: 'facebook',
        link: ''
    },
    {
        index: 3,
        socialMediaID: null,
        name: 'instagram',
        link: ''
    },
    {
        index: 4,
        socialMediaID: null,
        name: 'twitter',
        link: ''
    },
    {
        index: 5,
        socialMediaID: null,
        name: 'linkedin',
        link: ''
    },
    {
        index: 6,
        socialMediaID: null,
        name: 'skype',
        link: ''
    },
    {
        index: 7,
        socialMediaID: null,
        name: 'telegram',
        link: ''
    },
    {
        index: 8,
        socialMediaID: null,
        name: 'snapchat',
        link: ''
    },
    {
        index: 9,
        socialMediaID: null,
        name: 'blog',
        link: ''
    },
    {
        index: 10,
        socialMediaID: null,
        name: 'google plus',
        link: ''
    },
    {
        index: 11,
        socialMediaID: null,
        name: 'pinterest',
        link: ''
    },
];

export const onlinePayList = [
    {
        index: 0,
        onlinePayID: null,
        appName: 'google pay',
        number: '',
        upi: '',
        qrCode: null
    },
    {
        index: 1,
        onlinePayID: null,
        appName: 'paytm',
        number: '',
        upi: '',
        qrCode: null
    },
    {
        index: 2,
        onlinePayID: null,
        appName: 'phone pe',
        number: '',
        upi: '',
        qrCode: null
    },
    {
        index: 3,
        onlinePayID: null,
        appName: 'bharat pe',
        number: '',
        upi: '',
        qrCode: null
    },
]