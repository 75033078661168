import React from 'react';
import { Link } from 'react-router-dom';

import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import Progress from "../Progress/ProgressModal/ProgressModal";
import ChangePasswordDailog from "../Modal/ChangePasswordDialog/ChangePasswordDialog";

import { NavBarContainer, StyledImage, StyledAccountCircle } from './Navbar.styles.jsx';
import UseNavbarHook from './Navbar.hooks.jsx';

const Navbar = () => {
    const {
        open,
        loading,
        anchorEl,
        loginUser,
        openChangePasswordBox,
        handleClick,
        handleClose,
        handleLogout,
        handleChangePassword,
        toggleChangePasswordBox,
    } = UseNavbarHook();
    return (
        <NavBarContainer
            position='static'
            color='transparent'
        >
            <Progress showProgress={loading} />
            <Box component={Link} to={'/'} sx={{ textDecoration: 'none'}}>
                <StyledImage
                    fullwidth="true"
                    alt="Logo"
                    src={undefined}
                />
            </Box>
            <Box/>
            <Box display="flex" alignItems="center">
                <Box textAlign='end'>
                    <Typography>
                        {loginUser?.firstName} {loginUser?.lastName}
                    </Typography>
                    <Typography variant='inherit'>
                        {loginUser?.px_role?.name}
                    </Typography>
                </Box>
                <IconButton
                    aria-label="user-account"
                    color="inherit"
                    id={"user-account"}
                    onClick={handleClick}
                >
                    <StyledAccountCircle fontSize='large'/>
                </IconButton>
                <Menu
                    id="profile-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                        "aria-labelledby": "menu-button",
                    }}
                >
                    <MenuItem id="change-password" onClick={toggleChangePasswordBox}>
                        <Typography>Change Password</Typography>
                    </MenuItem>
                    <MenuItem id="logout" onClick={handleLogout}>
                        <Typography>Logout</Typography>
                    </MenuItem>
                </Menu>
            </Box>
            <ChangePasswordDailog
                id="change-password-dailog-box"
                open={openChangePasswordBox}
                onClose={toggleChangePasswordBox}
                onSubmit={handleChangePassword}
            />
        </NavBarContainer>
    )
}

export default React.memo(Navbar);