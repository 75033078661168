import {
    ApiClient
} from './web.service';
import {
    APIPATH
} from '../utils/constants/constants';

const apiClient = () => {
    return new ApiClient();
}

export const fetchSettingGrid = async (payload) => {
    return apiClient().post(`${APIPATH.SETTING}/list`, payload);
}

export const createSetting = async (payload) => {
    return apiClient().post(APIPATH.SETTING, payload);
}

export const updateSetting = async (id, payload) => {
    return apiClient().put(`${APIPATH.SETTING}/${id}`, payload);
}

export const fetchByIdSetting = async (id) => {
    return apiClient().get(`${APIPATH.SETTING}/${id}`);
}

export const deleteSetting = async (id) => {
    return apiClient().delete(`${APIPATH.SETTING}/${id}`)
}