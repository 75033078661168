import { useContext, useMemo } from 'react';

import { NavBarContext } from '../../core/context/navbarContext';
import { AppContext } from '../../core/context/appContextProvider';

const UseSidebarHook = () => {
    const {
        isNavBarOpen,
        isNavBarPinned,
        setIsNavBarOpen,
        setIsNavBarPinned
    } = useContext(NavBarContext);

    const {
        accessModules
    } = useContext(AppContext);

    const sideNavs = useMemo(() => {
        const dynamicModules = accessModules?.map((item, index) => {
            return {
                id: (index + 2),
                label: item?.px_module?.name,
                routerLink: item?.px_module?.routerLink,
                canDisable: false,
                icon: item?.px_module?.icon,
                children: JSON.parse(item?.px_module?.childRoute)?.map((childItem) => {
                    return {
                        label: childItem?.name,
                        routerLink: childItem?.routerLink,
                        canDisable: false
                    }
                })
            }
        })
        return [
            {
                id: 1,
                label: 'Dashboard',
                routerLink: '/',
                canDisable: false,
                icon: 'HomeIcon'
            },
            ...dynamicModules
        ];
    }, [accessModules]);

    // const sideNav = [
    //     {
    //         id: 1,
    //         label: 'Dashboard',
    //         routerLink: '/',
    //         canDisable: false,
    //         icon: 'HomeIcon'
    //     },
    //     {
    //         id: 2,
    //         label: 'Master',
    //         canDisable: false,
    //         icon: 'FolderRounded',
    //         children: [
    //             {
    //                 label: 'Rights',
    //                 routerLink: '/rights',
    //                 canDisable: false
    //             },
    //             {
    //                 label: 'Module',
    //                 routerLink: '/module',
    //                 canDisable: false
    //             },
    //             {
    //                 label: 'Role',
    //                 routerLink: '/role',
    //                 canDisable: false
    //             },
    //             // {
    //             //     label: 'Rights',
    //             //     routerLink: '/rights',
    //             //     canDisable: false
    //             // },
    //             {
    //                 label: 'Settings',
    //                 routerLink: '/settings',
    //                 canDisable: false
    //             },
    //             {
    //                 label: 'Card Theme',
    //                 routerLink: '/card-theme',
    //                 canDisable: false
    //             },
    //         ]
    //     },
    //     {
    //         id: 3,
    //         label: 'User',
    //         canDisable: false,
    //         icon: 'ContactsRounded',
    //         routerLink: '/user'
    //     }
    // ];

    const openSideBar = () => {
        if (isNavBarPinned) {
            return;
        }
        setIsNavBarOpen(true);
    };

    const closeSideBar = () => {
        if (isNavBarPinned) {
            return;
        }
        setIsNavBarOpen(false);
    };

    const togglePinSideBar = () => {
        setIsNavBarPinned(!isNavBarPinned);
    };

    return {
        isNavBarOpen,
        openSideBar,
        closeSideBar,
        isNavBarPinned,
        togglePinSideBar,
        sideNavs
    };
}

export default UseSidebarHook;