import { Popover, styled } from "@mui/material";

export const StyledPopover = styled(Popover)`
  margin-top: ${(props) => {
    if (props.displaysfromtop === "true") {
      return "30px";
    }
    return "0px";
  }};

  & .MuiPaper-root {
    min-width: ${(props) => (props.size === "sm" ? "228px" : "320px")};
    ${(props) => (props.size === "lg" ? "max-width:320px" : "")};
    margin-top: ${(props) => {
    if (props.displaysfromtop === "true" || props.inverted === "true") {
      return "0px";
    }
    return "10px";
  }};
    margin-bottom: ${(props) => (props.inverted === "true" ? "10px" : "")};
    border-radius: 10px;
    overflow: visible;
    box-shadow: 0 0 22px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0 0 22px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 0 22px rgba(0, 0, 0, 0.3);
  }

  & .MuiList-root {
    max-height: 650px;
    overflow: auto;
  }

  & .MuiPaper-root:before {
    content: "";
    height: 0;
    position: absolute;
    width: 0;
    top: ${(props) => (props.inverted === "false" ? "-20px" : "")};
    bottom: ${(props) => (props.inverted === "true" ? "-20px" : "")};
    right: ${(props) => (props.inverted === "false" ? "10px" : "")};
    left: ${(props) => ((props.inverted === "true" || props.arrowleft === "true") ? "10px" : "")};
    border: 10px solid transparent;
    border-bottom-color: WHITE;
    margin-right: "-0.71em";
    overflow: visible;
    transform: ${(props) =>
    props.inverted === "true" ? "rotate(180deg)" : ""};
  }
`;
