import { styled, Typography } from "@mui/material";
import { DataGridPro } from "@mui/x-data-grid-pro";

import {
  GRID_HEADER_BACKGROUND_COLOR,
  GRID_HEADER_BORDER_COLOR,
  PRIMARY,
  ROW_HOVER_BACKGROUND_COLOR,
} from "../../styles/colors";

export const StyledDataGridPro = styled(DataGridPro)`
  border: 1px solid transparent;
  border-radius: 0;
  min-height: ${(props) => props.minHeight};
  padding-bottom: 25px;

  .MuiDataGrid-row.Mui-hovered {
    background-color: ${ROW_HOVER_BACKGROUND_COLOR};
  }

  .MuiDataGrid-cell:focus {
    outline: none;
  }

  .MuiDataGrid-cell:focus-within {
    outline: none;
  }

  .MuiDataGrid-columnHeaders {
    background-color: ${GRID_HEADER_BACKGROUND_COLOR};
    border: 1px solid ${GRID_HEADER_BORDER_COLOR};
    border-bottom: 1px solid ${GRID_HEADER_BORDER_COLOR};
    border-left: 1px solid ${GRID_HEADER_BORDER_COLOR};
    min-height: 30px !important;
    max-height: 30px !important;
    line-height: 30px !important;
  }

  .MuiDataGrid-columnHeader {
    background-color: ${GRID_HEADER_BACKGROUND_COLOR};
    padding: 1px;
    width: 100%;
    .MuiDataGrid-columnHeaderTitleContainer {
      width: 100%;
      .MuiDataGrid-columnHeaderTitleContainerContent {
        width: 100%;
      }
    }
  }

  .MuiDataGrid-columnHeader:focus {
    outline: none;
  }

  .MuiDataGrid-columnSeparator {
    color: transparent;
  }

  .MuiDataGrid-columnSeparator:hover {
    color: transparent;
  }

  .MuiDataGrid-iconButtonContainer {
    visibility: visible;
    width: auto;
  }

  .MuiDataGrid-pinnedColumnHeaders {
    padding-right: 0px !important;
  }

  .MuiDataGrid-pinnedColumns {
    box-shadow: -6px 0px 12px -7px rgba(0, 0, 0, 0.5);
    -webkit-box-shadow: -6px 0px 12px -7px rgba(0, 0, 0, 0.5);
    -moz-box-shadow: -6px 0px 12px -7px rgba(0, 0, 0, 0.5);
  }

  .group-parent .MuiDataGrid-cell, .grouped-row:not(:last-child) .MuiDataGrid-cell {
    border-bottom: 0px;
  }

  .group-parent:not(:first-of-type) .MuiDataGrid-cell {
    border-top: 1px solid ${GRID_HEADER_BORDER_COLOR};
  }

  .investor-row p{
    font-weight: bold;
  }

  .cap-amount p{
    color: ${PRIMARY};
    cursor: pointer;
  }
`;

export const StringCell = styled(Typography)(({
  theme
}) => ({
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
}));
