
import {
    RouterProvider,
    createBrowserRouter,
} from "react-router-dom";

import {
    checkIsAuthenticated
} from "./utils/helpers";

import RoutingPaths from "./core/routing/routingPaths";
import Dashboard from "./pages/dashboard/dashboard";
import Login from "./pages/auth/login/login";
import NotFound from "./components/NotFound/NotFound";
import Layout from "./components/Layout/Layout";
import Role from './pages/role/role';
import AddEditRole from './pages/role/component/addEditRole';
import Settings from "./pages/settings/settings";
import AddEditSettings from "./pages/settings/component/addEditSettings";
import CardTheme from "./pages/cardTheme/cardTheme";
import AddEditCardTheme from "./pages/cardTheme/component/addEditCardTheme";
import User from "./pages/user/user";
import AddEditUser from "./pages/user/component/addEditUser";
import Module from "./pages/module/module";
import AddEditModule from "./pages/module/component/addEditModule";
import Rights from "./pages/rights/rights";
import Plan from "./pages/plan/plan";
import AddEditPlan from "./pages/plan/component/addEditPlan";
import Coupon from "./pages/coupon/coupon";
import AddEditCoupon from "./pages/coupon/component/addEditCoupon";
import AddOn from "./pages/addOn/addOn";
import AddEditAddOn from "./pages/addOn/component/addEditAddOn";
import Client from "./pages/client/client";
import AddEditClient from "./pages/client/component/addEditClient";
import ProtectedRoute from "./components/ProtectedRoute";

const Routes = () => {

    const routes = createBrowserRouter([
        {
            path: RoutingPaths.AppDashboard,
            element: <Layout/>,
            errorElement: <NotFound />,
            loader: checkIsAuthenticated,
            children: [
                { index: true, element: (<ProtectedRoute path={RoutingPaths.AppDashboard} Component={<Dashboard/>}/>)},
                { path: RoutingPaths.Role, element: <ProtectedRoute path={RoutingPaths.Role} Component={<Role/>}/>},
                { path: `${RoutingPaths.Role}/:mode`, element: <AddEditRole/>},
                { path: `${RoutingPaths.Role}/:mode/:id`, element: <AddEditRole/>},
                { path: RoutingPaths.Setting, element: <Settings/>},
                { path: `${RoutingPaths.Setting}/:mode`, element: <AddEditSettings/>},
                { path: `${RoutingPaths.Setting}/:mode/:id`, element: <AddEditSettings/>},
                { path: RoutingPaths.CardTheme, element: <CardTheme/>},
                { path: `${RoutingPaths.CardTheme}/:mode`, element: <AddEditCardTheme/>},
                { path: `${RoutingPaths.CardTheme}/:mode/:id`, element: <AddEditCardTheme/>},
                { path: RoutingPaths.User, element: <User/>},
                { path: `${RoutingPaths.User}/:mode`, element: <AddEditUser/>},
                { path: `${RoutingPaths.User}/:mode/:id`, element: <AddEditUser/>},
                { path: RoutingPaths.Module, element: <Module/>},
                { path: `${RoutingPaths.Module}/:mode`, element: <AddEditModule/>},
                { path: `${RoutingPaths.Module}/:mode/:id`, element: <AddEditModule/>},
                { path: RoutingPaths.Right, element: <Rights/>},
                { path: RoutingPaths.Plan, element: <Plan/>},
                { path: `${RoutingPaths.Plan}/:mode`, element: <AddEditPlan/>},
                { path: `${RoutingPaths.Plan}/:mode/:id`, element: <AddEditPlan/>},
                { path: RoutingPaths.Coupon, element: <Coupon/>},
                { path: `${RoutingPaths.Coupon}/:mode`, element: <AddEditCoupon/>},
                { path: `${RoutingPaths.Coupon}/:mode/:id`, element: <AddEditCoupon/>},
                { path: RoutingPaths.AddOn, element: <AddOn/>},
                { path: `${RoutingPaths.AddOn}/:mode`, element: <AddEditAddOn/>},
                { path: `${RoutingPaths.AddOn}/:mode/:id`, element: <AddEditAddOn/>},
                { path: RoutingPaths.Client, element: <Client/>},
                { path: `${RoutingPaths.Client}/:mode`, element: <AddEditClient/>},
                { path: `${RoutingPaths.Client}/:mode/:id`, element: <AddEditClient/>},
            ]
        },
        { path: RoutingPaths.SigninPath, element: <Login/>},
        { path: "*", element: <NotFound /> },
    ]);

    return (
        <RouterProvider router={routes} />
    )
}

export default Routes;