import {
  ContactsRounded,
  DeviceHubRounded,
  DownloadRounded,
  FolderRounded,
  LeaderboardRounded,
  LocalAtmRounded,
  MailRounded,
  MonetizationOnRounded,
  PaymentRounded,
  PeopleAltRounded,
  RequestQuoteRounded,
  UploadRounded
} from "@mui/icons-material";
import HomeIcon from '@mui/icons-material/Home';
import React, { useCallback } from 'react';


const MenuOptionIcon = ({
  iconKey, sx
}) => {

  const getIcon = useCallback(() => {
    switch (iconKey) {
      case "MonetizationOnRounded":
        return <MonetizationOnRounded sx={sx} />;
      case "MailRounded":
        return <MailRounded sx={sx}/>;
      // case "investors":
      //   return <InvestorIcon color={sx?.color} />;
      case "ContactsRounded":
        return <ContactsRounded sx={sx}/>;
      case "PaymentRounded":
        return <PaymentRounded sx={sx}/>;
      case "LocalAtmRounded":
        return <LocalAtmRounded sx={sx}/>;
      case "FolderRounded":
        return <FolderRounded sx={sx}/>;
      case "RequestQuoteRounded":
        return <RequestQuoteRounded sx={sx}/>;
      case "DeviceHubRounded":
        return <DeviceHubRounded sx={sx}/>;
      case "LeaderboardRounded":
        return <LeaderboardRounded sx={sx}/>;
      case "PeopleAltRounded":
        return <PeopleAltRounded sx={sx}/>;
      case "UploadRounded":
        return <UploadRounded sx={sx}/>;
      case "DownloadRounded":
        return <DownloadRounded sx={sx}/>;
      case "HomeIcon":
      default:
        return <HomeIcon sx={sx}/>;
    }
  }, [iconKey, sx]);

  return (
    <>
      {getIcon()}
    </>
  );
};

export default React.memo(MenuOptionIcon);
