import {
    ApiClient
} from './web.service';
import {
    APIPATH
} from '../utils/constants/constants';

const apiClient = () => {
    return new ApiClient();
}

export const fetchAddOnGrid = async (payload) => {
    return apiClient().post(`${APIPATH.ADDON}/list`, payload);
}

export const createAddOn = async (payload) => {
    return apiClient().post(APIPATH.ADDON, payload);
}

export const updateAddOn = async (id, payload) => {
    return apiClient().put(`${APIPATH.ADDON}/${id}`, payload);
}

export const fetchByIdAddOn = async (id) => {
    return apiClient().get(`${APIPATH.ADDON}/${id}`);
}

export const deleteAddOn = async (id) => {
    return apiClient().delete(`${APIPATH.ADDON}/${id}`)
}