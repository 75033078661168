import { IconButton } from "@mui/material"
import React, { useEffect, useState } from "react"

import IconClose from "../../assets/images/icons/icon_close.svg"
import IconSearch from "../../assets/images/icons/icon_search.svg"
import { DEBOUNCE_THRESHOLD } from "../../utils/constants/constants"
import { SearchInputAdornment } from "../DataGrid/Filter/Filter.style"
import ImgIcon from "../ImgIcon/ImgIcon"
import { SearchBarInput } from "./SearchBar.styles"

const SearchBar = ({
  id,
  size = "small",
  useIcon = true,
  placeHolder = "Search",
  onChange,
  value,
  readOnly = false,
  ...other
}) => {
  const [timeoutHandler, setTimeoutHandler] = useState(null)
  const [searchValue, setSearchValue] = useState(value)

  const handleChange = e => {
    if (timeoutHandler) {
      clearTimeout(timeoutHandler)
    }
    const timeout = setTimeout(() => {
      onChange(e.target.value)
    }, DEBOUNCE_THRESHOLD)

    setSearchValue(e.target.value)
    setTimeoutHandler(timeout)
  }

  const handleClear = () => {
    setSearchValue("")
    onChange("")
  }

  useEffect(() => {
    if (value !== searchValue) {
      setSearchValue(value)
    }
  }, [value, searchValue])

  return (
    <SearchBarInput
      id={id}
      size={size}
      placeholder={placeHolder}
      onChange={handleChange}
      value={searchValue}
      InputProps={{
        startAdornment: useIcon ? (
          <SearchInputAdornment position="start">
            <ImgIcon icon={IconSearch} useCursorPointer={true} />
          </SearchInputAdornment>
        ) : (
          undefined
        ),
        endAdornment:
          readOnly || !searchValue || searchValue?.length === 0 ? (
            <SearchInputAdornment position="end">
              <IconButton aria-label="clear">
                <ImgIcon icon={IconClose} useCursorPointer={true} hide />
              </IconButton>
            </SearchInputAdornment>
          ) : (
            <SearchInputAdornment position="end">
              <IconButton onClick={handleClear} aria-label="clear">
                <ImgIcon icon={IconClose} useCursorPointer={true} />
              </IconButton>
            </SearchInputAdornment>
          ),
        readOnly
      }}
      {...other}
    />
  )
}

export default SearchBar
