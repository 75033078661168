import {
    ApiClient
} from './web.service';
import {
    APIPATH
} from '../utils/constants/constants';

const apiClient = () => {
    return new ApiClient();
}

export const fetchPlanGrid = async (payload) => {
    return apiClient().post(`${APIPATH.PLAN}/list`, payload);
}

export const createPlan = async (payload) => {
    return apiClient().post(APIPATH.PLAN, payload);
}

export const updatePlan = async (id, payload) => {
    return apiClient().put(`${APIPATH.PLAN}/${id}`, payload);
}

export const fetchByIdPlan = async (id) => {
    return apiClient().get(`${APIPATH.PLAN}/${id}`);
}

export const deletePlan = async (id) => {
    return apiClient().delete(`${APIPATH.PLAN}/${id}`)
}