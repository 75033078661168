import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";

import { AppContext } from "../../../core/context/appContextProvider";
import { login } from "../../../services/login.service";

export const useLogin = () => {
    const navigate = useNavigate();
    const {
        isMobileView,
        setTitle,
        setLoading,
        informationAlert,
        handleSetLoginUser,
    } = useContext(AppContext);

    const [showPassword, setShowPassword] = useState(false);

    const {
        control,
        handleSubmit
    } = useForm({
        defaultValues: {
            email: '',
            password: '',
        },
        mode: 'onBlur'
    })

    useEffect(() => {
        setTitle('Login')
    }, [setTitle]);

    const submit = async (info) => {
        try {
            setLoading(true);
            const { success, message, data } = await login(info);
            if(success) {
                handleSetLoginUser(data);
                navigate('/');
            } else {
                informationAlert(message, "info");
            }
        } catch(err) {
            console.log(err);
            informationAlert(err.message, "error");
        } finally {
            setLoading(false);
        }
    }

    return {
        control,
        showPassword,
        isMobileView,
        submit,
        handleSubmit,
        setShowPassword,
    }
}