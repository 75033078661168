import { ClickAwayListener, Link } from "@mui/material"
import React from "react"

import { StyledStack } from "../../ChipsGroup/ChipsGroup.style"
import { getValue } from "../DataGrid.helper"
import { Chip, ChipBox, PopoverColumnArrow, StyledBox } from "./ChipCell.style"

const ChipsCell = ({
  name,
  items,
  chipLabelField,
  chipIDField,
  chipCount = 1,
  onChipClick,
  toggleVisibilityOnClick = false,
  variant = "filled",
  display = "flex"
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null)

  const handleOnHover = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleOnClose = () => {
    setAnchorEl(null)
  }

  const handleOnClick = event => {
    setAnchorEl(anchor => (anchor ? null : event.currentTarget))
  }

  const getChips = () => {
    const chips = items.slice(0, chipCount)
    const chipProps = {}

    if (typeof onChipClick === "function" && name) {
      chipProps.onClick = () => onChipClick(name, items)
    }

    return chips.map((chip, index) => (
      <Chip
        display={display}
        key={index}
        label={
          chipLabelField.length === 0
            ? chip
            : getValue(chipLabelField, chip ?? {})
        }
        variant={variant}
        {...chipProps}
      />
    ))
  }

  const link = React.useMemo(() => {
    const props = toggleVisibilityOnClick
      ? {
          onClick: handleOnClick
        }
      : {
          onMouseEnter: handleOnHover,
          onMouseLeave: handleOnClose
        }

    return (
      <Link
        id="btn_data_grid_columns_filter"
        underline="none"
        component="button"
        {...props}
      >
        {items.length > chipCount ? `+${items.length - chipCount} more` : ""}
      </Link>
    )
  }, [items.length, chipCount, toggleVisibilityOnClick])

  return (
    <>
      <StyledBox display={display} gap={0.5}>
        {items.length > 0 ? getChips() : ""}
        {link}
      </StyledBox>
      <PopoverColumnArrow
        id={`column-cell-popover`}
        sx={{
          pointerEvents: "none"
        }}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        hideBackdrop={true}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left"
        }}
        disableAutoFocus={true}
        disableEnforceFocus={true}
        onClose={handleOnClose}
        disableRestoreFocus
      >
        <ChipBox />
        <ClickAwayListener onClickAway={handleOnClose}>
          <StyledStack
            direction="column"
            spacing={1}
            sx={{
              p: 2,
              backgroundColor: "white",
              pointerEvents: toggleVisibilityOnClick ? "auto" : "none"
            }}
          >
            {items.slice(chipCount, items.length).map(item => {
              const label =
                chipLabelField.length === 0
                  ? item
                  : getValue(chipLabelField, item)
              const id = getValue(chipIDField, item)

              return <div key={id}>{label}</div>
            })}
          </StyledStack>
        </ClickAwayListener>
      </PopoverColumnArrow>
    </>
  )
}

export default ChipsCell
