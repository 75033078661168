import { Button, styled } from "@mui/material";

import {
  ERROR,
  ERROR_HOVER,
  ERROR_HOVER_TEXT,
  ERROR_PRESSED_TEXT,
  PRIMARY,
  PRIMARY_DISABLED,
  PRIMARY_HOVER,
  PRIMARY_PRESSED,
  SECONDARY_HOVER,
  SECONDARY_HOVER_TEXT,
  SECONDARY_PRESSED_TEXT,
  WHITE,
} from "../../../styles/colors";
import { toHexWithOpacity } from "../../../utils/helpers/toHexWithOpacity";

export const StyledButton = styled(Button)`
  text-transform: capitalize;
  min-width: ${(props) => props.width ? props.width : '130px'}

  background-color: ${(props) => {
    switch (props.buttoncolor) {
      case "primary":
        return props.backgroundcolor || PRIMARY;
      default:
        return WHITE;
    }
  }};
  color: ${(props) => {
    switch (props.buttoncolor) {
      case "primary":
        return props.textcolor || WHITE;
      case "secondary":
        return props.backgroundcolor || PRIMARY;
      case "error":
        return ERROR;
      default:
        return WHITE;
    }
  }};
  border: ${(props) => {
    if (props.variant === "text") return "none";
    else
      switch (props.buttoncolor) {
        case "primary":
          return `1px solid ${props.backgroundcolor || PRIMARY}`;
        case "secondary":
          return `1px solid ${props.backgroundcolor || PRIMARY}`;
        case "error":
          return `1px solid ${ERROR}`;
        default:
          return PRIMARY;
      }
  }};

  &:hover {
    background-color: ${(props) => {
    switch (props.buttoncolor) {
      case "primary":
        return props.backgroundcolor ? toHexWithOpacity(props.backgroundcolor, 0.8) : PRIMARY_HOVER;
      case "secondary":
        return SECONDARY_HOVER;
      case "error":
        return ERROR_HOVER;
      default:
        return PRIMARY_HOVER;
    }
  }};
    color: ${(props) => {
    switch (props.buttoncolor) {
      case "primary":
        return props.textcolor ? toHexWithOpacity(props.textcolor, 0.8) : WHITE;
      case "secondary":
        return props.backgroundcolor ? toHexWithOpacity(props.backgroundcolor, 0.8) : SECONDARY_HOVER_TEXT;
      case "error":
        return ERROR_HOVER_TEXT;
      default:
        return WHITE;
    }
  }};
    border: ${(props) => {
    if (props.variant === "text") return "none";
    else
      switch (props.buttoncolor) {
        case "primary":
          return `1px solid ${props.backgroundcolor || PRIMARY}`;
        case "secondary":
          return `1px solid ${props.backgroundcolor ? toHexWithOpacity(props.backgroundcolor, 0.8) : SECONDARY_HOVER_TEXT}`;
        case "error":
          return `1px solid ${ERROR_HOVER_TEXT}`;
        default:
          return 'none';
      }
  }};
  }

  &:active {
    background-color: ${(props) => {
    switch (props.buttoncolor) {
      case "primary":
        return props.backgroundcolor || PRIMARY_PRESSED;
      default:
        return WHITE;
    }
  }};
    color: ${(props) => {
    switch (props.buttoncolor) {
      case "primary":
        return props.textcolor || WHITE;
      case "secondary":
        return props.backgroundcolor || SECONDARY_PRESSED_TEXT;
      case "error":
        return ERROR_PRESSED_TEXT;
      default:
        return 'none';
    }
  }};
    border: ${(props) => {
    switch (props.buttoncolor) {
      case "primary":
        return props.backgroundcolor || PRIMARY;
      case "secondary":
        return props.backgroundcolor || SECONDARY_PRESSED_TEXT;
      case "error":
        return ERROR_PRESSED_TEXT;
      default:
        return 'none';
    }
  }};
  }

  &:focus {
    background-color: ${(props) => {
    switch (props.buttoncolor) {
      case "primary":
        return props.backgroundcolor || PRIMARY_PRESSED;
      default:
        return WHITE;
    }
  }};
    color: ${(props) => {
    switch (props.buttoncolor) {
      case "primary":
        return WHITE;
      case "secondary":
        return props.backgroundcolor || SECONDARY_PRESSED_TEXT;
      case "error":
        return ERROR_PRESSED_TEXT;
      default:
        return 'none';
    }
  }};
    border: ${(props) => {
    switch (props.buttoncolor) {
      case "primary":
        return `1px solid ${props.backgroundcolor || PRIMARY_PRESSED}`;
      case "secondary":
        return `1px solid ${props.backgroundcolor || SECONDARY_PRESSED_TEXT}`;
      case "error":
        return `1px solid ${ERROR_PRESSED_TEXT}`;
      default:
        return 'none';
    }
  }};
  }

  &:disabled {
    border-color: ${PRIMARY_DISABLED};
    background-color: ${PRIMARY_DISABLED};
  }

  margin-left: ${(props) => {
    if (props.spacebetween === "true") return "10px";
    else return "0px";
  }};

  margin-right: ${(props) => {
    if (props.spacebetween === "true") return "10px";
    else return "0px";
  }};

  ${(props) => {
    if (props.size === "small") return "height: 30px";
    else return "";
  }};
`;
