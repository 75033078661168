import { Outlet } from 'react-router-dom'

import Box from '@mui/material/Box';

import Navbar from '../Navbar/Navbar';
import Sidebar from '../Sidebar/Sidebar';

import {
    LayoutContainer,
    LayoutContentWithSideBar,
    LayoutContent
} from './Layout.styles';

const Layout = () => {
    return (
        <LayoutContainer role='main'>
            <Box>
                <Navbar/>
            </Box>
            <LayoutContentWithSideBar>
                <Sidebar/>
                <LayoutContent>
                    <Outlet/>
                </LayoutContent>
            </LayoutContentWithSideBar>
        </LayoutContainer>
    )
}

export default Layout;