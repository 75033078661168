import { FormControlLabel } from "@mui/material"
import React, { useEffect, useState } from "react"

import { ButtonText, FormControlCheckAll } from "../Filter.style"
import {
  FilterAllStatus,
  FilterCheckBox,
  FilterItem,
  ListFilter
} from "./FilterStatus.style"

const FilterStatus = ({
  options,
  selectedOptions,
  handleFilter,
  idField,
  labelField,
  filterName,
  clearable = true
}) => {
  const [selectedOptionList, setSelectedOptionList] = useState([])
  let selectedItem = []

  useEffect(() => {
    if (selectedOptions) {
      setSelectedOptionList(selectedOptions)
    }
  }, [selectedOptions])

  const onCheckAll = (_, checked) => {
    if (checked) {
      setSelectedOptionList(options?.map(item => item[idField]) ?? [])
    } else {
      setSelectedOptionList([])
    }
  }

  const onItemClick = id => {
    if (selectedOptionList.includes(id)) {
      selectedItem = selectedOptionList.filter(item => item !== id)

      setSelectedOptionList([...selectedItem])
      handleFilter(filterName, selectedItem)
    } else {
      selectedItem = [...selectedOptionList, id]

      setSelectedOptionList(selectedItem)
      handleFilter(filterName, selectedItem)
    }
  }

  return (
    <div id="popoverCheckFilter">
      <FilterAllStatus>
        <b>
          <FilterItem>
            <FormControlCheckAll
              id="checkAll"
              key="All"
              control={
                <FilterCheckBox
                  checked={selectedOptionList.length === options?.length}
                  indeterminate={
                    options &&
                    selectedOptionList.length > 0 &&
                    selectedOptionList.length < options?.length
                  }
                  onChange={onCheckAll}
                />
              }
              label="All"
            />
          </FilterItem>
        </b>
      </FilterAllStatus>
      <ListFilter>
        {options &&
          options.reduce((acc, cur) => {
            return [
              ...acc,
              <FilterItem key={cur[idField]}>
                <FormControlLabel
                  key={cur[idField]}
                  control={
                    <FilterCheckBox
                      id={`${cur[idField]}_check`}
                      name={cur[labelField]}
                      checked={selectedOptionList?.includes(cur[labelField])}
                      onChange={() => onItemClick(cur[idField])}
                    />
                  }
                  label={cur[labelField]}
                />
              </FilterItem>
            ]
          }, [])}
      </ListFilter>
      <ButtonText id="btnApply" variant="text">
        Apply
      </ButtonText>
    </div>
  )
}

export default FilterStatus
