import React from 'react';

import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';

import MenuOption from "./MenuOption";
import MenuOptionIcon from "./MenuOptionIcon";
import {
    SideBarList,
    SideBarContainer,
    StyledKeyboardDoubleArrowLeft,
    StyledKeyboardDoubleArrowRight
} from './Sidebar.styles.jsx';
import UseSidebarHook from './Sidebar.hooks.jsx';

const Sidebar = () => {
    const {
        sideNavs,
        isNavBarOpen,
        isNavBarPinned,
        openSideBar,
        closeSideBar,
        togglePinSideBar
    } = UseSidebarHook();
    return (
        <Collapse
            collapsedSize="60px"
            orientation="horizontal"
            in={isNavBarOpen}
            onMouseEnter={openSideBar}
            onMouseLeave={closeSideBar}
        >
            <SideBarContainer isnavbaropen={isNavBarOpen?.toString()}>
                <Collapse
                    sx={{
                        display: 'flex',
                        justifyContent: 'flex-end'
                    }}
                    collapsedSize='40px'
                    orientation='horizontal'
                    in={isNavBarPinned}
                >
                    <IconButton
                        aria-label="expand-collapse-sidebar"
                        color="info"
                        id="expand_collapse_sidebar"
                        onClick={togglePinSideBar}
                    >
                        {isNavBarPinned ? (
                            <StyledKeyboardDoubleArrowLeft/>
                        ) : (
                            <StyledKeyboardDoubleArrowRight/>
                        )}
                    </IconButton>
                </Collapse>
                <SideBarList
                    isnavbaropen={isNavBarOpen?.toString()}
                    component="nav"
                    aria-labelledby="sidebar"
                >
                    {sideNavs?.map((menu) => (
                        <MenuOption
                            label={menu.label}
                            id={`${menu.label}_menu_option`}
                            key={menu.id}
                            redirectionLink={menu.routerLink || ''}
                            isDisabled={menu.canDisable}
                            color="info"
                            icon={
                                <MenuOptionIcon
                                    iconKey={menu.icon}
                                />
                            }
                            options={
                                menu?.children && menu.children?.length > 0 ?
                                menu?.children?.map((subMenu) => ({
                                    label: subMenu.label,
                                    id: `${subMenu.label}_menu_option`,
                                    redirectionLink: subMenu.routerLink || "",
                                    isDisabled: !!subMenu.canDisable,
                                    // icon: <HomeIcon />
                                }))
                                : []
                            }
                        />
                    ))}
                </SideBarList>
            </SideBarContainer>
        </Collapse>
    );
}

export default React.memo(Sidebar);