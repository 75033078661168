import React, { useState } from "react"
import {
    InputAdornment,
    Box,
    Button,
    // DialogActions,
    DialogContent,
    FormControl,
    IconButton,
    TextField,
    Grid
} from "@mui/material"
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useForm, Controller } from 'react-hook-form';

import {
    StyledDialog,
    StyledDialogActions,
    // StyledDialogContentText,
    StyledDialogTitle,
    // StyledInputCaption,
    // StyledInputWrapper
} from "./ChangePasswordDialog.styles"

const ChangePasswordDailog = ({
    open,
    onClose,
    onSubmit,
    id
}) => {
    const [showPassword, setShowPassword] = useState(false);
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showConfirmNewPassword, setShowConfirmNewPassword] = useState(false);

    const {
        control,
        getValues,
        handleSubmit,
    } = useForm({
        defaultValues: {
            oldPassword: '',
            newPassword: '',
            confirmNewPassword: ''
        },
        mode: 'onBlur'
    });


    return (
        <StyledDialog
            disableEscapeKeyDown
            open={open}
            onClose={onClose}
            id={id}
            aria-labelledby="change-password-dialog-title"
            aria-describedby="change-password-dialog-description"
            PaperProps={{
                elevation: 0
            }}
        >
            <form onSubmit={handleSubmit(onSubmit)}>
                <StyledDialogTitle>Change Password</StyledDialogTitle>
                <DialogContent style={{ paddingTop: '20px'}}>
                    <Box>
                        <Grid container gap={2}>
                            <Grid item xs={12}>
                                <Controller
                                    control={control}
                                    name="oldPassword"
                                    render={({
                                        field: { onBlur, onChange, value },
                                        fieldState: { error },
                                    }) => (
                                        <FormControl size="small" fullWidth variant="standard">
                                            <TextField
                                                type={showPassword ? "text" : "password"}
                                                label="Old Password*"
                                                size="small"
                                                name="Old Password"
                                                value={value}
                                                variant="outlined"
                                                onChange={onChange}
                                                onBlur={onBlur}
                                                error={!!error}
                                                helperText={error?.message}
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <IconButton
                                                                aria-label="toggle password visibility"
                                                                onClick={() => setShowPassword(!showPassword)}
                                                                onMouseDown={(event) => { event.preventDefault();}}
                                                                edge="end"
                                                            >
                                                                {showPassword ? (
                                                                    <VisibilityOff />
                                                                ) : (
                                                                    <Visibility />
                                                                )}
                                                            </IconButton>
                                                        </InputAdornment>
                                                    )
                                                }}
                                            />
                                        </FormControl>
                                    )}
                                    rules={{
                                        required: "Please Enter Old Password",
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Controller
                                    control={control}
                                    name="newPassword"
                                    render={({
                                        field: { onBlur, onChange, value },
                                        fieldState: { error },
                                    }) => (
                                        <FormControl size="small" fullWidth variant="standard">
                                            <TextField
                                                type={showNewPassword ? "text" : "password"}
                                                label="New Password*"
                                                size="small"
                                                name="New Password"
                                                value={value}
                                                variant="outlined"
                                                onChange={onChange}
                                                onBlur={onBlur}
                                                error={!!error}
                                                helperText={error?.message}
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <IconButton
                                                                aria-label="toggle new password visibility"
                                                                onClick={() => setShowNewPassword(!showNewPassword)}
                                                                onMouseDown={(event) => { event.preventDefault();}}
                                                                edge="end"
                                                            >
                                                                {showNewPassword ? (
                                                                    <VisibilityOff />
                                                                ) : (
                                                                    <Visibility />
                                                                )}
                                                            </IconButton>
                                                        </InputAdornment>
                                                    )
                                                }}
                                            />
                                        </FormControl>
                                    )}
                                    rules={{
                                        required: "Please Enter New Password",
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Controller
                                    control={control}
                                    name="confirmNewPassword"
                                    render={({
                                        field: { onBlur, onChange, value },
                                        fieldState: { error },
                                    }) => (
                                        <FormControl size="small" fullWidth variant="standard">
                                            <TextField
                                                type={showConfirmNewPassword ? "text" : "password"}
                                                label="Confirm New Password*"
                                                size="small"
                                                name="Confirm New Password"
                                                value={value}
                                                variant="outlined"
                                                onChange={onChange}
                                                onBlur={onBlur}
                                                error={!!error}
                                                helperText={error?.message}
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <IconButton
                                                                aria-label="toggle confirm new password visibility"
                                                                onClick={() => setShowConfirmNewPassword(!showConfirmNewPassword)}
                                                                onMouseDown={(event) => { event.preventDefault();}}
                                                                edge="end"
                                                            >
                                                                {showConfirmNewPassword ? (
                                                                    <VisibilityOff />
                                                                ) : (
                                                                    <Visibility />
                                                                )}
                                                            </IconButton>
                                                        </InputAdornment>
                                                    )
                                                }}
                                            />
                                        </FormControl>
                                    )}
                                    rules={{
                                        required: "Please Enter Confirm New Password",
                                        validate: (value) => {
                                            if(value !== getValues('newPassword')) {
                                                return 'Confirm Password is Not Match with New Password'
                                            } else {
                                                return true;
                                            }
                                        }
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </Box>
                </DialogContent>
                <Box display="flex" justifyContent="center">
                    <StyledDialogActions>
                        <Button
                            key='save'
                            id='save-button'
                            variant="contained"
                            color="primary"
                            type="submit"
                        >
                            Save
                        </Button>
                        <Button
                            key='cancel'
                            id='cancel-button'
                            variant="contained"
                            color="primary"
                            onClick={onClose}
                        >
                            Cancel
                        </Button>
                    </StyledDialogActions>
                </Box>
            </form>
        </StyledDialog>
    );
};

export default ChangePasswordDailog;