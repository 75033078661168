import { DeleteOutlined, Edit, Visibility } from "@mui/icons-material"
import { Box, Button } from "@mui/material"
import React from "react"

import {
  DeleteButton,
  IconButtonWithBackground,
  ImgDisplayBox,
  PreviewActionContainer,
  PreviewChangeButton,
  StyledPreviewImageOverlay,
  StyledPreviewImageOverlayContainer,
  StyledPreviewImg
} from "./FileSelector.style"

const FilePreview = ({
  id = "preview",
  url,
  clearImage,
  showFullScreenPreview = false,
  showChangeImageOption = false,
  overlayColor,
  overlayColorOpacity,
  previewHeight,
  previewWidth = "100%"
}) => {
  if (!url) {
    return null
  }

  return (
    <Box>
      <ImgDisplayBox>
        <StyledPreviewImageOverlayContainer>
          <StyledPreviewImg
            crossOrigin="anonymous"
            src={url || ""}
            alt="Preview"
            previewHeight={previewHeight}
            previewWidth={previewWidth}
          />
          <StyledPreviewImageOverlay
            overlayColor={overlayColor}
            overlayOpacity={overlayColorOpacity}
          />
        </StyledPreviewImageOverlayContainer>
        <DeleteButton>
          <IconButtonWithBackground
            aria-label="Delete"
            color="secondary"
            id={`${id}_delete`}
            onClick={clearImage}
          >
            <DeleteOutlined color="primary" />
          </IconButtonWithBackground>
        </DeleteButton>
        <PreviewActionContainer>
          {showChangeImageOption && (
            <PreviewChangeButton
              id={`${id}_edit_button`}
              variant="text"
              disableElevation
              startIcon={<Edit />}
              onClick={clearImage}
            >
              Change Image
            </PreviewChangeButton>
          )}
          {showFullScreenPreview && (
            <Button
              id={`${id}_preview_button`}
              variant="text"
              disableElevation
              color="primary"
              startIcon={<Visibility />}
              onClick={clearImage}
            >
              Preview Background
            </Button>
          )}
        </PreviewActionContainer>
      </ImgDisplayBox>
    </Box>
  )
}

export default FilePreview
