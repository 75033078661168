import { useState } from "react"

import { useEffectAsync } from "../../utils/hooks/useEffectAsync.hook"
export const useFileContainer = ({ file }) => {
  const [fileCount, setFileCount] = useState(0)

  const countFiles = files => {
    const { length } = files

    setFileCount(length)
  }

  // eslint-disable-next-line require-await
  useEffectAsync(
    async isCanceled => {
      try {
        if (isCanceled()) return
        if (file && file.length) countFiles(file)
      } catch (e) {
        countFiles([])
        return
      }
    },
    [file]
  )

  return {
    fileCount
  }
}
