import {
    ApiClient
} from './web.service';
import {
    APIPATH
} from '../utils/constants/constants';

const apiClient = () => {
    return new ApiClient();
}

export const fetchUserGrid = async (payload) => {
    return apiClient().post(`${APIPATH.USER}/list`, payload);
}

export const createUser = async (payload) => {
    return apiClient().post(APIPATH.USER, payload);
}

export const updateUser = async (id, payload) => {
    return apiClient().put(`${APIPATH.USER}/${id}`, payload);
}

export const fetchByIdUser = async (id) => {
    return apiClient().get(`${APIPATH.USER}/${id}`);
}

export const deleteUser = async (id) => {
    return apiClient().delete(`${APIPATH.USER}/${id}`)
}