import { StyledIcon } from "./ImgIcon.styles"

const ImgIcon = props => {
  const {
    icon,
    id = "image_icon",
    className,
    useCursorPointer = false,
    onClick,
    hide = false
  } = props

  return (
    <StyledIcon
      usecursorpointer={useCursorPointer?.toString() || "false"}
      hide={hide?.toString()}
    >
      <img
        id={id}
        alt="edit"
        src={icon}
        className={className}
        onClick={e => {
          if (onClick) onClick(e)
        }}
      />
    </StyledIcon>
  )
}

export default ImgIcon
