import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { Alert, styled } from "@mui/material";

export const StyledAlert = styled(Alert)`
  min-width: 480px;
  .MuiAlert-action {
    align-items: center;
  }

  .MuiAlert-message {
    white-space: pre;
  }
`;

export const StyledCloseIcon = styled(CloseOutlinedIcon)`
  padding-left: 10px;
  padding-right: 10px;
`;