
import { useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate, useLocation } from "react-router-dom";

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Switch from '@mui/material/Switch';

import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete"

import Button from "../../components/Buttons/Button/Button";
import { StringCell } from '../../components/DataGrid/DataGrid.styles';

import { M_DASH_UNICODE } from '../../utils/constants/constants';
import { AppContext } from '../../core/context/appContextProvider';
import { listPayload } from '../../utils/helpers';

import {
    deleteModule,
    updateModule,
    fetchModuleGrid,
} from '../../services/module.service';

const UseModuleHooks = () => {
    const {
        setLoading,
        accessControls,
        informationAlert,
    } = useContext(AppContext);

    const navigate = useNavigate();
    const location = useLocation();

    const [list, setList] = useState([]);
    const [page, setPage] = useState(0);
    const [count, setCount] = useState(0);
    const [selectedId, setSelectedId] = useState(null);
    const [showDeleteModal, setShowDeleteModal] = useState(false);

    const rightForModule = accessControls(location.pathname);

    const fetchModuleList = async () => {
        try {
            setLoading(true);
            const payload = listPayload(page);
            const { success, message, data } = await fetchModuleGrid(payload);
            if (success) {
                setList(data?.rows);
                setCount(data?.count);
            } else {
                setList([]);
                setCount(0);
                informationAlert(message, "error");
            }
        } catch (err) {
            console.error(err);
            informationAlert(err.message, "error");
        } finally {
            setLoading(false);
        }
    };

    const handleChangeStatus = async (status, id) => {
        try {
            const { success, message } = await updateModule(id, {
                isActive: status
            })
            if (success) {
                informationAlert(message, 'info');
            } else {
                informationAlert(message, 'error');
            }
        } catch (err) {
            console.error(err);
            informationAlert(err.message, "error");
        } finally {
            await fetchModuleList();
        }
    };

    const handleDelete = async () => {
        try {
            setLoading(true);
            const {success, message} = await deleteModule(selectedId);
            if(success) {
                setShowDeleteModal(false);
                informationAlert(message, 'info');
            } else {
                informationAlert(message, 'error');
            }
        } catch (err) {
            console.error(err);
            informationAlert(err.message, "error");
        } finally {
            setLoading(false);
            await fetchModuleList();
        }
    }

    const headerList = useMemo(() => [
        {
            field: "name",
            headerName: "Name",
            hide: false,
            index: 1,
            renderCell: ({ row }) => {
                const name = `${row?.name ?? ''}`;

                return (
                    <StringCell>
                        <Typography>{name || M_DASH_UNICODE}</Typography>
                    </StringCell>
                );
            },
            type: 'string',
            align: 'left',
        },
        {
            field: "path",
            headerName: "Path",
            hide: false,
            index: 2,
            renderCell: ({ row }) => {
                const routerLink = `${row?.routerLink ?? ''}`;

                return (
                    <StringCell>
                        <Typography>{routerLink || M_DASH_UNICODE}</Typography>
                    </StringCell>
                );
            },
            type: 'string',
            align: 'left',
        },
        {
            field: 'isActive',
            headerName: 'Status',
            hide: false,
            index: 3,
            renderCell: ({ row }) => {
                return (
                    <StringCell>
                        {rightForModule.edit ?
                            <Switch
                                checked={row.isActive}
                                onChange={(e) => handleChangeStatus(e.target.checked, row.id)}
                            />
                        : null }
                    </StringCell>
                )
            },
            align: 'left',
        },
        {
            field: 'action',
            headerName: 'Action',
            hide: false,
            index: 4,
            renderCell: ({row}) => {
                return (
                    // <StringCell>
                        <Box>
                            {rightForModule.edit ?
                                <Button
                                    id="btn_add_edit_role"
                                    variant="contained"
                                    icon={<EditIcon />}
                                    color="primary"
                                    fullWidth={false}
                                    size="small"
                                    onClick={() => navigate(`/module/edit/${row.id}`)}
                                />
                            : null}
                            {rightForModule.delete ?
                                <Button
                                    id="btn_add_delete_role"
                                    variant="contained"
                                    icon={<DeleteIcon />}
                                    color="primary"
                                    fullWidth={false}
                                    addSpaceBetweenButtons={true}
                                    size="small"
                                    onClick={() => [setShowDeleteModal(!showDeleteModal), setSelectedId(row?.id)]}
                                />
                            : null}
                        </Box>
                    // </StringCell>
                )
            },
            align: 'left'
        }
        // eslint-disable-next-line
    ], [rightForModule]);

    useEffect(() => {
        fetchModuleList()
        // eslint-disable-next-line
    }, []);

    return {
        list,
        page,
        count,
        headerList,
        rightForModule,
        showDeleteModal,
        setPage,
        handleDelete,
        setShowDeleteModal
    }
};

export default UseModuleHooks;