import {
  Button,
  Checkbox,
  FormControlLabel,
  FormLabel,
  InputAdornment,
  Popover,
  styled
} from "@mui/material";

export const SearchInputAdornment = styled(InputAdornment)(({ theme }) => ({
  paddingLeft: theme.spacing(1.2),
}));

export const ButtonText = styled(Button)(({ theme }) => ({
  fontStyle: "normal",
  fontWeight: "bold",
  textTransform: "initial",
  fontSize: theme.typography.pxToRem(16),
  lineHeight: "19px",
}));

export const HeaderTitle = styled("div")`
  text-align: end;
`;

export const FormControlCheckAll = styled(FormControlLabel)`
  & .MuiTypography-root {
    font-weight: bold;
  }
`;


export const ListFilter = styled("div")(({ theme }) => ({
  display: "flex",
  flexWrap: "wrap",
  flexDirection: "column",
  marginLeft: theme.spacing(0.75),
}));

export const ColumnName = styled("div")(({ theme }) => ({
  display: "flex",
  flexWrap: "nowrap",
  justifyContent: "space-between",
  paddingLeft: theme.spacing(0.75),
  width: "100%",
  alignItems: "center",
}));

export const FilterItem = styled("div")(({ theme }) => ({
  display: "flex",
  flexWrap: "wrap",
  marginLeft: theme.spacing(1.2),
}));

export const FilterAll = styled("div")(({ theme }) => ({
  marginLeft: theme.spacing(0.5),
}));

export const TextInfo = styled("div")(({ theme }) => ({
  marginTop: theme.spacing(2.5),
  marginLeft: theme.spacing(2.5),
  marginRight: theme.spacing(2.5),
}));

export const FundsContent = styled("div")(() => ({
  maxWidth: "250px",
  maxHeight: "200px",
  overflow: "auto",
}));

export const IconColumn = styled("div")(({ theme }) => ({
  marginTop: theme.spacing(0.75),
}));

export const ButtonContentEnd = styled("div")(() => ({
  textAlign: "end",
}));

export const PopoverFilter = styled(Popover)`
  .MuiPopover-paper {
    padding: 1%;
  }
`;

export const FilterCheckBox = styled(Checkbox)`
  padding: 2px;
`;

export const SelectableLabel = styled(FormLabel)`
  cursor: pointer;
`;