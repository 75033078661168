import { useNavigate } from "react-router-dom";
import { Controller } from "react-hook-form";

import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

import Button from "../../../components/Buttons/Button/Button";
import { DIGIT_REGEX } from "../../../utils/constants/constants";

import { HeaderRow } from "../coupon.styles";

import UseAddEditCouponHook from "./addEditCoupon.hooks";

const AddEditCoupon = () => {
    const {
        mode,
        control,
        onSubmit,
        getValues,
        handleSubmit
    } = UseAddEditCouponHook();
    const navigate = useNavigate();
    return (
        <Container maxWidth={false}>
            <Box textAlign={'left'}>
                <HeaderRow>
                    <Typography variant="pageTitle" textTransform={"capitalize"}>{mode} Coupon</Typography>
                    {/* <Button
                        id="btn_back"
                        variant="contained"
                        text="Back"
                        color="primary"
                        onClick={() => navigate('/role')}
                    /> */}
                </HeaderRow>
                <Box>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Grid container>
                            <Grid item xs={12} sm={4}>
                                <Box sx={{ display: 'flex', flexDirection: 'column'}}>
                                    <Controller
                                        control={control}
                                        name="code"
                                        render={({
                                            field: { onBlur, onChange, value },
                                            fieldState: { error },
                                        }) => (
                                            <FormControl size="small" variant="standard">
                                                <TextField
                                                    label="Code*"
                                                    size="small"
                                                    name="code"
                                                    value={value}
                                                    variant="outlined"
                                                    onChange={(e) => onChange(e.target.value.toUpperCase())}
                                                    inputProps={{ style: { textTransform: 'uppercase'}}}
                                                    onBlur={onBlur}
                                                    error={!!error}
                                                    helperText={error?.message}
                                                />
                                            </FormControl>
                                        )}
                                        rules={{
                                            required: "Please Enter Code",
                                        }}
                                    />
                                    <br/>
                                    <Controller
                                        control={control}
                                        name="description"
                                        render={({
                                            field: { onBlur, onChange, value },
                                            fieldState: { error },
                                        }) => (
                                            <FormControl size="small" variant="standard">
                                                <TextField
                                                    label="Description*"
                                                    size="small"
                                                    name="description"
                                                    value={value}
                                                    variant="outlined"
                                                    onChange={onChange}
                                                    onBlur={onBlur}
                                                    error={!!error}
                                                    helperText={error?.message}
                                                    multiline
                                                    rows={3}
                                                />
                                            </FormControl>
                                        )}
                                        rules={{
                                            required: "Please Enter Link",
                                        }}
                                    />
                                    <br/>
                                    <Controller
                                        control={control}
                                        name="type"
                                        render={({
                                            field: { onBlur, onChange, value },
                                            fieldState: { error },
                                        }) => (
                                            <FormControl size="small">
                                                <InputLabel size="small" id="type-select">Select Type</InputLabel>
                                                <Select
                                                    variant="outlined"
                                                    labelId="type-select"
                                                    label="Select Type"
                                                    size="small"
                                                    value={value}
                                                    onChange={onChange}
                                                >
                                                    <MenuItem value={'FLAT'}>Flat</MenuItem>
                                                    <MenuItem value={'PERCENTAGE'}>Percentage</MenuItem>
                                                </Select>
                                            </FormControl>
                                        )}
                                        rules={{
                                            required: "Please Select Type"
                                        }}
                                    />
                                    <br/>
                                    <Controller
                                        control={control}
                                        name="amount"
                                        render={({
                                            field: { onBlur, onChange, value },
                                            fieldState: { error },
                                        }) => (
                                            <FormControl size="small" variant="standard">
                                                <TextField
                                                    label="Amount*"
                                                    size="small"
                                                    name="amount"
                                                    value={value}
                                                    variant="outlined"
                                                    onChange={onChange}
                                                    inputProps={{ style: { textTransform: 'capitalize' } }}
                                                    onBlur={onBlur}
                                                    error={!!error}
                                                    helperText={error?.message}
                                                />
                                            </FormControl>
                                        )}
                                        rules={{
                                            required: "Please Enter Amount",
                                            validate: (value) => {
                                                if(getValues('type') === 'PERCENTAGE') {
                                                    return parseInt(value) > 100 ? 'You can not Enter Above 100' : null;
                                                }
                                            },
                                            pattern: {
                                                value: DIGIT_REGEX,
                                                message: 'Please Enter Valid Amount'
                                            }
                                        }}
                                    />
                                </Box>
                            </Grid>
                        </Grid>
                        <Box sx={{ display: 'flex', gap: '12px', padding: '12px 0px'}}>
                            <Button
                                id="btn_back"
                                variant="contained"
                                type="button"
                                text="Back"
                                color="primary"
                                onClick={() => navigate('/coupon')}
                            />
                            <Button
                                id="btn_back"
                                variant="contained"
                                type="submit"
                                text={mode}
                                color="primary"
                            />
                        </Box>
                    </form>
                </Box>
            </Box>
        </Container>
    )
}

export default AddEditCoupon;