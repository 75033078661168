import { Controller } from "react-hook-form";

import InputAdornment from "@mui/material/InputAdornment";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

import Sidecover from "../../../assets/images/login-sidecover.png";
import SiteLogo from "../../../assets/images/logo.png";

import { useLogin } from './login.hooks';

import './login.css';

const Login = () => {
    const {
        control,
        showPassword,
        isMobileView,
        submit,
        handleSubmit,
        setShowPassword
    } = useLogin();

    return (
        <>
            <Grid container>
                {!isMobileView ?
                    <Grid item xs={12} sm={6}>
                        <img src={Sidecover} alt="background" style={{ width: '100%', height: '100vh' }} />
                    </Grid>
                    : null}
                <Grid item xs={12} sm={isMobileView ? 12 : 6}>
                    <Box>
                        <form onSubmit={handleSubmit(submit)}>
                            <Box>
                                <img src={SiteLogo} alt="logo" />
                            </Box>
                            <Box sx={{
                                paddingLeft: '12rem',
                                paddingRight: '15%',
                                paddingTop: '20%',
                                display: "flex",
                                flexDirection: "column",
                                gap: "18px",
                                justifyContent: "center",
                                alignItems: "center",
                            }}>
                                <Controller
                                    name="email"
                                    control={control}
                                    render={({
                                        field: { onBlur, onChange, value },
                                        fieldState: { error },
                                    }) => (
                                        <FormControl size="small" variant="standard" fullWidth>
                                            <TextField
                                                label="Email"
                                                size="small"
                                                value={value}
                                                onChange={onChange}
                                                onBlur={onBlur}
                                                error={!!error}
                                                helperText={error?.message}
                                            />
                                        </FormControl>
                                    )}
                                    rules={{
                                        required: "Email is required",
                                    }}
                                />
                                <Controller
                                    name="password"
                                    control={control}
                                    render={({
                                        field: { onBlur, onChange, value },
                                        fieldState: { error },
                                    }) => (
                                        <FormControl size="small" variant="standard" fullWidth>
                                            <TextField
                                                type={showPassword ? "text" : "password"}
                                                label="Password"
                                                size="small"
                                                name="password"
                                                value={value}
                                                onChange={onChange}
                                                onBlur={onBlur}
                                                error={!!error}
                                                helperText={error?.message}
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <IconButton
                                                                aria-label="toggle password visibility"
                                                                onClick={() => setShowPassword(!showPassword)}
                                                                onMouseDown={(event) => { event.preventDefault();}}
                                                                edge="end"
                                                            >
                                                                {showPassword ? (
                                                                    <VisibilityOff />
                                                                ) : (
                                                                    <Visibility />
                                                                )}
                                                            </IconButton>
                                                        </InputAdornment>
                                                    ),
                                                }}
                                            />
                                        </FormControl>
                                    )}
                                    rules={{
                                        required: "Password required",
                                    }}
                                />
                            {/* </Box> */}
                            {/* <Box> */}
                                <Button type="submit" variant="contained" color="info" size="large">
                                    Login
                                </Button>
                            </Box>
                        </form>
                    </Box>
                </Grid>
            </Grid>
        </>
    )
}

export default Login;