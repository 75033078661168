import { Checkbox, styled } from "@mui/material";

export const FilterCheckBox = styled(Checkbox)`
  padding: 2px;
`;

export const FilterItem = styled("div")(() => ({
  display: "flex",
  flexWrap: "wrap",
  marginLeft: "10px",
}));

export const ListFilter = styled("div")(() => ({
  display: "flex",
  flexWrap: "wrap",
  flexDirection: "column",
  marginLeft: "5px",
}));

export const FilterAllStatus = styled("div")(() => ({
  marginLeft: "5px",
}));
