import {
    ApiClient
} from './web.service';
import {
    APIPATH
} from '../utils/constants/constants';

const apiClient = () => {
    return new ApiClient();
}

export const fetchRoleDropDown = async () => {
    return apiClient().get(`${APIPATH.ROLE}/get/dropdown`);
}

export const fetchRoleGrid = async (payload) => {
    return apiClient().post(`${APIPATH.ROLE}/list`, payload);
}

export const createRole = async (payload) => {
    return apiClient().post(APIPATH.ROLE, payload);
}

export const updateRole = async (id, payload) => {
    return apiClient().put(`${APIPATH.ROLE}/${id}`, payload);
}

export const fetchByIdRole = async (id) => {
    return apiClient().get(`${APIPATH.ROLE}/${id}`);
}

export const deleteRole = async (id) => {
    return apiClient().delete(`${APIPATH.ROLE}/${id}`)
}