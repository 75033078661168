import { Controller } from "react-hook-form";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Container from "@mui/material/Container";
import CheckBox from "@mui/material/Checkbox";
import InputLabel from "@mui/material/InputLabel";
import Grid from "@mui/material/Grid";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Typography from "@mui/material/Typography";
import Select from "@mui/material/Select";

import { HeaderRow } from "./rights.styles";

import UseRightsHook from "./rights.hooks";

const Rights = () => {
    const {
        control,
        roleList,
        rightList,
        rightForRight,
        onSubmit,
        handleSubmit,
        fetchModuleList,
        handleSelectAll,
        checkAllSelected
    } = UseRightsHook();
    return (
        <Container maxWidth={false} sx={{textAlign: 'left'}}>
            <Box textAlign={'left'}>
                <HeaderRow>
                    <Typography variant="pageTitle" textTransform={"capitalize"}>Rights</Typography>
                </HeaderRow>
            </Box>
            <Box>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Card>
                        <CardContent>
                            <Grid container>
                                <Grid item xs={12} sm={4}>

                                    {/* <Controller
                                        control={control}
                                        name="roleID"
                                        render={({
                                            field: { onBlur, onChange, value },
                                            fieldState: { error },
                                        }) => ( */}
                                            <FormControl size="small" fullWidth>
                                                <InputLabel size="small" id="role-select">Select Role</InputLabel>
                                                <Select
                                                    variant="outlined"
                                                    labelId="role-select"
                                                    label="Select Role"
                                                    size="small"
                                                    onChange={(e) => fetchModuleList(e.target.value)}
                                                    // value={value}
                                                    // onChange={(e) => [onChange(e.target.value), fetchModuleList(e.target.value)]}
                                                    // onBlur={onBlur}
                                                >
                                                    {roleList?.map((item, index) => (
                                                        <MenuItem key={`role_${index}`} value={item?.id}> {item.name}</MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        {/* )}
                                        rules={{
                                            required: "Please Select Role"
                                        }}
                                    /> */}
                                </Grid>
                                <Grid item xs={12} sm={8} sx={{textAlign: 'end'}}>
                                    {rightForRight.add ?
                                        <Button variant="contained" type="submit">
                                            Save
                                        </Button>
                                    : null}
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                    <br/>
                    <Card>
                        <CardContent>
                            <TableContainer>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Module Name</TableCell>
                                            <TableCell>All</TableCell>
                                            <TableCell>View</TableCell>
                                            <TableCell>Add</TableCell>
                                            <TableCell>Edit</TableCell>
                                            <TableCell>Delete</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {rightList?.fields?.map((item, index) => (
                                            <TableRow key={`rights_${index}`}>
                                                <TableCell>
                                                    <Controller
                                                        control={control}
                                                        name={`rightList.${index}.moduleName`}
                                                        render={({ field: {value } }) => (
                                                            <InputLabel variant="standard">{value}</InputLabel>
                                                        )}
                                                    />
                                                </TableCell>
                                                <TableCell>
                                                    <CheckBox checked={checkAllSelected(index)} onChange={(e) => handleSelectAll(index, e.target.checked)}/>
                                                </TableCell>
                                                <TableCell>
                                                    <Controller
                                                        control={control}
                                                        name={`rightList.${index}.view`}
                                                        render={({ field: { value, onChange } }) => (
                                                            <CheckBox checked={value} onChange={onChange}/>
                                                        )}
                                                    />
                                                </TableCell>
                                                <TableCell>
                                                    <Controller
                                                        control={control}
                                                        name={`rightList.${index}.add`}
                                                        render={({ field: { value, onChange } }) => (
                                                            <CheckBox checked={value} onChange={onChange}/>
                                                        )}
                                                    />
                                                </TableCell>
                                                <TableCell>
                                                    <Controller
                                                        control={control}
                                                        name={`rightList.${index}.edit`}
                                                        render={({ field: { value, onChange } }) => (
                                                            <CheckBox checked={value} onChange={onChange}/>
                                                        )}
                                                    />
                                                </TableCell>
                                                <TableCell>
                                                    <Controller
                                                        control={control}
                                                        name={`rightList.${index}.delete`}
                                                        render={({ field: { value, onChange } }) => (
                                                            <CheckBox checked={value} onChange={onChange}/>
                                                        )}
                                                    />
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </CardContent>
                    </Card>
                </form>
            </Box>
        </Container>
    )
}

export default Rights;