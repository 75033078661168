import { Stack, Typography } from "@mui/material"
import React, { useRef } from "react"

import IconMultiPDFile from "../../assets/images/icons/icon_multiple_pdf.svg"
import UploadFile from "../../assets/images/icons/icon_upload.svg"
import { FileContainer } from "../FileContainer/FileContainer"
import {
  FileSelectorButton,
  FileSelectorContainer,
  UploadFileIconImage,
  UploadFileInput
} from "./FileSelector.style"

const FileSelector = ({
  title = "Drop your image here (Max Height 40px), or",
  onUpload,
  onClear = () => {},
  acceptType = "",
  btnText = "Choose File",
  multiple = false,
  inline = false,
  uploadIcon = UploadFile,
  uploadTitleBoxStyle = {},
  height,
  preserveFiles = false,
  progress = 0,
  disabled = false
}) => {
  const buttonRef = useRef(null)
  const [fileList, setFileList] = React.useState([])

  const dragOver = e => {
    handleDragOver(e)
  }

  const onDrop = e => {
    handleDragOver(e)
    if (disabled) return
    const files = e.target.files || e.dataTransfer.files

    if (preserveFiles) {
      const allFiles = Array.from(fileList).concat(Array.from(files))

      setFileList(allFiles)
      onUpload(URL.createObjectURL(allFiles[0]), allFiles)
    } else {
      onUpload(URL.createObjectURL(files[0]), files)
    }
  }

  const handleDragOver = e => {
    e?.stopPropagation()
    e?.preventDefault()
  }

  const onChange = event => {
    handleDragOver(event)
    if (event.target?.files && event.target?.files?.length > 0) {
      const files = event.target?.files

      if (preserveFiles) {
        const allFiles = Array.from(fileList).concat(Array.from(files))

        setFileList(allFiles)
        onUpload(URL.createObjectURL(allFiles[0]), allFiles)
      } else {
        onUpload(URL.createObjectURL(files[0]), files)
      }
    }
  }

  const handleUpload = e => {
    e?.stopPropagation()
    e?.preventDefault()

    buttonRef.current?.click()
  }

  return (
    <>
      <FileSelectorContainer
        onDragOver={dragOver}
        onDragLeave={dragOver}
        onDrop={onDrop}
        sx={{
          height
        }}
      >
        <Stack
          justifyContent="center"
          alignItems="center"
          flexDirection={inline ? "row" : "column"}
          columnGap={2}
          sx={[
            ...(Array.isArray(uploadTitleBoxStyle)
              ? uploadTitleBoxStyle
              : [uploadTitleBoxStyle])
          ]}
        >
          <UploadFileIconImage src={uploadIcon} alt="uploadImage" />
          <Typography>{title}</Typography>
        </Stack>
        <FileSelectorButton
          onClick={handleUpload}
          id="file_upload_button"
          variant="outlined"
          disabled={disabled}
        >
          {btnText}
        </FileSelectorButton>
      </FileSelectorContainer>
      <UploadFileInput
        type="file"
        id={`image_upload_input`}
        ref={buttonRef}
        onChange={onChange}
        accept={acceptType}
        multiple={multiple}
        disabled={disabled}
      />
      {preserveFiles && !!fileList && fileList.length > 0 && (
        <FileContainer
          file={fileList}
          onClear={() => {
            setFileList([])
            onClear()
          }}
          hideClear={disabled}
          icon={IconMultiPDFile}
          showUploadProgress={true}
          progressBarStyle={{
            width: 150,
            ml: 2,
            borderRadius: 20,
            bgcolor: "rgba(0, 122, 255, 0.1)"
          }}
          progressValue={progress}
        />
      )}
    </>
  )
}

export default FileSelector
