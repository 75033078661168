import {
    ApiClient
} from './web.service';
import {
    APIPATH
} from '../utils/constants/constants';

const apiClient = () => {
    return new ApiClient();
}

export const fetchRightGrid = async (payload) => {
    return apiClient().post(`${APIPATH.RIGHT}/list`, payload);
}

export const createBulkRight = async (payload) => {
    return apiClient().post(`${APIPATH.RIGHT}/bulk-create`, payload);
}

export const createRight = async (payload) => {
    return apiClient().post(APIPATH.RIGHT, payload);
}

export const updateRight = async (id, payload) => {
    return apiClient().put(`${APIPATH.RIGHT}/${id}`, payload);
}

export const fetchByIdRight = async (id) => {
    return apiClient().get(`${APIPATH.RIGHT}/${id}`);
}

export const fetchByPayloadRight = async (payload) => {
    return apiClient().post(`${APIPATH.RIGHT}/get`, payload);
}

export const deleteRight = async (id) => {
    return apiClient().delete(`${APIPATH.RIGHT}/${id}`)
}