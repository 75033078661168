import {
  styled,
  TextField,
} from "@mui/material";

export const SearchBarInput = styled(TextField)`
  padding: 10px;
  fieldset {
    border-radius: 50px;
    border: 1px solid #919195;
  }

  input {
    width: 100%;
    padding-right: 10px !important;
    padding-top: 10px !important;
    padding-bottom: 10px !important;
    padding-left: 0px !important;
  }

  & .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border: 1px solid #919195;
  }
`;
