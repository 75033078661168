import { useContext, useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useForm } from 'react-hook-form';

import { AppContext } from '../../../core/context/appContextProvider';

import { fetchRoleDropDown } from '../../../services/role.service';
import { createUser, fetchByIdUser, updateUser } from '../../../services/user.service';

const UseAddEditUserHook = () => {
    const {
        loginUser,
        setLoading,
        informationAlert
    } = useContext(AppContext);

    const navigate = useNavigate();
    const { mode, id } = useParams();

    const [roleList, setRoleList] = useState([]);

    const {
        control,
        setValue,
        handleSubmit
    } = useForm({
        defaultValues: {
            firstName: '',
            lastName: '',
            email: '',
            phoneNumber: '',
            roleID: '',
            password: 'Qwer@1234',
            userType: 'ADMIN'
        },
        mode: 'onBlur'
    });

    const fetchById = async (id) => {
        try {
            if(id) {
                setLoading(true);
                const { success, message, data } = await fetchByIdUser(id);
                if(success) {
                    setValue('firstName', data?.firstName);
                    setValue('lastName', data?.lastName);
                    setValue('email', data?.email);
                    setValue('phoneNumber', data?.phoneNumber);
                    setValue('roleID', data?.roleID);
                } else {
                    informationAlert(message, "error");
                }
            }
        } catch(err) {
            informationAlert(err.message, "error");
        } finally {
            setLoading(false);
        }
    }

    const fetchList = async () => {
        try {
            setLoading(true);
            const { success, message, data} = await fetchRoleDropDown();
            if(success) {
                setRoleList(data);
            } else {
                informationAlert(message, 'error');
            }
        } catch(err) {
            informationAlert(err.message, "error");
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        if(id) {
            fetchById(id);
        }
        fetchList();
        // eslint-disable-next-line
    }, [id]);

    const onSubmit = async (info) => {
        try {
            setLoading(true);
            let payload = {...info, createdBy: loginUser?.id};
            if(id) {
                delete info['password'];
                payload = {...info, updatedBy: loginUser?.id};
            }
            const response = id ? await updateUser(id, payload) : await createUser(payload);
            if(response.success) {
                navigate('/user');
                informationAlert(response.message, "info");
            } else {
                informationAlert(response.message, "error");
            }
        } catch(err) {
            console.error(err);
            informationAlert(err.message, "error");
        } finally {
            setLoading(false);
        }
    }
    return {
        mode,
        control,
        roleList,
        onSubmit,
        setRoleList,
        handleSubmit,
    }
}

export default UseAddEditUserHook;