import { Button, IconButton } from "@mui/material";
import { Box, styled } from "@mui/system";

export const ImgDisplayBox = styled(Box)(({
  theme
}) => ({
  position: "relative",
  display: "inline-block",
}));

export const TabNameBox = styled(Box)(({
  theme
}) => ({
  marginTop: theme.spacing(2, 0),
}));

export const FileSelectorContainer = styled(Box)(({
  theme
}) => ({
  width: "100%",
  marginTop: theme.spacing(2),
  height: theme.spacing(31),
  border: `2px dashed ${theme.palette.divider}`,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",
}));

export const FileSelectorButton = styled(Button)(({
  theme
}) => ({
  marginTop: "10px",
}));

export const DeleteButton = styled("div")(({
  theme
}) => ({
  position: "absolute",
  top: theme.spacing(-2.5),
  cursor: "pointer",
  right: theme.spacing(-2.5),
}));

export const BackGroundPropertyContainer = styled(Box)(({
  theme
}) => ({
  marginTop: theme.spacing(3.75),
}));

export const IconButtonWithBackground = styled(IconButton)(({
  theme
}) => ({
  backgroundColor: theme.palette.common.white,
  "&: hover": {
    backgroundColor: theme.palette.common.white,
  },
  boxShadow: `1px 2px 4px ${theme.palette.common.boxShadow}`,
}));

export const StyledPreviewImageOverlayContainer = styled(Box)(() => ({
  width: "100%",
  position: "relative",
}));

export const StyledPreviewImageOverlay = styled(Box)(({
  overlayColor, overlayOpacity
}) => ({
  position: "absolute",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  backgroundColor: overlayColor,
  opacity: overlayOpacity,
}));

export const StyledPreviewImg = styled("img")(({
  previewWidth,
  previewHeight
}) => ({
  maxWidth: previewWidth,
  maxHeight: previewHeight,
}));

export const UploadFileIconImage = styled("img")(() => ({
  width: "40px",
}));

export const UploadFileInput = styled("input")(() => ({
  display: "none",
}));

export const PreviewActionContainer = styled(Box)(() => ({
  display: "flex",
  justifyContent: "space-between",
}));

export const PreviewChangeButton = styled(Button)(({
  theme
}) => ({
  color: theme.palette.text.secondary,
}));

export const PreviewContainer = styled(Box)(
  ({
    theme,
    previewWidth,
    previewHeight
  }) => ({
    maxWidth: `calc(${previewWidth} + ${theme.spacing(2)})`,
    maxHeight: `calc(${previewHeight} + ${theme.spacing(4)})`,
  })
);

