
import { useCallback, useContext, useEffect, useState } from 'react';
import { useForm, useFieldArray } from 'react-hook-form';
import { useLocation } from "react-router-dom";

import { AppContext } from "../../core/context/appContextProvider";
import {
    fetchRoleDropDown
} from '../../services/role.service';
import {
    fetchModuleDropDown
} from '../../services/module.service';
import {
    createBulkRight,
    fetchByPayloadRight
} from '../../services/right.service';

const UseRightsHook = () => {

    const {
        loginUser,
        setLoading,
        accessControls,
        informationAlert,
    } = useContext(AppContext);

    const [roleList, setRoleList] = useState([]);
    // const [moduleList, setModuleList] = useState([]);

    const location = useLocation();

    const rightForRight = accessControls(location.pathname);

    const {
        control,
        reset,
        watch,
        setValue,
        getValues,
        handleSubmit
    } = useForm({
        defaultValues: {
            rightList: []
        },
        mode: 'onBlur'
    });

    const rightList = useFieldArray({
        control: control,
        name: 'rightList'
    });

    const fetchList = async () => {
        const [roleResponse] = await Promise.all([fetchRoleDropDown()]);
        if(roleResponse.success) {
            setRoleList(roleResponse.data);
        } else {
            informationAlert(roleResponse.message, "error");
        }
    }

    useEffect(() => {
        fetchList();
        // eslint-disable-next-line
    }, []);

    const fetchModuleList = async (roleID) => {
        reset({
            rightList: []
        })
        const { success, data } = await fetchByPayloadRight({roleID: roleID})
        if(success) {
            if(data.length > 0) {
                data?.forEach((res) => {
                    const index = getValues('rightList').length;
                    rightList.append({
                        id: res.id,
                        index: (index + 1),
                        roleID: roleID,
                        moduleID: res.moduleID,
                        moduleName: res.px_module?.name,
                        add: res.add,
                        edit: res.edit,
                        delete: res.delete,
                        view: res.view
                    })
                })
            } else {
                const moduleResponse = await fetchModuleDropDown();
                if(moduleResponse.success) {
                    moduleResponse?.data?.forEach((res) => {
                        const index = getValues('rightList').length;
                        rightList.append({
                            index: (index + 1),
                            roleID: roleID,
                            moduleID: res.id,
                            moduleName: res.name,
                            add: false,
                            edit: false,
                            delete: false,
                            view: false
                        })
                    })
                } else {
                    informationAlert(moduleResponse.message, "error");
                }
            }
        } else {
            const moduleResponse = await fetchModuleDropDown();
            if(moduleResponse.success) {
                moduleResponse?.data?.forEach((res) => {
                    const index = getValues('rightList').length;
                    rightList.append({
                        id: null,
                        index: (index + 1),
                        roleID: roleID,
                        moduleID: res.id,
                        moduleName: res.name,
                        add: false,
                        edit: false,
                        delete: false,
                        view: false
                    })
                })
            } else {
                informationAlert(moduleResponse.message, "error");
            }
        }
    }

    const checkAllSelected = useCallback((index) => {
        return (getValues(`rightList.${index}.add`) && getValues(`rightList.${index}.edit`) && getValues(`rightList.${index}.delete`) && getValues(`rightList.${index}.view`));
    // eslint-disable-next-line
    }, [watch('rightList'), getValues]);

    const handleSelectAll = (index, value) => {
        setValue(`rightList.${index}.add`, value);
        setValue(`rightList.${index}.view`, value);
        setValue(`rightList.${index}.edit`, value);
        setValue(`rightList.${index}.delete`, value);
    }

    const onSubmit = async (info) => {
        try {
            setLoading(true);
            let payload = JSON.parse(JSON.stringify(info.rightList));
            payload = payload?.map((item) => {
                return {
                    id: item.id,
                    roleID: item.roleID,
                    moduleID: item.moduleID,
                    add: item.add,
                    edit: item.edit,
                    view: item.view,
                    delete: item.delete,
                    createdBy: loginUser?.id
                }
            })
            const { success, message } = await createBulkRight(payload);
            if(success) {
                reset({
                    rightList: []
                })
                informationAlert(message, 'info');
            } else {
                informationAlert(message, 'error');
            }
        } catch(err) {
            informationAlert(err.message, 'error');
        } finally {
            setLoading(false);
        }
    }

    return {
        control,
        roleList,
        rightList,
        rightForRight,
        onSubmit,
        handleSubmit,
        fetchModuleList,
        handleSelectAll,
        checkAllSelected,
    }
}

export default UseRightsHook;