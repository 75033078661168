import { useContext, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useForm } from 'react-hook-form';

import { AppContext } from '../../../core/context/appContextProvider';

import { createCoupon, fetchByIdCoupon, updateCoupon } from '../../../services/coupon.service';

const UseAddEditCouponHook = () => {
    const {
        loginUser,
        setLoading,
        informationAlert
    } = useContext(AppContext);

    const navigate = useNavigate();
    const { mode, id } = useParams();

    const {
        control,
        setValue,
        getValues,
        handleSubmit
    } = useForm({
        defaultValues: {
            code: '',
            description: '',
            type: 'FLAT',
            amount: '',
        },
        mode: 'onBlur'
    });

    const fetchById = async (id) => {
        try {
            if(id) {
                setLoading(true);
                const { success, message, data } = await fetchByIdCoupon(id);
                if(success) {
                    setValue('code', data?.code);
                    setValue('description', data?.description);
                    setValue('type', data?.type);
                    setValue('amount', data?.amount);
                } else {
                    informationAlert(message, "error");
                }
            }
        } catch(err) {
            informationAlert(err.message, "error");
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        if(id) {
            fetchById(id);
        }
        // eslint-disable-next-line
    }, [id]);

    const onSubmit = async (info) => {
        const payload = JSON.parse(JSON.stringify(info));
        try {
            setLoading(true);
            const response = id ? await updateCoupon(id, {...payload, updatedBy: loginUser?.id}) : await createCoupon({...payload, createdBy: loginUser?.id});
            if(response.success) {
                navigate('/coupon');
            } else {
                informationAlert(response.message, "error");
            }
        } catch(err) {
            console.error(err);
            informationAlert(err.message, "error");
        } finally {
            setLoading(false);
        }
    }
    return {
        mode,
        control,
        onSubmit,
        getValues,
        handleSubmit
    }
}

export default UseAddEditCouponHook;