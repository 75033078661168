import CssBaseline from '@mui/material/CssBaseline';
import { createTheme,ThemeProvider } from '@mui/material/styles';
import React, { useMemo } from 'react';

// import componentsOverride from './overrides';
import palette from './palette';
import typography from './typography';


const ThemeConfig = ({children}) => {
  const themeOptions = useMemo(
    () => ({
      typography,
      palette,
    }),
    [],
  );

  const theme = createTheme(themeOptions);

  // theme.components = componentsOverride(theme, clientTheme);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {children}
    </ThemeProvider>
  );
};

export default ThemeConfig;
