import { Typography } from "@mui/material"
import List from "@mui/material/List"
import ListItemButton from "@mui/material/ListItemButton"

import ListItem from "../../ListItem/ListItem"
import ListItemIcon from "../../ListItemIcon/ListItemIcon"

const PopoverFilterList = ({
  options,
  handleFilter,
  selectedOption = true,
  filterName
}) => {
  return (
    <List dense={true}>
      {options?.map(option => (
        <ListItem key={option.id}>
          <ListItemButton
            onClick={() => handleFilter(filterName, option.value)}
            divider={option.divider}
            selected={selectedOption === option.value}
          >
            {!!option.icon && (
              <ListItemIcon id={option.id} icon={option.icon} />
            )}
            <Typography variant="subtitle2">{option.label}</Typography>
          </ListItemButton>
        </ListItem>
      ))}
    </List>
  )
}

export default PopoverFilterList
