import { useNavigate } from "react-router-dom";
import { Controller } from "react-hook-form";

import Accordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import TableHead from "@mui/material/TableHead";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Select from "@mui/material/Select";


import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import Button from "../../../components/Buttons/Button/Button";
import FileDisplay from "../../../components/FileDisplay/FileDisplay";
import FilePreview from "../../../components/FileSelector/FilePreview";
import FileSelector from "../../../components/FileSelector/FileSelector";

import { EMAIL_REGEX, PHONE_REGEX, DIGIT_REGEX, IFSC_REGEX, UPI_REGEX, GST_REGEX } from "../../../utils/constants/constants";
import { imagePath, createQrCode } from "../../../utils/helpers";

import UseAddEditClientHook from "./addEditClient.hooks";
import { HeaderRow } from "../client.styles";

const AddEditClient = () => {
    const {
        mode,
        openId,
        isAddMode,
        themeList,
        clientData,
        serviceList,
        serviceForm,
        galleryForm,
        galleryList,
        videoLinkForm,
        onlinePayForm,
        openServiceBox,
        bankDetailForm,
        basicDetailForm,
        socialMediaForm,
        clientDetailForm,
        documentDetailForm,
        videoLinkArrayField,
        onlinePayArrayField,
        bankDetailArrayField,
        socialMediaArrayField,
        officeLocationArrayField,
        documentDetailArrayField,
        basicSubmit,
        handleOpenId,
        serviceSubmit,
        gallerySubmit,
        downloadQrCode,
        videoLinkSubmit,
        onlinePaySubmit,
        handleAddService,
        bankDetailSubmit,
        handleEditService,
        setOpenServiceBox,
        socialMediaSubmit,
        handleAddVideoLink,
        handleAddDocument,
        clientDetailSubmit,
        handleAddBankDetail,
        handleRemoveService,
        handleRemoveDocument,
        documentDetailSubmit,
        handleRemoveVideoLink,
        handleRemoveBankDetail,
        handleAddOfficeLocation,
        handleRemoveSelectedFiles,
        handleRemoveUploadedFiles,
        handleRemoveOfficeLocation,
    } = UseAddEditClientHook();
    const navigate = useNavigate();

    return (
        <Container maxWidth={false}>
            <Box textAlign={'left'}>
                <HeaderRow>
                    <Typography variant="pageTitle" textTransform={"capitalize"}>{mode} Client</Typography>
                    <Button
                        id="btn_back"
                        variant="contained"
                        text="Back"
                        color="primary"
                        onClick={() => navigate('/client')}
                    />
                </HeaderRow>
                {/* Basic Detail */}
                <Accordion id={0} onChange={(e) => handleOpenId(0)} expanded={!isAddMode || openId === 0}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography variant="subtitle1">Basic Detail</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Grid container gap={2}>
                            <Grid item xs={12} sm={2}>
                                <Controller
                                    control={basicDetailForm.control}
                                    name="firstName"
                                    render={({
                                        field: { onBlur, onChange, value },
                                        fieldState: { error },
                                    }) => (
                                        <FormControl size="small" fullWidth variant="standard">
                                            <TextField
                                                label="First Name*"
                                                size="small"
                                                name="firstName"
                                                value={value}
                                                variant="outlined"
                                                onChange={(e) => onChange(e.target.value)}
                                                inputProps={{ style: { textTransform: 'capitalize' } }}
                                                onBlur={onBlur}
                                                error={!!error}
                                                helperText={error?.message}
                                            />
                                        </FormControl>
                                    )}
                                    rules={{
                                        required: "Please Enter First Name",
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={2}>
                                <Controller
                                    control={basicDetailForm.control}
                                    name="lastName"
                                    render={({
                                        field: { onBlur, onChange, value },
                                        fieldState: { error },
                                    }) => (
                                        <FormControl size="small" fullWidth variant="standard">
                                            <TextField
                                                label="Last Name*"
                                                size="small"
                                                name="lastName"
                                                value={value}
                                                variant="outlined"
                                                onChange={(e) => onChange(e.target.value)}
                                                inputProps={{ style: { textTransform: 'capitalize' } }}
                                                onBlur={onBlur}
                                                error={!!error}
                                                helperText={error?.message}
                                            />
                                        </FormControl>
                                    )}
                                    rules={{
                                        required: "Please Enter Last Name",
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <Controller
                                    control={basicDetailForm.control}
                                    name="email"
                                    render={({
                                        field: { onBlur, onChange, value },
                                        fieldState: { error },
                                    }) => (
                                        <FormControl size="small" fullWidth variant="standard">
                                            <TextField
                                                label="Email*"
                                                size="small"
                                                name="email"
                                                value={value}
                                                variant="outlined"
                                                onChange={onChange}
                                                onBlur={onBlur}
                                                error={!!error}
                                                helperText={error?.message}
                                            />
                                        </FormControl>
                                    )}
                                    rules={{
                                        required: "Please Enter Email",
                                        pattern: {
                                            value: EMAIL_REGEX,
                                            message: 'Please enter a valid email',
                                        }
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <Controller
                                    control={basicDetailForm.control}
                                    name="phoneNumber"
                                    render={({
                                        field: { onBlur, onChange, value },
                                        fieldState: { error },
                                    }) => (
                                        <FormControl size="small" fullWidth variant="standard">
                                            <TextField
                                                label="Phone Number*"
                                                size="small"
                                                name="phoneNumber"
                                                value={value}
                                                variant="outlined"
                                                onChange={onChange}
                                                onBlur={onBlur}
                                                error={!!error}
                                                helperText={error?.message}
                                            />
                                        </FormControl>
                                    )}
                                    rules={{
                                        required: "Please Enter Phone Number",
                                        minLength: {
                                            value: 10,
                                            message: 'Please enter minimum 10 digit'
                                        },
                                        maxLength: {
                                            value: 10,
                                            message: 'Please enter maximum 10 digit'
                                        },
                                        pattern: {
                                            value: PHONE_REGEX,
                                            message: 'Please enter valid Phone Number',
                                        }
                                    }}
                                />
                            </Grid>
                            {!isAddMode &&
                                <Grid item xs={12} sm={2}>
                                    {createQrCode(clientData?.px_user?.phoneNumber, imagePath(clientData?.feviconIcon))}
                                    <Button
                                        id="download_qrcode"
                                        variant="contained"
                                        text="Download"
                                        color="primary"
                                        onClick={downloadQrCode}
                                    />
                                </Grid>
                            }
                        </Grid>
                    </AccordionDetails>
                    <AccordionActions>
                        <Button
                            id="btn_save"
                            variant="contained"
                            text="Save"
                            color="primary"
                            onClick={basicDetailForm.handleSubmit(basicSubmit)}
                        />
                    </AccordionActions>
                </Accordion>
                <br/>
                {/* Client Detail */}
                <Accordion expanded={openId === 1} onChange={(e) => handleOpenId(1)} disabled={isAddMode}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2-content"
                        id="panel2-header"
                    >
                        <Typography variant="subtitle1">Client Detail</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Grid container gap={2}>
                            <Grid item xs={12} sm={4}>
                                <Box sx={{ display: 'flex', flexDirection: 'column', gap: '22px' }}>
                                    <Controller
                                        control={clientDetailForm.control}
                                        name="companyName"
                                        render={({
                                            field: { onBlur, onChange, value },
                                            fieldState: { error },
                                        }) => (
                                            <FormControl size="small" fullWidth variant="standard">
                                                <TextField
                                                    label="Company Name*"
                                                    size="small"
                                                    name="companyName"
                                                    value={value}
                                                    variant="outlined"
                                                    onChange={(e) => onChange(e.target.value)}
                                                    inputProps={{ style: { textTransform: 'capitalize' } }}
                                                    onBlur={onBlur}
                                                    error={!!error}
                                                    helperText={error?.message}
                                                />
                                            </FormControl>
                                        )}
                                        rules={{
                                            required: "Please Enter Company Name",
                                        }}
                                    />
                                    <Controller
                                        control={clientDetailForm.control}
                                        name="establishDate"
                                        render={({
                                            field: { onBlur, onChange, value },
                                            fieldState: { error },
                                        }) => (
                                            <FormControl size="small" fullWidth variant="standard">
                                                <TextField
                                                    label="Establish Date*"
                                                    size="small"
                                                    name="establishDate"
                                                    value={value}
                                                    variant="outlined"
                                                    type="date"
                                                    onChange={(e) => onChange(e.target.value)}
                                                    onBlur={onBlur}
                                                    error={!!error}
                                                    helperText={error?.message}
                                                />
                                            </FormControl>
                                        )}
                                        rules={{
                                            required: "Please Enter Establish Date",
                                        }}
                                    />
                                    <Controller
                                        control={clientDetailForm.control}
                                        name="gstNo"
                                        render={({
                                            field: { onBlur, onChange, value },
                                            fieldState: { error },
                                        }) => (
                                            <FormControl size="small" fullWidth variant="standard">
                                                <TextField
                                                    label="GST No"
                                                    size="small"
                                                    name="gstNo"
                                                    value={value || ''}
                                                    variant="outlined"
                                                    onChange={onChange}
                                                    inputProps={{ style: { textTransform: 'uppercase' } }}
                                                    onBlur={onBlur}
                                                    error={!!error}
                                                    helperText={error?.message}
                                                />
                                            </FormControl>
                                        )}
                                        rules={{
                                            pattern: {
                                                value: GST_REGEX,
                                                message: 'Please enter valid GST Number',
                                            }
                                        }}
                                    />
                                    <Controller
                                        control={clientDetailForm.control}
                                        name="natureOfBusiness"
                                        render={({
                                            field: { onBlur, onChange, value },
                                            fieldState: { error },
                                        }) => (
                                            <FormControl size="small" fullWidth variant="standard">
                                                <TextField
                                                    label="Business Type*"
                                                    size="small"
                                                    name="natureOfBusiness"
                                                    value={value}
                                                    variant="outlined"
                                                    onChange={(e) => onChange(e.target.value)}
                                                    inputProps={{ style: { textTransform: 'capitalize' } }}
                                                    onBlur={onBlur}
                                                    error={!!error}
                                                    helperText={error?.message}
                                                />
                                            </FormControl>
                                        )}
                                        rules={{
                                            required: "Please Enter Business Type",
                                        }}
                                    />
                                    <Controller
                                        control={clientDetailForm.control}
                                        name="website"
                                        render={({
                                            field: { onBlur, onChange, value },
                                            fieldState: { error },
                                        }) => (
                                            <FormControl size="small" fullWidth variant="standard">
                                                <TextField
                                                    label="Website*"
                                                    size="small"
                                                    name="website"
                                                    value={value}
                                                    variant="outlined"
                                                    onChange={(e) => onChange(e.target.value)}
                                                    onBlur={onBlur}
                                                    error={!!error}
                                                    helperText={error?.message}
                                                />
                                            </FormControl>
                                        )}
                                        rules={{
                                            required: "Please Enter Website",
                                        }}
                                    />
                                    <Controller
                                        control={clientDetailForm.control}
                                        name="cardThemeID"
                                        render={({
                                            field: { onBlur, onChange, value },
                                            fieldState: { error },
                                        }) => (
                                            <FormControl size="small" fullWidth>
                                                <InputLabel size="small" id="theme-type">Select Theme</InputLabel>
                                                <Select
                                                    variant="outlined"
                                                    labelId="theme-type"
                                                    label="Select Type"
                                                    size="small"
                                                    value={value}
                                                    onChange={onChange}
                                                    onBlur={onBlur}
                                                >
                                                    {themeList?.map((item, index) => (
                                                        <MenuItem key={`theme_${index}`} value={item.id}>{item.name}</MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        )}
                                        rules={{
                                            required: 'Please Select Card Theme'
                                        }}
                                    />
                                    <Controller
                                        control={clientDetailForm.control}
                                        name="whatsappNumber"
                                        render={({
                                            field: { onBlur, onChange, value },
                                            fieldState: { error },
                                        }) => (
                                            <FormControl size="small" fullWidth variant="standard">
                                                <TextField
                                                    label="Whatsapp Number*"
                                                    size="small"
                                                    name="whatsappNumber"
                                                    value={value}
                                                    variant="outlined"
                                                    onChange={onChange}
                                                    onBlur={onBlur}
                                                    error={!!error}
                                                    helperText={error?.message}
                                                />
                                            </FormControl>
                                        )}
                                        rules={{
                                            required: "Please Enter Whatsapp Number",
                                            minLength: {
                                                value: 10,
                                                message: 'Please enter minimum 10 digit'
                                            },
                                            maxLength: {
                                                value: 10,
                                                message: 'Please enter maximum 10 digit'
                                            },
                                            pattern: {
                                                value: PHONE_REGEX,
                                                message: 'Please enter valid Whatsapp Number',
                                            }
                                        }}
                                    />
                                    <Controller
                                        control={clientDetailForm.control}
                                        name="phoneNumber2"
                                        render={({
                                            field: { onBlur, onChange, value },
                                            fieldState: { error },
                                        }) => (
                                            <FormControl size="small" fullWidth variant="standard">
                                                <TextField
                                                    label="Secondary Phone Number"
                                                    size="small"
                                                    name="phoneNumber2"
                                                    value={value}
                                                    variant="outlined"
                                                    onChange={onChange}
                                                    onBlur={onBlur}
                                                    error={!!error}
                                                    helperText={error?.message}
                                                />
                                            </FormControl>
                                        )}
                                        rules={{
                                            minLength: {
                                                value: 10,
                                                message: 'Please enter minimum 10 digit'
                                            },
                                            maxLength: {
                                                value: 10,
                                                message: 'Please enter maximum 10 digit'
                                            },
                                            pattern: {
                                                value: PHONE_REGEX,
                                                message: 'Please enter valid Secondary Phone Number',
                                            }
                                        }}
                                    />
                                    <Controller
                                        control={clientDetailForm.control}
                                        name="email2"
                                        render={({
                                            field: { onBlur, onChange, value },
                                            fieldState: { error },
                                        }) => (
                                            <FormControl size="small" fullWidth variant="standard">
                                                <TextField
                                                    label="Secondary Email"
                                                    size="small"
                                                    name="email2"
                                                    value={value}
                                                    variant="outlined"
                                                    onChange={onChange}
                                                    onBlur={onBlur}
                                                    error={!!error}
                                                    helperText={error?.message}
                                                />
                                            </FormControl>
                                        )}
                                        rules={{
                                            pattern: {
                                                value: EMAIL_REGEX,
                                                message: 'Please enter a valid Secondary Email',
                                            }
                                        }}
                                    />
                                    <Controller
                                        control={clientDetailForm.control}
                                        name="description"
                                        render={({
                                            field: { onBlur, onChange, value },
                                            fieldState: { error },
                                        }) => (
                                            <FormControl size="small" fullWidth variant="standard">
                                                <TextField
                                                    label="Description*"
                                                    size="small"
                                                    name="description"
                                                    value={value}
                                                    variant="outlined"
                                                    onChange={(e) => onChange(e.target.value)}
                                                    inputProps={{ style: { textTransform: 'capitalize' } }}
                                                    onBlur={onBlur}
                                                    error={!!error}
                                                    helperText={error?.message}
                                                    multiline={true}
                                                    rows={4}
                                                />
                                            </FormControl>
                                        )}
                                        rules={{
                                            required: "Please Enter Description",
                                        }}
                                    />
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                {officeLocationArrayField.fields?.map((item, index) => (
                                    <Box key={item.id} id={item.id} sx={{ display: 'grid', gridTemplateColumns: '0.2fr 1fr 0.2fr', paddingBottom: '12px' }}>
                                        {officeLocationArrayField.fields.length === (index + 1) ?
                                            <Button
                                                id="btn_remove_child"
                                                size="small"
                                                variant="contained"
                                                icon={<AddIcon />}
                                                type="button"
                                                text=""
                                                color="primary"
                                                addSpaceBetweenButtons={true}
                                                onClick={handleAddOfficeLocation}
                                            />
                                            :
                                            <Box />
                                        }
                                        <Controller
                                            control={clientDetailForm.control}
                                            name={`officeLocation.${index}.address`}
                                            render={({
                                                field: { onBlur, onChange, value },
                                                fieldState: { error },
                                            }) => (
                                                <FormControl size="small" fullWidth variant="standard">
                                                    <TextField
                                                        label="Office Address"
                                                        size="small"
                                                        name="officeLocation"
                                                        value={value}
                                                        variant="outlined"
                                                        onChange={(e) => onChange(e.target.value)}
                                                        inputProps={{ style: { textTransform: 'capitalize' } }}
                                                        onBlur={onBlur}
                                                        error={!!error}
                                                        helperText={error?.message}
                                                        multiline={true}
                                                        rows={4}
                                                    />
                                                </FormControl>
                                            )}
                                        />
                                        {officeLocationArrayField.fields.length > 1 ?
                                            <Button
                                                id="btn_remove_child"
                                                size="small"
                                                variant="contained"
                                                icon={<DeleteIcon />}
                                                type="button"
                                                text=""
                                                color="primary"
                                                addSpaceBetweenButtons={true}
                                                onClick={() => handleRemoveOfficeLocation(index)}
                                            />
                                            :
                                            <Box />
                                        }
                                    </Box>
                                ))}
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <Controller
                                    control={clientDetailForm.control}
                                    name="feviconIcon"
                                    render={({
                                        field: { onChange, value },
                                        fieldState: { error },
                                    }) => (
                                        <>
                                            <InputLabel>Fevicon Icon</InputLabel>
                                            {!value && (
                                                <>
                                                    <FileSelector
                                                        title="Drag and drop your file here,or"
                                                        acceptType=".png,.jpg,.jpeg"
                                                        onUpload={(_, file) => onChange(file)}
                                                    />
                                                    <FormHelperText error={true}>
                                                        {error?.message}
                                                    </FormHelperText>
                                                </>
                                            )}
                                            {value && (
                                                <FilePreview
                                                    url={typeof value === 'string' ? imagePath(value) : URL.createObjectURL(value[0])}
                                                    clearImage={() => onChange()}
                                                    previewHeight={250}
                                                />
                                            )}
                                        </>
                                    )}
                                    rules={{
                                        required: "Please Select Fevicon Icon",
                                    }}
                                />
                                <Controller
                                    control={clientDetailForm.control}
                                    name="logo"
                                    render={({
                                        field: { onChange, value },
                                        fieldState: { error },
                                    }) => (
                                        <>
                                            <InputLabel>Logo</InputLabel>
                                            {!value && (
                                                <>
                                                    <FileSelector
                                                        title="Drag and drop your file here,or"
                                                        acceptType=".png,.jpg,.jpeg"
                                                        onUpload={(_, file) => onChange(file)}
                                                    />
                                                    <FormHelperText error={true}>
                                                        {error?.message}
                                                    </FormHelperText>
                                                </>
                                            )}
                                            {value && (
                                                <FilePreview
                                                    url={typeof value === 'string' ? imagePath(value) : URL.createObjectURL(value[0])}
                                                    clearImage={() => onChange()}
                                                    previewHeight={250}
                                                />
                                            )}
                                        </>
                                    )}
                                    rules={{
                                        required: "Please Select Logo",
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </AccordionDetails>
                    <AccordionActions>
                        <Button
                            id="btn_save"
                            variant="contained"
                            text="Save"
                            color="primary"
                            type="submit"
                            onClick={clientDetailForm.handleSubmit(clientDetailSubmit)}
                        />
                    </AccordionActions>
                </Accordion>
                <br/>
                {/* Attach Document */}
                <Accordion expanded={openId === 2} onChange={(e) => handleOpenId(2)} disabled={isAddMode}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel3-content"
                        id="panel3-header"
                    >
                        <Typography variant="subtitle1">Attach Document</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Button
                            id="btn_remove_child"
                            size="small"
                            variant="contained"
                            icon={<AddIcon />}
                            type="button"
                            text=""
                            color="primary"
                            addSpaceBetweenButtons={true}
                            onClick={handleAddDocument}
                        />
                        <Grid container gap={2}>
                            {documentDetailArrayField.fields?.map((item, index) => (
                                <Grid item xs={12} sm={5}
                                    key={`document_${index}`}
                                    id={`document_${index}`}
                                >
                                    <Box
                                        sx={{
                                            display: 'grid',
                                            gridTemplateColumns: '1fr 0.2fr',
                                            border: '1px solid #000',
                                            padding: '24px',
                                            borderRadius: '8px',
                                            margin: '10px 0px'
                                        }}
                                    >
                                        <Box>
                                            <Controller
                                                control={documentDetailForm.control}
                                                name={`documentList.${index}.title`}
                                                render={({
                                                    field: { onBlur, onChange, value },
                                                    fieldState: { error },
                                                }) => (
                                                    <FormControl size="small" fullWidth variant="standard">
                                                        <TextField
                                                            label="Title"
                                                            size="small"
                                                            name="title"
                                                            value={value}
                                                            variant="outlined"
                                                            onChange={(e) => onChange(e.target.value)}
                                                            inputProps={{ style: { textTransform: 'capitalize' } }}
                                                            onBlur={onBlur}
                                                            error={!!error}
                                                            helperText={error?.message}
                                                        />
                                                    </FormControl>
                                                )}
                                                rules={{
                                                    required: 'Enter Document Title'
                                                }}
                                            />
                                            <Controller
                                                control={documentDetailForm.control}
                                                name={`documentList.${index}.document`}
                                                render={({
                                                    field: { onChange, value },
                                                    fieldState: { error },
                                                }) => (
                                                    <Box>
                                                        <InputLabel>Attach Document</InputLabel>
                                                        {value.length === 0 && (
                                                            <>
                                                                <FileSelector
                                                                    title="Drag and drop your file here,or"
                                                                    acceptType=".pdf,.xlsx,.docs"
                                                                    onUpload={(_, file) => onChange(file[0])}
                                                                />
                                                                <FormHelperText error={true}>
                                                                    {error?.message}
                                                                </FormHelperText>
                                                            </>
                                                        )}
                                                        {value && (
                                                            <FileDisplay
                                                                text={typeof value === 'string' ? documentDetailForm.getValues(`documentList.${index}.title`) : ''}
                                                                document={typeof value === 'string' ? imagePath(value) : value}
                                                                onClear={() => onChange('')}
                                                            />
                                                        )}
                                                    </Box>
                                                )}
                                                rules={{
                                                    required: "Please Select Document",
                                                }}
                                            />
                                        </Box>
                                        {documentDetailArrayField.fields.length > 1 ?
                                            <Button
                                                id="btn_remove_child"
                                                size="small"
                                                variant="contained"
                                                icon={<DeleteIcon />}
                                                type="button"
                                                text=""
                                                color="primary"
                                                addSpaceBetweenButtons={true}
                                                onClick={() => handleRemoveDocument(index)}
                                            />
                                            :
                                            <Box />
                                        }
                                    </Box>
                                </Grid>
                            ))}
                        </Grid>
                    </AccordionDetails>
                    <AccordionActions>
                        <Button
                            id="btn_save"
                            variant="contained"
                            text="Save"
                            color="primary"
                            type="submit"
                            onClick={documentDetailForm.handleSubmit(documentDetailSubmit)}
                        />
                    </AccordionActions>
                </Accordion>
                <br/>
                {/* Services */}
                <Accordion expanded={openId === 3} onChange={(e) => handleOpenId(3)} disabled={isAddMode}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel4-content"
                        id="panel4-header"
                    >
                        <Typography variant="subtitle1">Services / Products</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Button
                            id="btn_remove_child"
                            size="small"
                            variant="contained"
                            icon={<AddIcon />}
                            type="button"
                            text=""
                            color="primary"
                            addSpaceBetweenButtons={true}
                            onClick={handleAddService}
                        />
                        {openServiceBox ?
                            <Grid container gap={2}>
                                <Grid item xs={12}>
                                    <Box
                                        sx={{
                                            display: 'grid',
                                            gridTemplateColumns: '1fr 1fr',
                                            border: '1px solid #000',
                                            padding: '24px',
                                            borderRadius: '8px',
                                            margin: '10px 0px'
                                        }}
                                    >
                                        <Box sx={{
                                            display: 'grid',
                                            gap: '12px'
                                        }}>
                                            <Controller
                                                control={serviceForm.control}
                                                name={'type'}
                                                render={({
                                                    field: { onBlur, onChange, value },
                                                    fieldState: { error },
                                                }) => (
                                                    <FormControl size="small" fullWidth>
                                                        <InputLabel size="small" id="theme-type">Select Type</InputLabel>
                                                        <Select
                                                            variant="outlined"
                                                            labelId="type"
                                                            label="Select Type"
                                                            size="small"
                                                            value={value}
                                                            onChange={onChange}
                                                            onBlur={onBlur}
                                                        >
                                                            <MenuItem value={'SERVICE'}>Service</MenuItem>
                                                            <MenuItem value={'PRODUCT'}>Product</MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                )}
                                                rules={{
                                                    required: 'Please Select Type'
                                                }}
                                            />
                                            <Controller
                                                control={serviceForm.control}
                                                name={'title'}
                                                render={({
                                                    field: { onBlur, onChange, value },
                                                    fieldState: { error },
                                                }) => (
                                                    <FormControl size="small" fullWidth variant="standard">
                                                        <TextField
                                                            label="Title"
                                                            size="small"
                                                            name="title"
                                                            value={value}
                                                            variant="outlined"
                                                            onChange={(e) => onChange(e.target.value)}
                                                            inputProps={{ style: { textTransform: 'capitalize' } }}
                                                            onBlur={onBlur}
                                                            error={!!error}
                                                            helperText={error?.message}
                                                        />
                                                    </FormControl>
                                                )}
                                                rules={{
                                                    required: 'Enter Document Title'
                                                }}
                                            />
                                            <Controller
                                                control={serviceForm.control}
                                                name={`description`}
                                                render={({
                                                    field: { onBlur, onChange, value },
                                                    fieldState: { error },
                                                }) => (
                                                    <FormControl size="small" fullWidth variant="standard">
                                                        <TextField
                                                            label="Description*"
                                                            size="small"
                                                            name="description"
                                                            value={value}
                                                            variant="outlined"
                                                            onChange={(e) => onChange(e.target.value)}
                                                            inputProps={{ style: { textTransform: 'capitalize' } }}
                                                            onBlur={onBlur}
                                                            error={!!error}
                                                            helperText={error?.message}
                                                            multiline={true}
                                                            rows={4}
                                                        />
                                                    </FormControl>
                                                )}
                                                rules={{
                                                    required: "Please Enter Description",
                                                }}
                                            />
                                            <Controller
                                                control={serviceForm.control}
                                                name={`discountPrice`}
                                                render={({
                                                    field: { onBlur, onChange, value },
                                                    fieldState: { error },
                                                }) => (
                                                    <FormControl size="small" fullWidth variant="standard">
                                                        <TextField
                                                            label="Discount Price"
                                                            size="small"
                                                            name="discountPrice"
                                                            value={value}
                                                            variant="outlined"
                                                            onChange={onChange}
                                                            inputProps={{ style: { textTransform: 'capitalize' } }}
                                                            onBlur={onBlur}
                                                            error={!!error}
                                                            helperText={error?.message}
                                                        />
                                                    </FormControl>
                                                )}
                                                rules={{
                                                    pattern: {
                                                        value: DIGIT_REGEX,
                                                        message: 'Please Enter Valid Discount Price'
                                                    }
                                                }}
                                            />
                                            <Controller
                                                control={serviceForm.control}
                                                name={`price`}
                                                render={({
                                                    field: { onBlur, onChange, value },
                                                    fieldState: { error },
                                                }) => (
                                                    <FormControl size="small" fullWidth variant="standard">
                                                        <TextField
                                                            label="Price*"
                                                            size="small"
                                                            name="price"
                                                            value={value}
                                                            variant="outlined"
                                                            onChange={onChange}
                                                            inputProps={{ style: { textTransform: 'capitalize' } }}
                                                            onBlur={onBlur}
                                                            error={!!error}
                                                            helperText={error?.message}
                                                        />
                                                    </FormControl>
                                                )}
                                                rules={{
                                                    required: "Please Enter Price",
                                                    pattern: {
                                                        value: DIGIT_REGEX,
                                                        message: 'Please Enter Valid Price'
                                                    }
                                                }}
                                            />
                                        </Box>
                                        <Box sx={{padding: '0px 24px'}}>
                                            <Controller
                                                control={serviceForm.control}
                                                name={`image`}
                                                render={({
                                                    field: { onChange, value },
                                                    fieldState: { error },
                                                }) => (
                                                    <Box>
                                                        <InputLabel>Attach Document</InputLabel>
                                                        {!value && (
                                                            <>
                                                                <FileSelector
                                                                    title="Drag and drop your file here,or"
                                                                    acceptType=".png,.jpg,.jpeg"
                                                                    onUpload={(_, file) => onChange(file[0])}
                                                                />
                                                                <FormHelperText error={true}>
                                                                    {error?.message}
                                                                </FormHelperText>
                                                            </>
                                                        )}
                                                        {value && (
                                                            <FilePreview
                                                                url={typeof value === 'string' ? imagePath(value) : URL.createObjectURL(value)}
                                                                clearImage={() => onChange(null)}
                                                                previewHeight={250}
                                                            />
                                                        )}
                                                    </Box>
                                                )}
                                                rules={{
                                                    required: 'Please Select Image'
                                                }}
                                            />
                                        </Box>
                                    </Box>
                                </Grid>
                                <Grid item xs={12}>
                                    <Box sx={{ textAlign: 'end'}}>
                                        <Button
                                            id="btn_save"
                                            variant="contained"
                                            text="Back"
                                            color="primary"
                                            onClick={() => setOpenServiceBox(false)}
                                        />
                                        &nbsp;
                                        <Button
                                            id="btn_save"
                                            variant="contained"
                                            text={serviceForm.getValues('id') ? "Update" : "Save"}
                                            color="primary"
                                            type="submit"
                                            onClick={serviceForm.handleSubmit(serviceSubmit)}
                                        />
                                    </Box>
                                </Grid>
                            </Grid>
                        :
                            <Box>
                                <TableContainer>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Sr</TableCell>
                                                <TableCell>Image</TableCell>
                                                <TableCell>Title</TableCell>
                                                <TableCell>Description</TableCell>
                                                <TableCell>Discount Price</TableCell>
                                                <TableCell>Price</TableCell>
                                                <TableCell>Action</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {serviceList?.map((item, index) => (
                                                <TableRow key={`service_${index}`}>
                                                    <TableCell>{(index + 1)}</TableCell>
                                                    <TableCell>
                                                        <img height={'100px'} crossOrigin="anonymous" src={imagePath(item.image)} alt={item.title}/>
                                                    </TableCell>
                                                    <TableCell>{item.title}</TableCell>
                                                    <TableCell>{item.description}</TableCell>
                                                    <TableCell>{item.discountPrice}</TableCell>
                                                    <TableCell>{item.price}</TableCell>
                                                    <TableCell>
                                                        <Box sx={{display: 'flex'}}>
                                                            <Button
                                                                id="btn_add_edit_role"
                                                                variant="contained"
                                                                icon={<EditIcon />}
                                                                color="primary"
                                                                fullWidth={false}
                                                                size="small"
                                                                onClick={() => handleEditService(item)}

                                                            />
                                                            <Button
                                                                id="btn_add_delete_role"
                                                                variant="contained"
                                                                icon={<DeleteIcon />}
                                                                color="primary"
                                                                fullWidth={false}
                                                                addSpaceBetweenButtons={true}
                                                                size="small"
                                                                onClick={() => handleRemoveService(item.id)}
                                                            />
                                                        </Box>
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Box>
                        }
                    </AccordionDetails>
                    {/* <AccordionActions>

                    </AccordionActions> */}
                </Accordion>
                <br/>
                {/* Gallery */}
                <Accordion expanded={openId === 4} onChange={(e) => handleOpenId(4)} disabled={isAddMode}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel5-content"
                        id="panel5-header"
                    >
                        <Typography variant="subtitle1">Gallery</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Grid container gap={2}>
                            <Grid item xs={12} sm={8}>
                                <Box>
                                    <Controller
                                        control={galleryForm.control}
                                        name={'images'}
                                        render={({
                                            field: { onChange, value },
                                            fieldState: { error },
                                        }) => (
                                            <Box>
                                                <InputLabel>Attach Images</InputLabel>
                                                <FileSelector
                                                    title="Drag and drop your images here,or"
                                                    acceptType=".png,.jpg,.jpeg"
                                                    onUpload={(_, file) => {
                                                        onChange([...value, ...Array.from(file)])
                                                    }}
                                                    multiple={true}
                                                />
                                                <FormHelperText error={true}>
                                                    {error?.message}
                                                </FormHelperText>
                                                <Box sx={{ display: 'flex', gap: '18px'}}>
                                                    {value && value?.map((file, index) => (
                                                        <FilePreview
                                                            id={`preview_${index}`}
                                                            key={`preview_${index}`}
                                                            url={URL.createObjectURL(file)}
                                                            clearImage={() => handleRemoveSelectedFiles(index)}
                                                            previewHeight={225}
                                                        />
                                                    ))}
                                                </Box>
                                            </Box>
                                        )}
                                        rules={{
                                            required: "Please Select Images",
                                        }}
                                    />
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <InputLabel>Uploaded Images</InputLabel>
                                <br/>
                                <Box sx={{
                                    display: 'grid',
                                    gridTemplateColumns: '1fr 1fr',
                                    gap: '24px'
                                }}>
                                    {galleryList?.map((file, index) => (
                                        <FilePreview
                                            id={`uploaded_preview_${index}`}
                                            key={`uploaded_preview_${index}`}
                                            url={imagePath(file.image)}
                                            clearImage={() => handleRemoveUploadedFiles(file.id)}
                                            previewHeight={225}
                                        />
                                    ))}
                                </Box>
                            </Grid>
                            {/* {galleryArrayField.fields?.map((item, index) => (
                                <Grid item xs={12} sm={5}
                                    key={`gallery_${index}`}
                                    id={`gallery_${index}`}
                                >
                                    <Box
                                        sx={{
                                            display: 'grid',
                                            gridTemplateColumns: '1fr 0.2fr',
                                            border: '1px solid #000',
                                            padding: '24px',
                                            borderRadius: '8px',
                                            margin: '10px 0px',
                                        }}
                                    >
                                        <Controller
                                            control={galleryForm.control}
                                            name={`galleryList.${index}.image`}
                                            render={({
                                                field: { onChange, value },
                                                fieldState: { error },
                                            }) => (
                                                <Box>
                                                    <InputLabel>Attach Images</InputLabel>
                                                    {!value && (
                                                        <>
                                                            <FileSelector
                                                                title="Drag and drop your image here,or"
                                                                acceptType=".png,.jpg,.jpeg"
                                                                onUpload={(_, file) => onChange(file[0])}
                                                                multiple={true}
                                                            />
                                                            <FormHelperText error={true}>
                                                                {error?.message}
                                                            </FormHelperText>
                                                        </>
                                                    )}
                                                    {value && (
                                                        <FilePreview
                                                            url={typeof value === 'string' ? imagePath(value) : URL.createObjectURL(value)}
                                                            // url={URL.createObjectURL(value)}
                                                            clearImage={() => onChange()}
                                                            previewHeight={225}
                                                        />
                                                    )}
                                                </Box>
                                            )}
                                            rules={{
                                                required: "Please Select Images",
                                            }}
                                        />
                                        <Button
                                            id="btn_remove_child"
                                            size="small"
                                            variant="contained"
                                            icon={<DeleteIcon />}
                                            type="button"
                                            text=""
                                            color="primary"
                                            addSpaceBetweenButtons={true}
                                            onClick={() => handleRemoveGallery(index)}
                                        />
                                    </Box>
                                </Grid>
                            ))} */}
                        </Grid>
                    </AccordionDetails>
                    <AccordionActions>
                        <Button
                            id="btn_save"
                            variant="contained"
                            text="Save"
                            color="primary"
                            type="submit"
                            onClick={galleryForm.handleSubmit(gallerySubmit)}
                        />
                    </AccordionActions>
                </Accordion>
                <br/>
                {/* Video Link */}
                <Accordion expanded={openId === 5} onChange={(e) => handleOpenId(5)} disabled={isAddMode}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel6-content"
                        id="panel6-header"
                    >
                        <Typography variant="subtitle1">Video Link</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Button
                            id="btn_remove_child"
                            size="small"
                            variant="contained"
                            icon={<AddIcon />}
                            type="button"
                            text=""
                            color="primary"
                            addSpaceBetweenButtons={true}
                            onClick={handleAddVideoLink}
                        />
                        <Grid container gap={2}>
                            {videoLinkArrayField.fields?.map((item, index) => (
                                <Grid item xs={12} key={`video_${index}`} id={`video_${index}`}>
                                    <Box
                                        sx={{
                                            display: 'grid',
                                            gridTemplateColumns: '1fr 0.1fr',
                                            border: '1px solid #000',
                                            padding: '24px',
                                            borderRadius: '8px',
                                            margin: '10px 0px'
                                        }}
                                    >
                                        <Controller
                                            control={videoLinkForm.control}
                                            name={`videoLinkList.${index}.link`}
                                            render={({
                                                field: { onBlur, onChange, value },
                                                fieldState: { error },
                                            }) => (
                                                <FormControl size="small" fullWidth variant="standard">
                                                    <TextField
                                                        label="Video URL"
                                                        size="small"
                                                        name="videourl"
                                                        value={value}
                                                        variant="outlined"
                                                        onChange={onChange}
                                                        onBlur={onBlur}
                                                        error={!!error}
                                                        helperText={error?.message}
                                                    />
                                                </FormControl>
                                            )}
                                            rules={{
                                                required: 'Enter Video Link'
                                            }}
                                        />
                                        <Button
                                            id="btn_remove_child"
                                            size="small"
                                            variant="contained"
                                            icon={<DeleteIcon />}
                                            type="button"
                                            text=""
                                            color="primary"
                                            addSpaceBetweenButtons={true}
                                            onClick={() => handleRemoveVideoLink(index)}
                                        />
                                    </Box>
                                </Grid>
                            ))}
                        </Grid>
                    </AccordionDetails>
                    <AccordionActions>
                        <Button
                            id="btn_save"
                            variant="contained"
                            text="Save"
                            color="primary"
                            type="submit"
                            onClick={videoLinkForm.handleSubmit(videoLinkSubmit)}
                        />
                    </AccordionActions>
                </Accordion>
                <br/>
                {/* Bank Detail */}
                <Accordion expanded={openId === 6} onChange={(e) => handleOpenId(6)} disabled={isAddMode}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel7-content"
                        id="panel7-header"
                    >
                        <Typography variant="subtitle1">Bank Detail</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Button
                            id="btn_remove_child"
                            size="small"
                            variant="contained"
                            icon={<AddIcon />}
                            type="button"
                            text=""
                            color="primary"
                            addSpaceBetweenButtons={true}
                            onClick={handleAddBankDetail}
                        />
                        <Grid container gap={2}>
                            {bankDetailArrayField.fields?.map((item, index) => (
                                <Grid item xs={12} sm={5}
                                    key={`bank_detail_${index}`}
                                    id={`bank_detail_${index}`}
                                >
                                    <Box
                                        sx={{
                                            display: 'grid',
                                            gridTemplateColumns: '1fr 0.2fr',
                                            border: '1px solid #000',
                                            padding: '24px',
                                            borderRadius: '8px',
                                            margin: '10px 0px',
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                display: 'grid',
                                                gap: '12px'
                                            }}
                                        >
                                            <Controller
                                                control={bankDetailForm.control}
                                                name={`bankDetailList.${index}.bankName`}
                                                render={({
                                                    field: { onBlur, onChange, value },
                                                    fieldState: { error },
                                                }) => (
                                                    <FormControl size="small" fullWidth variant="standard">
                                                        <TextField
                                                            label="Bank Name"
                                                            size="small"
                                                            name="title"
                                                            value={value}
                                                            variant="outlined"
                                                            onChange={(e) => onChange(e.target.value)}
                                                            inputProps={{ style: { textTransform: 'capitalize' } }}
                                                            onBlur={onBlur}
                                                            error={!!error}
                                                            helperText={error?.message}
                                                        />
                                                    </FormControl>
                                                )}
                                                rules={{
                                                    required: 'Enter Bank Name'
                                                }}
                                            />
                                            <Controller
                                                control={bankDetailForm.control}
                                                name={`bankDetailList.${index}.accountNumber`}
                                                render={({
                                                    field: { onBlur, onChange, value },
                                                    fieldState: { error },
                                                }) => (
                                                    <FormControl size="small" fullWidth variant="standard">
                                                        <TextField
                                                            label="Account Number"
                                                            size="small"
                                                            name="accountNumber"
                                                            value={value}
                                                            variant="outlined"
                                                            onChange={(e) => onChange(e.target.value)}
                                                            onBlur={onBlur}
                                                            error={!!error}
                                                            helperText={error?.message}
                                                        />
                                                    </FormControl>
                                                )}
                                                rules={{
                                                    required: 'Enter Account Number'
                                                }}
                                            />
                                            <Controller
                                                control={bankDetailForm.control}
                                                name={`bankDetailList.${index}.accountHolderName`}
                                                render={({
                                                    field: { onBlur, onChange, value },
                                                    fieldState: { error },
                                                }) => (
                                                    <FormControl size="small" fullWidth variant="standard">
                                                        <TextField
                                                            label="Account Holder Name"
                                                            size="small"
                                                            name="accountHolderName"
                                                            value={value}
                                                            variant="outlined"
                                                            onChange={(e) => onChange(e.target.value)}
                                                            onBlur={onBlur}
                                                            inputProps={{ style: { textTransform: 'capitalize' } }}
                                                            error={!!error}
                                                            helperText={error?.message}
                                                        />
                                                    </FormControl>
                                                )}
                                                rules={{
                                                    required: 'Enter Account Holder Name'
                                                }}
                                            />
                                            <Controller
                                                control={bankDetailForm.control}
                                                name={`bankDetailList.${index}.accountType`}
                                                render={({
                                                    field: { onBlur, onChange, value },
                                                    fieldState: { error },
                                                }) => (
                                                    <FormControl size="small" fullWidth variant="standard">
                                                        <TextField
                                                            label="Account Type"
                                                            size="small"
                                                            name="accountType"
                                                            value={value}
                                                            variant="outlined"
                                                            onChange={(e) => onChange(e.target.value)}
                                                            onBlur={onBlur}
                                                            inputProps={{ style: { textTransform: 'capitalize' } }}
                                                            error={!!error}
                                                            helperText={error?.message}
                                                        />
                                                    </FormControl>
                                                )}
                                                rules={{
                                                    required: 'Enter Account Type'
                                                }}
                                            />
                                            <Controller
                                                control={bankDetailForm.control}
                                                name={`bankDetailList.${index}.ifscCode`}
                                                render={({
                                                    field: { onBlur, onChange, value },
                                                    fieldState: { error },
                                                }) => (
                                                    <FormControl size="small" fullWidth variant="standard">
                                                        <TextField
                                                            label="IFSC Code"
                                                            size="small"
                                                            name="ifscCode"
                                                            value={value}
                                                            variant="outlined"
                                                            onChange={(e) => onChange(e.target.value)}
                                                            onBlur={onBlur}
                                                            inputProps={{ style: { textTransform: 'uppercase' } }}
                                                            error={!!error}
                                                            helperText={error?.message}
                                                        />
                                                    </FormControl>
                                                )}
                                                rules={{
                                                    required: 'Enter IFSC Code',
                                                    pattern: {
                                                        value: IFSC_REGEX,
                                                        message: 'Enter Valid IFSC Code'
                                                    }
                                                }}
                                            />
                                        </Box>
                                        <Button
                                            id="btn_remove_child"
                                            size="small"
                                            variant="contained"
                                            icon={<DeleteIcon />}
                                            type="button"
                                            text=""
                                            color="primary"
                                            addSpaceBetweenButtons={true}
                                            onClick={() => handleRemoveBankDetail(index)}
                                        />
                                    </Box>
                                </Grid>
                            ))}
                        </Grid>
                    </AccordionDetails>
                    <AccordionActions>
                        <Button
                            id="btn_save"
                            variant="contained"
                            text="Save"
                            color="primary"
                            type="submit"
                            onClick={bankDetailForm.handleSubmit(bankDetailSubmit)}
                        />
                    </AccordionActions>
                </Accordion>
                <br/>
                {/* Online Payment Detail */}
                <Accordion expanded={openId === 7} onChange={(e) => handleOpenId(7)} disabled={isAddMode}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel8-content"
                        id="panel8-header"
                    >
                        <Typography variant="subtitle1">Online Payment Detail</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Grid container>
                            {onlinePayArrayField.fields?.map((item, index) => (
                                <Grid item xs={12} sm={6}
                                    key={`online_pay_${index}`}
                                    id={`online_pay_${index}`}
                                >
                                    <Box
                                        sx={{
                                            display: 'grid',
                                            gridTemplateColumns: '1fr 1fr',
                                            gap: '18px',
                                            border: '1px solid #000',
                                            padding: '24px',
                                            borderRadius: '8px',
                                            margin: '10px 0px'
                                        }}
                                    >
                                        <Box sx={{ display: 'grid'}}>
                                            <Controller
                                                control={onlinePayForm.control}
                                                name={`onlinePayList.${index}.appName`}
                                                render={({
                                                    field: { onBlur, onChange, value },
                                                    fieldState: { error },
                                                }) => (
                                                    <FormControl size="small" fullWidth variant="standard">
                                                        <TextField
                                                            label="Title"
                                                            size="small"
                                                            name="title"
                                                            value={value}
                                                            variant="outlined"
                                                            inputProps={{ style: { textTransform: 'capitalize' } }}
                                                            error={!!error}
                                                            helperText={error?.message}
                                                            disabled
                                                        />
                                                    </FormControl>
                                                )}
                                                rules={{
                                                    required: 'Please Enter Payment App Name'
                                                }}
                                            />
                                            <Controller
                                                control={onlinePayForm.control}
                                                name={`onlinePayList.${index}.number`}
                                                render={({
                                                    field: { onBlur, onChange, value },
                                                    fieldState: { error },
                                                }) => (
                                                    <FormControl size="small" fullWidth variant="standard">
                                                        <TextField
                                                            label="Number"
                                                            size="small"
                                                            name="number"
                                                            value={value}
                                                            variant="outlined"
                                                            onChange={(e) => onChange(e.target.value)}
                                                            onBlur={onBlur}
                                                            error={!!error}
                                                            helperText={error?.message}
                                                        />
                                                    </FormControl>
                                                )}
                                                rules={{
                                                    // required: 'Please Enter Register Number in Payment App',
                                                    minLength: {
                                                        value: 10,
                                                        message: 'Please enter minimum 10 digit'
                                                    },
                                                    maxLength: {
                                                        value: 10,
                                                        message: 'Please enter maximum 10 digit'
                                                    },
                                                    pattern: {
                                                        value: PHONE_REGEX,
                                                        message: 'Please enter valid Number',
                                                    }
                                                }}
                                            />
                                            <Controller
                                                control={onlinePayForm.control}
                                                name={`onlinePayList.${index}.upi`}
                                                render={({
                                                    field: { onBlur, onChange, value },
                                                    fieldState: { error },
                                                }) => (
                                                    <FormControl size="small" fullWidth variant="standard">
                                                        <TextField
                                                            label="UPI"
                                                            size="small"
                                                            name="upi"
                                                            value={value}
                                                            variant="outlined"
                                                            onChange={(e) => onChange(e.target.value)}
                                                            onBlur={onBlur}
                                                            error={!!error}
                                                            helperText={error?.message}
                                                        />
                                                    </FormControl>
                                                )}
                                                rules={{
                                                    // required: 'Please Enter Register Number in Payment App',
                                                    pattern: {
                                                        value: UPI_REGEX,
                                                        message: 'Please enter valid UPI',
                                                    }
                                                }}
                                            />
                                        </Box>
                                        <Box>
                                            <Controller
                                                control={onlinePayForm.control}
                                                name={`onlinePayList.${index}.qrCode`}
                                                render={({
                                                    field: { onChange, value },
                                                    fieldState: { error },
                                                }) => (
                                                    <Box>
                                                        <InputLabel>Attach QR Code</InputLabel>
                                                        {!value && (
                                                            <>
                                                                <FileSelector
                                                                    title="Drag and drop your file here,or"
                                                                    acceptType=".png,.jpg,.jpeg"
                                                                    onUpload={(_, file) => onChange(file[0])}
                                                                />
                                                                <FormHelperText error={true}>
                                                                    {error?.message}
                                                                </FormHelperText>
                                                            </>
                                                        )}
                                                        {value && (
                                                            <FilePreview
                                                                url={typeof value === 'string' ? imagePath(value) : URL.createObjectURL(value)}
                                                                clearImage={() => onChange(null)}
                                                                previewHeight={250}
                                                            />
                                                        )}
                                                    </Box>
                                                )}
                                                // rules={{
                                                //     required: 'Please Put QR Code'
                                                // }}
                                            />
                                        </Box>
                                    </Box>
                                </Grid>
                            ))}
                        </Grid>
                    </AccordionDetails>
                    <AccordionActions>
                        <Button
                            id="btn_save"
                            variant="contained"
                            text="Save"
                            color="primary"
                            type="submit"
                            onClick={onlinePayForm.handleSubmit(onlinePaySubmit)}
                        />
                    </AccordionActions>
                </Accordion>
                <br/>
                {/* Social Media */}
                <Accordion expanded={openId === 8} onChange={(e) => handleOpenId(8)} disabled={isAddMode}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel9-content"
                        id="panel9-header"
                    >
                        <Typography variant="subtitle1">Social Media</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Grid container>
                            {socialMediaArrayField.fields?.map((item, index) => (
                                <Grid item xs={12} sm={6} key={`social_media_${index}`} id={`social_media_${index}`} >
                                    <Box
                                        sx={{
                                            display: 'grid',
                                            gridTemplateColumns: '0.3fr 1fr',
                                            border: '1px solid #000',
                                            padding: '24px',
                                            borderRadius: '8px',
                                            margin: '10px 0px',
                                            gap: '12px'
                                        }}
                                    >
                                        <Controller
                                            control={socialMediaForm.control}
                                            name={`socialMediaList.${index}.name`}
                                            render={({
                                                field: { onBlur, onChange, value },
                                                fieldState: { error },
                                            }) => (
                                                <FormControl size="small" fullWidth variant="standard">
                                                    <TextField
                                                        label="Name"
                                                        size="small"
                                                        name="name"
                                                        value={value}
                                                        variant="outlined"
                                                        onChange={onChange}
                                                        inputProps={{ style: { textTransform: 'capitalize' } }}
                                                        onBlur={onBlur}
                                                        error={!!error}
                                                        helperText={error?.message}
                                                        disabled
                                                    />
                                                </FormControl>
                                            )}
                                        />
                                        <Controller
                                            control={socialMediaForm.control}
                                            name={`socialMediaList.${index}.link`}
                                            render={({
                                                field: { onBlur, onChange, value },
                                                fieldState: { error },
                                            }) => (
                                                <FormControl size="small" fullWidth variant="standard">
                                                    <TextField
                                                        label="Link"
                                                        size="small"
                                                        name="link"
                                                        value={value}
                                                        variant="outlined"
                                                        onChange={onChange}
                                                        onBlur={onBlur}
                                                        error={!!error}
                                                        helperText={error?.message}
                                                    />
                                                </FormControl>
                                            )}
                                        />
                                    </Box>
                                </Grid>
                            ))}
                        </Grid>
                    </AccordionDetails>
                    <AccordionActions>
                        <Button
                            id="btn_save"
                            variant="contained"
                            text="Save"
                            color="primary"
                            type="submit"
                            onClick={socialMediaForm.handleSubmit(socialMediaSubmit)}
                        />
                    </AccordionActions>
                </Accordion>
            </Box>
        </Container>
    )
}

export default AddEditClient;