import { Fade } from "@mui/material"
import { StyledPopover } from "./ArrowPopover.styles"

const ArrowPopover = props => {
  const {
    id,
    anchorEl,
    showPopover,
    handleOnPopoverClose,
    content,
    verticalOrigin = "bottom",
    transformOrigin = "top",
    orientation = "right",
    size = "sm",
    arrowLeft = "right",
    anchorPosition,
    anchorReference
  } = props

  return (
    <StyledPopover
      id={id}
      anchorOrigin={{
        vertical: verticalOrigin,
        horizontal: orientation
      }}
      transformOrigin={{
        vertical: transformOrigin,
        horizontal: orientation
      }}
      disableAutoFocus={true}
      disableEnforceFocus={true}
      anchorEl={anchorEl}
      open={showPopover}
      TransitionComponent={Fade}
      TransitionProps={{
        timeout: 0
      }}
      onClose={handleOnPopoverClose}
      displaysfromtop={(
        verticalOrigin === "top" && transformOrigin === "top"
      ).toString()}
      size={size}
      inverted={(transformOrigin === "bottom").toString()}
      arrowleft={arrowLeft?.toString()}
      anchorPosition={anchorPosition}
      anchorReference={anchorReference}
    >
      {content}
    </StyledPopover>
  )
}

export default ArrowPopover
