import { IconButton, ListItem, Typography } from "@mui/material"
import React from "react"

import IconAddHeader from "../../../assets/images/icons/icon_add_header.svg"
import IconConfig from "../../../assets/images/icons/icon_config.svg"
import IconRemoveHeader from "../../../assets/images/icons/icon_remove_header.svg"
import { CustomType } from "../../../utils/constants/constants";
import ArrowPopover from "../../ArrowPopover/ArrowPopover"
import ImgIcon from "../../ImgIcon/ImgIcon"
import { OptionsBox, StyledList } from "./HeaderSelectionPopover.styles"

const HeaderSelectionPopover = ({
  id,
  headerFields,
  handleUpdateHeader,
  icon = IconConfig
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null)

  const handleOnClick = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleOnClose = () => {
    setAnchorEl(null)
  }

  return (
    <div>
      <OptionsBox>
        <IconButton
          aria-label="Column Settings"
          color="inherit"
          id={`btn_data_grid_columns_filter_${id}`}
          onClick={handleOnClick}
        >
          <ImgIcon icon={icon} useCursorPointer />
        </IconButton>
      </OptionsBox>
      <ArrowPopover
        id={id}
        anchorEl={anchorEl}
        showPopover={Boolean(anchorEl)}
        handleOnPopoverClose={handleOnClose}
        verticalOrigin="top"
        content={
          <StyledList dense={true}>
            {headerFields?.reduce((prevValue, currentValue) => {
              if (currentValue?.customType !== CustomType.Action) {
                prevValue.push(
                  <ListItem
                    id={`checkbox-list-label-${currentValue.field}`}
                    key={currentValue.field}
                    secondaryAction={
                      <IconButton
                        id={`${id}_${currentValue.field}`}
                        edge="end"
                        aria-label="delete"
                        onClick={() =>
                          handleUpdateHeader(
                            currentValue.field,
                            currentValue?.inlineFilterName
                          )
                        }
                      >
                        <img
                          id={`${id}_${currentValue.field}_img`}
                          src={
                            currentValue.hide ? IconAddHeader : IconRemoveHeader
                          }
                          alt={`${currentValue.hide ? "Add" : "Remove"} header`}
                        />
                      </IconButton>
                    }
                  >
                    <Typography variant="subtitle1">
                      {currentValue.headerName}
                    </Typography>
                  </ListItem>
                )
              }
              return prevValue
            }, [])}
          </StyledList>
        }
      />
    </div>
  )
}

export default HeaderSelectionPopover
