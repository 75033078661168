import { StyledTextField } from "./TextField.styles"

const TextField = ({
  id,
  required = false,
  error = false,
  type = "text",
  inputProps = undefined,
  helperText,
  infoHelperText = undefined,
  fullWidth = false,
  rows = 1,
  hidden = false,
  isShort = false,
  size = "small",
  ...props
}) => {
  return (
    <>
      {!hidden && (
        <StyledTextField
          size={size}
          type={type}
          id={id}
          required={required}
          error={error}
          helperText={error ? helperText : infoHelperText ?? ""}
          inputProps={inputProps}
          fullWidth={fullWidth}
          isfullwidth={fullWidth.toString()}
          rows={rows}
          multiline={rows > 1}
          isshort={isShort.toString()}
          {...props}
        />
      )}
    </>
  )
}

export default TextField
