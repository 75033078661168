import {
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Typography
} from "@mui/material"
import React, { useEffect, useState } from "react"

import SearchBar from "../../SearchBarWithDebounce/SearchBar"
import { ButtonText, FormControlCheckAll } from "./Filter.style"
import {
  FilterAll,
  FilterCheckBox,
  FilterContent,
  FilterItem,
  FundsContent,
  SplitFilterContent
} from "./PopoverFilter.style"

const SplitFilter = ({ clearable = true, splitFilters, handleFilter }) => {
  const [search, setSearch] = useState()
  const [selectedOptionList, setSelectedOptionList] = useState()

  useEffect(() => {
    const selectedOptions = {}
    const searchKeys = {}

    splitFilters.forEach(filter => {
      selectedOptions[filter.inlineFilterName] =
        filter.inlineFilterSelected || []
      searchKeys[filter.inlineFilterName] = ""
    })
    setSelectedOptionList(selectedOptions)
    setSearch(searchKeys)
  }, [splitFilters])

  const onChange = (id, name) => {
    const nameSelectedOptions = selectedOptionList?.[name]

    if (nameSelectedOptions.includes(id)) {
      const filterList = nameSelectedOptions.filter(item => item !== id)

      // setRemovedOption([...removedOption, id]);
      setSelectedOptionList(prevSelectedOptionsList => ({
        ...prevSelectedOptionsList,
        [name]: filterList
      }))
    } else {
      // setRemovedOption(removedOption.filter((item) => item !== id));
      setSelectedOptionList(prevSelectedOptionsList => ({
        ...prevSelectedOptionsList,
        [name]: [...nameSelectedOptions, id]
      }))
    }
  }

  const onCheckAll = (checked, name) => {
    const curSplitFilter = splitFilters.filter(
      filter => filter.inlineFilterName === name
    )[0]

    if (checked) {
      const allChecked =
        curSplitFilter.inlineFilterOptions?.map(
          item => item[curSplitFilter.inlineFilterIDField]
        ) ?? []

      setSelectedOptionList(prevSelectedOptions => ({
        ...prevSelectedOptions,
        [name]: allChecked
      }))
    } else {
      setSelectedOptionList(prevSelectedOptions => ({
        ...prevSelectedOptions,
        [name]: []
      }))
    }
  }

  const onSearch = (e, name) => {
    const curSplitFilter = splitFilters.filter(
      filter => filter.inlineFilterName === name
    )[0]

    const filteredList = curSplitFilter.inlineFilterOptions.filter(item => {
      return (
        // && !removedOption.includes(item[idField])
        item[curSplitFilter.inlineFilterLabelField]
          .toLowerCase()
          .includes(e.toLowerCase()) ?? ["", ""]
      )
    })

    const selectedIds = filteredList.map(
      filter => filter[curSplitFilter.inlineFilterIDField]
    )

    setSelectedOptionList(prevSelectedOptions => ({
      ...prevSelectedOptions,
      [name]: selectedIds
    }))
    setSearch(prevSearchKeys => ({ ...prevSearchKeys, [name]: e }))
  }

  const onClear = () => {
    const selectedOptions = {}
    const searchKeys = {}

    splitFilters.forEach(filter => {
      selectedOptions[filter.inlineFilterName] =
        filter.inlineFilterOptions.map(f => f[filter.inlineFilterIDField]) || []
      searchKeys[filter.inlineFilterName] = ""
    })
    setSearch(searchKeys)
    setSelectedOptionList(selectedOptions)
  }

  const handleApply = () => {
    const { filter, selected } = splitFilters.reduce(
      (accumulator, f) => {
        return {
          filter: [...accumulator.filter, f.inlineFilterName],
          selected: [
            ...accumulator.selected,
            selectedOptionList[f.inlineFilterName]
          ]
        }
      },
      { filter: [], selected: [] }
    )

    if (handleFilter) handleFilter(filter, selected)
  }

  return search ? (
    <div id="popover_filter_text">
      <SplitFilterContent>
        {splitFilters.map((filter, index) => {
          const {
            inlineFilterName: name,
            inlineFilterOptions: options,
            inlineFilterLabelField: labelField,
            inlineFilterIDField: idField,
            inlineFilterTitle: title
          } = filter

          return (
            <FilterContent key={name}>
              {title && <Typography variant="formTitle2">{title}</Typography>}
              <SearchBar
                id={`filter_search_${index}`}
                onChange={e => {
                  onSearch(e, name)
                }}
                value={search[name]}
              />
              <FormGroup>
                <FilterAll>
                  <b>
                    <FormControlCheckAll
                      id={"check_all_" + name}
                      key="All"
                      control={
                        <Checkbox
                          checked={
                            selectedOptionList[name].length === options?.length
                          }
                          indeterminate={
                            options &&
                            selectedOptionList.length > 0 &&
                            selectedOptionList.length < options?.length
                          }
                          onChange={(_, checked) => onCheckAll(checked, name)}
                        />
                      }
                      label="All"
                    />
                  </b>
                </FilterAll>
                <FundsContent>
                  {options && options.length > 0 ? (
                    options.reduce((accumulator, cur) => {
                      if (
                        (search &&
                          cur[labelField]
                            .toLowerCase()
                            .includes(search[name].toLowerCase())) ||
                        !search
                      ) {
                        return [
                          ...accumulator,
                          <FilterItem key={cur[idField]}>
                            <FormControlLabel
                              key={cur[idField]}
                              control={
                                <FilterCheckBox
                                  id={`${cur[idField]}_check`}
                                  name={cur[labelField]}
                                  checked={selectedOptionList[name]?.includes(
                                    cur[idField]
                                  )}
                                  onChange={() => onChange(cur[idField], name)}
                                />
                              }
                              label={cur[labelField]}
                            />
                          </FilterItem>
                        ]
                      }
                      return accumulator
                    }, [])
                  ) : (
                    <div>No records</div>
                  )}
                </FundsContent>
              </FormGroup>
            </FilterContent>
          )
        })}
      </SplitFilterContent>
      <Box display="flex" justifyContent="flex-end">
        <ButtonText id="btn_apply" variant="text" onClick={handleApply}>
          Apply Filter
        </ButtonText>
        {clearable && (
          <ButtonText id="btn_clear" variant="text" onClick={onClear}>
            Clear Filter
          </ButtonText>
        )}
      </Box>
    </div>
  ) : (
    <div>No records</div>
  )
}

export default SplitFilter
