import { saveAs } from "file-saver"
import { useEffect, useState } from "react"

// import { getNoticeUrl } from "../../../../services/documents.service"

export const useFileDisplay = ({ file, document }) => {
  const [fileName, setFileName] = useState("")
  const [fileSize, setFileSize] = useState("")
  // eslint-disable-next-line
  const [pdfLink, setPdfLink] = useState("")

  const calculateFileSize = size => {
    if (size < 1000000) {
      const value = Math.floor(size / 1000);
      return value ? (value + "KB") : '';
    } else {
      const value = Math.floor(size / 1000000);
      return value ? (value + "MB") : '';
    }
  }

  const displayFileSize = async () => {
    if (file) {
      setFileSize(calculateFileSize(file[0].size))
    }
    if (document) {
      setFileSize(calculateFileSize(document.size))
      // const res = await getNoticeUrl(document.fileId)

      // setPdfLink(res[document.fileId])
    }
  }

  const displayFileName = () => {
    if (file) {
      setFileName(file[0].name)
    }
    if (document) {
      setFileName(document.name)
    }
  }

  const downloadFile = () => {
    if (file) {
      saveAs(file[0])
    } else {
      saveAs(pdfLink)
    }
  }

  useEffect(() => {
    displayFileName()
    displayFileSize()
    // eslint-disable-next-line
  }, [file, document])

  return {
    fileName,
    fileSize,
    pdfLink,
    downloadFile
  }
}
