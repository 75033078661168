import { LinearProgress, Typography } from "@mui/material";
import { Box, styled } from "@mui/system";

export const StyledBox = styled(Box)(({ theme }) => ({
  display: "grid",
  gridTemplateColumns: "1fr 5% 5% 5%",
  paddingLeft: theme.spacing(1),
  justifyContent: "center",
  alignItems: "center",
}));

export const Container = styled(Box)(({ theme }) => ({
  boxShadow: `0px 3px 8px ${theme.palette.boxshadow.grey}`,
  marginTop: theme.spacing(4),
  borderRadius: theme.spacing(1),
  background: theme.palette.common.white,
}));

export const MultiFileContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(2, 3),
}));

export const StyledTypography = styled(Typography)(({ theme }) => ({
  marginLeft: theme.spacing(1),
  color: theme.palette.grey[600],
}));

export const StyledProgressBar = styled(LinearProgress)(({ theme }) => ({
  width: 150,
  marginLeft: 2,
  borderRadius: 20,
  backgroundColor: theme.palette.common.progressBarBg
}));
