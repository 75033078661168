import {
    ApiClient
} from './web.service';
import {
    APIPATH
} from '../utils/constants/constants';

const apiClient = () => {
    return new ApiClient();
}

export const fetchDropDownCardTheme = async () => {
    return apiClient().get(`${APIPATH.CARDTHEME}/get/dropdown`);
}

export const fetchCardThemeGrid = async (payload) => {
    return apiClient().post(`${APIPATH.CARDTHEME}/list`, payload);
}

export const createCardTheme = async (payload) => {
    return apiClient().post(APIPATH.CARDTHEME, payload);
}

export const updateCardTheme = async (id, payload) => {
    return apiClient().put(`${APIPATH.CARDTHEME}/${id}`, payload);
}

export const fetchByIdCardTheme = async (id) => {
    return apiClient().get(`${APIPATH.CARDTHEME}/${id}`);
}

export const deleteCardTheme = async (id) => {
    return apiClient().delete(`${APIPATH.CARDTHEME}/${id}`)
}