import { useContext, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useForm, useFieldArray } from 'react-hook-form';

import { AppContext } from '../../../core/context/appContextProvider';

import { createPlan, fetchByIdPlan, updatePlan } from '../../../services/plan.service';

const UseAddEditPlanHook = () => {
    const {
        loginUser,
        setLoading,
        informationAlert
    } = useContext(AppContext);

    const navigate = useNavigate();
    const { mode, id } = useParams();

    const {
        control,
        setValue,
        getValues,
        handleSubmit
    } = useForm({
        defaultValues: {
            name: '',
            description: '',
            discountPrice: '0',
            price: '0',
            isDisplay: true,
            services: []
        },
        mode: 'onBlur'
    });

    const services = useFieldArray({
        control: control,
        name: 'services',
    });

    const handleAddChild = () => {
        const index = getValues('services').length;
        services.append({
            index: (index + 1),
            name: '',
        });
    }

    const handleRemoveChild = (index) => {
        services.remove(index);
    }

    const fetchById = async (id) => {
        try {
            if(id) {
                setLoading(true);
                const { success, message, data } = await fetchByIdPlan(id);
                if(success) {
                    console.log(data);
                    setValue('name', data?.name);
                    setValue('description', data?.description);
                    setValue('discountPrice', data?.discountPrice);
                    setValue('price', data?.price);
                    setValue('isDisplay', data?.isDisplay);
                    setValue('services', JSON.parse(data?.services)?.map((res) => ({name: res})))
                    // setValue('routerLink', data?.routerLink);
                    // setValue('icon', data?.icon);
                    // setValue('childRoute', JSON.parse(data?.childRoute));
                } else {
                    informationAlert(message, "error");
                }
            }
        } catch(err) {
            informationAlert(err.message, "error");
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        if(id) {
            fetchById(id);
        }
        // eslint-disable-next-line
    }, [id]);

    const onSubmit = async (info) => {
        if(info.services.length > 0) {
            const payload = JSON.parse(JSON.stringify(info));
            payload['discountPrice'] = parseFloat(info['discountPrice']);
            payload['price'] = parseFloat(info['price']);
            payload['services'] = JSON.stringify(info['services']?.map(res => res?.name));
            try {
                setLoading(true);
                const response = id ? await updatePlan(id, {...payload, updatedBy: loginUser?.id}) : await createPlan({...payload, createdBy: loginUser?.id});
                if(response.success) {
                    navigate('/plan');
                } else {
                    informationAlert(response.message, "error");
                }
            } catch(err) {
                console.error(err);
                informationAlert(err.message, "error");
            } finally {
                setLoading(false);
            }
        } else {
            informationAlert("Please Add Services", "error");
        }
    }
    return {
        mode,
        control,
        services,
        onSubmit,
        getValues,
        handleSubmit,
        handleAddChild,
        handleRemoveChild,
    }
}

export default UseAddEditPlanHook;