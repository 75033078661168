const routingPaths = {
    SigninPath: "login",
    AppDashboard: "/",
    Role: 'role',
    Setting: 'settings',
    CardTheme: 'card-theme',
    User: 'user',
    Module: 'module',
    Right: 'rights',
    Plan: 'plan',
    Coupon: 'coupon',
    AddOn: 'add-on',
    Client: 'client'
}

export default routingPaths;