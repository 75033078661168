import {
    ApiClient
} from './web.service';
import {
    APIPATH
} from '../utils/constants/constants';

const apiClient = () => {
    return new ApiClient();
}

export const fetchClientGrid = async (payload) => {
    return apiClient().post(`${APIPATH.CLIENT}/list`, payload);
}

export const createClient = async (payload) => {
    return apiClient().post(APIPATH.CLIENT, payload);
}

export const updateClientDetail = async (id, payload) => {
    return apiClient().put(`${APIPATH.CLIENT}/detail/${id}`, payload);
}

export const updateClientDocument = async (payload) => {
    return apiClient().post(`${APIPATH.CLIENT}/document`, payload);
}

export const fetchClientDocument = async (clientID) => {
    return apiClient().get(`${APIPATH.CLIENT}/document/${clientID}`);
}

export const deleteClientDocument = async (id) => {
    return apiClient().delete(`${APIPATH.CLIENT}/document/${id}`);
}

export const addClientService = async (payload) => {
    return apiClient().post(`${APIPATH.CLIENT}/service`, payload);
}

export const updateClientService = async (id, payload) => {
    return apiClient().put(`${APIPATH.CLIENT}/service/${id}`, payload);
}

export const fetchClientService = async (clientID) => {
    return apiClient().get(`${APIPATH.CLIENT}/service/${clientID}`);
}

export const deleteClientService = async (id) => {
    return apiClient().delete(`${APIPATH.CLIENT}/service/${id}`);
}

export const updateClientGallery = async (clientID,payload) => {
    return apiClient().put(`${APIPATH.CLIENT}/gallery/${clientID}`, payload);
}

export const fetchClientGallery = async (clientID) => {
    return apiClient().get(`${APIPATH.CLIENT}/gallery/${clientID}`);
}

export const deleteClientGallery = async (galleryID) => {
    return apiClient().delete(`${APIPATH.CLIENT}/gallery/${galleryID}`);
}

export const updateClientBankDetail = async (payload) => {
    return apiClient().post(`${APIPATH.CLIENT}/bankDetail`, payload);
}

export const fetchClientBankDetail = async (clientID) => {
    return apiClient().get(`${APIPATH.CLIENT}/bankDetail/${clientID}`);
}

export const deleteClientBankDetail = async (id) => {
    return apiClient().delete(`${APIPATH.CLIENT}/bankDetail/${id}`);
}

export const updateClientSocialMedia = async (payload) => {
    return apiClient().post(`${APIPATH.CLIENT}/socialMedia`, payload);
}

export const fetchClientSocialMedia = async (clientID) => {
    return apiClient().get(`${APIPATH.CLIENT}/socialMedia/${clientID}`);
}

export const updateClientOnlinePay = async (payload) => {
    return apiClient().post(`${APIPATH.CLIENT}/onlinePay`, payload);
}

export const fetchClientOnlinePay = async (clientID) => {
    return apiClient().get(`${APIPATH.CLIENT}/onlinePay/${clientID}`);
}

export const updateClientVideoLink = async (payload) => {
    return apiClient().post(`${APIPATH.CLIENT}/videoLink`, payload);
}

export const fetchClientVideoLink = async (clientID) => {
    return apiClient().get(`${APIPATH.CLIENT}/videoLink/${clientID}`);
}

export const deleteClientVideoLink = async (id) => {
    return apiClient().delete(`${APIPATH.CLIENT}/videoLink/${id}`);
}

export const updateClient = async (id, payload) => {
    return apiClient().put(`${APIPATH.CLIENT}/${id}`, payload);
}

export const fetchByIdClient = async (id) => {
    return apiClient().get(`${APIPATH.CLIENT}/${id}`);
}

export const deleteClient = async (id) => {
    return apiClient().delete(`${APIPATH.CLIENT}/${id}`)
}