import {
  Box,
  Modal,
  styled,
  Typography,
} from "@mui/material";

import { PRIMARY } from "../../../styles/colors";

export const StyledModal = styled(Modal)`
  .MuiBackdrop-root {
    background-color: rgba(255, 255, 255, 0.7);
  }
`;

export const StyledBox = styled(Box)`
  position: absolute;
  display: flex;
  align-items: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  box-shadow: 0px 0px 26px -7px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: 0px 0px 26px -7px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 26px -7px rgba(0, 0, 0, 0.75);
  border-radius: 8px;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 20px;
  padding-bottom: 20px;
  min-width: 400px;

  :focus-visible {
    outline: none;
  }
`;


export const ProgressText = styled(Typography)`
  color: ${PRIMARY};
  margin-left: 10px;
`;
