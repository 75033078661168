import {
    ApiClient
} from './web.service';
import {
    APIPATH
} from '../utils/constants/constants';

const apiClient = () => {
    return new ApiClient();
}

export const fetchModuleDropDown = async () => {
    return apiClient().get(`${APIPATH.MODULE}/get/dropdown`);
}

export const fetchModuleGrid = async (payload) => {
    return apiClient().post(`${APIPATH.MODULE}/list`, payload);
}

export const createModule = async (payload) => {
    return apiClient().post(APIPATH.MODULE, payload);
}

export const updateModule = async (id, payload) => {
    return apiClient().put(`${APIPATH.MODULE}/${id}`, payload);
}

export const fetchByIdModule = async (id) => {
    return apiClient().get(`${APIPATH.MODULE}/${id}`);
}

export const deleteModule = async (id) => {
    return apiClient().delete(`${APIPATH.MODULE}/${id}`)
}