import { styled, TextField } from "@mui/material";

export const StyledTextField = styled(TextField)`
  width: ${(props) => {
    if (props.isshort === "true") return "160px";
    else if (props.isfullwidth === "false") return "320px";
    else return "";
  }};
  margin: 10px;
  input[type="number"]::-webkit-outer-spin-button,
  input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;
