
import useDashboard from "./dashboard.hooks";

const Dashboard = () => {
    const { data } = useDashboard();
    console.log(data);
    return (
        <>
            <h3>Dashboard Coming Soon</h3>
        </>
    )
}

export default Dashboard;