
import { memo } from "react";
import { useNavigate } from "react-router-dom";

import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";

import AddIcon from "@mui/icons-material/Add";

import Button from "../../components/Buttons/Button/Button";
import DataGrid from "../../components/DataGrid/DataGrid";
import DeleteConfirmationDialog from "../../components/Modal/DeleteConfirmationDialog/DeleteConfirmationDialog";

import UseRoleHooks from "./role.hooks";
import { HeaderRow } from "./role.styles";

const Role = () => {
    const {
        list,
        headerList,
        rightForRole,
        showDeleteModal,
        handleDelete,
        setShowDeleteModal
    } = UseRoleHooks();
    const navigate = useNavigate();
    return (
        <Container maxWidth={false}>
            <Box textAlign={'left'}>
                <HeaderRow>
                    <Typography variant="pageTitle">Role</Typography>
                    {rightForRole.add ?
                        <Button
                            id="btn_add_new_role"
                            variant="contained"
                            icon={<AddIcon />}
                            text="New Role"
                            color="primary"
                            onClick={() => navigate('/role/add')}
                        />
                    : null}
                </HeaderRow>
                <DataGrid
                    id="client_data_grid"
                    dataList={list || []}
                    headerList={headerList || []}
                    minHeight="70vh"
                    checkboxSelection={false}
                    // noDataMessage={(loadingClients || loading) ? "Fetching Data..." : undefined}
                    autoHeight={false}
                    // handleOnView={handleOnView}
                    activeHeaderFields={6}
                    // handleUpdateHeader={handleUpdateHeader}
                />
            </Box>
            <DeleteConfirmationDialog
                open={showDeleteModal}
                id="delete_confirmation_modal"
                primaryButtonAction={handleDelete}
                secondaryButtonAction={() => setShowDeleteModal(false)}
            />
        </Container>
    )
}

export default memo(Role);