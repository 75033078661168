import { useNavigate } from "react-router-dom";
import { Controller } from "react-hook-form";

import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Switch from '@mui/material/Switch';

import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete"

import Button from "../../../components/Buttons/Button/Button";
import { DIGIT_REGEX } from "../../../utils/constants/constants";
import { HeaderRow } from "../plan.styles";

import UseAddEditPlanHook from "./addEditPlan.hooks";

const AddEditPlan = () => {
    const {
        mode,
        control,
        services,
        onSubmit,
        handleSubmit,
        handleAddChild,
        handleRemoveChild
    } = UseAddEditPlanHook();
    const navigate = useNavigate();

    return (
        <Container maxWidth={false}>
            <Box textAlign={'left'}>
                <HeaderRow>
                    <Typography variant="pageTitle" textTransform={"capitalize"}>{mode} Plan</Typography>
                    {/* <Button
                        id="btn_back"
                        variant="contained"
                        text="Back"
                        color="primary"
                        onClick={() => navigate('/role')}
                    /> */}
                </HeaderRow>
                <Box>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Grid container>
                            <Grid item xs={12} sm={4}>
                                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                    <Controller
                                        control={control}
                                        name="isDisplay"
                                        render={({
                                            field: { onBlur, onChange, value },
                                        }) => (
                                            <FormControl size="small" variant="standard">
                                                <FormLabel>Is Display on Website</FormLabel>
                                                <Switch
                                                    checked={value}
                                                    onChange={onChange}
                                                    onBlur={onBlur}
                                                />
                                            </FormControl>
                                        )}
                                    />
                                    <Controller
                                        control={control}
                                        name="name"
                                        render={({
                                            field: { onBlur, onChange, value },
                                            fieldState: { error },
                                        }) => (
                                            <FormControl size="small" variant="standard">
                                                <TextField
                                                    label="Name*"
                                                    size="small"
                                                    name="name"
                                                    value={value}
                                                    variant="outlined"
                                                    onChange={onChange}
                                                    inputProps={{ style: { textTransform: 'capitalize' } }}
                                                    onBlur={onBlur}
                                                    error={!!error}
                                                    helperText={error?.message}
                                                />
                                            </FormControl>
                                        )}
                                        rules={{
                                            required: "Please Enter Name",
                                        }}
                                    />
                                    <br />
                                    <Controller
                                        control={control}
                                        name="description"
                                        render={({
                                            field: { onBlur, onChange, value },
                                            fieldState: { error },
                                        }) => (
                                            <FormControl size="small" variant="standard">
                                                <TextField
                                                    label="Description*"
                                                    size="small"
                                                    name="description"
                                                    value={value}
                                                    variant="outlined"
                                                    onChange={onChange}
                                                    onBlur={onBlur}
                                                    error={!!error}
                                                    helperText={error?.message}
                                                    multiline
                                                    rows={3}
                                                />
                                            </FormControl>
                                        )}
                                        rules={{
                                            required: "Please Enter Description",
                                        }}
                                    />
                                    <br />
                                    <Controller
                                        control={control}
                                        name="discountPrice"
                                        render={({
                                            field: { onBlur, onChange, value },
                                            fieldState: { error },
                                        }) => (
                                            <FormControl size="small" variant="standard">
                                                <TextField
                                                    label="Discount Price*"
                                                    size="small"
                                                    name="discountPrice"
                                                    value={value}
                                                    variant="outlined"
                                                    onChange={onChange}
                                                    inputProps={{ style: { textTransform: 'capitalize' } }}
                                                    onBlur={onBlur}
                                                    error={!!error}
                                                    helperText={error?.message}
                                                />
                                            </FormControl>
                                        )}
                                        rules={{
                                            required: "Please Enter Discount Price",
                                            pattern: {
                                                value: DIGIT_REGEX,
                                                message: 'Please Enter Valid Discount Price'
                                            }
                                        }}
                                    />
                                    <br/>
                                    <Controller
                                        control={control}
                                        name="price"
                                        render={({
                                            field: { onBlur, onChange, value },
                                            fieldState: { error },
                                        }) => (
                                            <FormControl size="small" variant="standard">
                                                <TextField
                                                    label="Price*"
                                                    size="small"
                                                    name="price"
                                                    value={value}
                                                    variant="outlined"
                                                    onChange={onChange}
                                                    inputProps={{ style: { textTransform: 'capitalize' } }}
                                                    onBlur={onBlur}
                                                    error={!!error}
                                                    helperText={error?.message}
                                                />
                                            </FormControl>
                                        )}
                                        rules={{
                                            required: "Please Enter Price",
                                            pattern: {
                                                value: DIGIT_REGEX,
                                                message: 'Please Enter Valid Price'
                                            }
                                        }}
                                    />
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={8}>
                                {services.fields.length === 0 ?
                                    <Button
                                        id="btn_add_child"
                                        variant="contained"
                                        type="button"
                                        text="Add Services"
                                        color="primary"
                                        addSpaceBetweenButtons={true}
                                        onClick={handleAddChild}
                                    />
                                    : null}
                                <br />
                                <Box>
                                    {services.fields?.map((item, index) => (
                                        <Box sx={{ display: 'flex', margin: '10px', gap: '10px' }} key={index} id={index}>
                                            {services.fields.length === (index + 1) ?
                                                <Button
                                                    id="btn_remove_child"
                                                    size="small"
                                                    variant="contained"
                                                    icon={<AddIcon />}
                                                    type="button"
                                                    text=""
                                                    color="primary"
                                                    addSpaceBetweenButtons={true}
                                                    onClick={handleAddChild}
                                                />
                                                :
                                                <Box sx={{ margin: '0px 42px' }} />
                                            }
                                            <Controller
                                                control={control}
                                                name={`services.${index}.name`}
                                                render={({
                                                    field: { onBlur, onChange, value },
                                                    fieldState: { error },
                                                }) => (
                                                    <FormControl size="small" fullWidth variant="standard">
                                                        <TextField
                                                            label="Name*"
                                                            size="small"
                                                            name="name"
                                                            value={value}
                                                            variant="outlined"
                                                            onChange={onChange}
                                                            inputProps={{ style: { textTransform: 'capitalize' } }}
                                                            onBlur={onBlur}
                                                            error={!!error}
                                                            helperText={error?.message}
                                                        />
                                                    </FormControl>
                                                )}
                                                rules={{
                                                    required: "Please Enter Name",
                                                }}
                                            />
                                            <Button
                                                id="btn_remove_child"
                                                size="small"
                                                variant="contained"
                                                icon={<DeleteIcon />}
                                                type="button"
                                                text=""
                                                color="primary"
                                                addSpaceBetweenButtons={true}
                                                onClick={() => handleRemoveChild(index)}
                                            />
                                        </Box>
                                    ))}
                                </Box>
                            </Grid>
                        </Grid>
                        {/* <br/>
                        <Grid container>
                            <Grid item xs={12} sm={4}>

                            </Grid>
                        </Grid>
                        <br/>
                        <Grid container>
                            <Grid item xs={12} sm={4}>

                            </Grid>
                        </Grid> */}
                        <Box sx={{ display: 'flex', gap: '12px', padding: '12px 0px' }}>
                            <Button
                                id="btn_back"
                                variant="contained"
                                type="button"
                                text="Back"
                                color="primary"
                                onClick={() => navigate('/plan')}
                            />
                            <Button
                                id="btn_back"
                                variant="contained"
                                type="submit"
                                text={mode}
                                color="primary"
                            />
                        </Box>
                    </form>
                </Box>
            </Box>
        </Container>
    )
}

export default AddEditPlan;