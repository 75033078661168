import { useContext, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useForm, useFieldArray } from 'react-hook-form';

import { AppContext } from '../../../core/context/appContextProvider';

import { createModule, fetchByIdModule, updateModule } from '../../../services/module.service';

const UseAddEditModuleHook = () => {
    const {
        loginUser,
        setLoading,
        informationAlert
    } = useContext(AppContext);

    const navigate = useNavigate();
    const { mode, id } = useParams();

    const {
        control,
        setValue,
        getValues,
        handleSubmit
    } = useForm({
        defaultValues: {
            name: '',
            icon: '',
            routerLink: '',
            childRoute: [],
        },
        mode: 'onBlur'
    });

    const childRoute = useFieldArray({
        control: control,
        name: 'childRoute',
    });

    const handleAddChild = () => {
        const index = getValues('childRoute').length;
        childRoute.append({
            index: (index + 1),
            name: '',
            routerLink: ''
        });
    }

    const handleRemoveChild = (index) => {
        childRoute.remove(index);
    }

    const fetchById = async (id) => {
        try {
            if(id) {
                setLoading(true);
                const { success, message, data } = await fetchByIdModule(id);
                if(success) {
                    setValue('name', data?.name);
                    setValue('routerLink', data?.routerLink);
                    setValue('icon', data?.icon);
                    setValue('childRoute', JSON.parse(data?.childRoute));
                } else {
                    informationAlert(message, "error");
                }
            }
        } catch(err) {
            informationAlert(err.message, "error");
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        if(id) {
            fetchById(id);
        }
        // eslint-disable-next-line
    }, [id]);

    const onSubmit = async (info) => {
        const payload = JSON.parse(JSON.stringify(info));
        payload['childRoute'] = JSON.stringify(info['childRoute']);
        payload['routerLink'] = info.childRoute.length > 0 ? '/' : payload['routerLink']
        try {
            setLoading(true);
            const response = id ? await updateModule(id, {...payload, updatedBy: loginUser?.id}) : await createModule({...payload, createdBy: loginUser?.id});
            if(response.success) {
                navigate('/module');
            } else {
                informationAlert(response.message, "error");
            }
        } catch(err) {
            console.error(err);
            informationAlert(err.message, "error");
        } finally {
            setLoading(false);
        }
    }
    return {
        mode,
        control,
        childRoute,
        onSubmit,
        getValues,
        handleSubmit,
        handleAddChild,
        handleRemoveChild,
    }
}

export default UseAddEditModuleHook;