export const PRIMARY = "#007aff";
export const PRIMARY_HOVER = "#4aa1ff";
export const PRIMARY_PRESSED = "#0d68ca";
export const PRIMARY_DISABLED = "#c2c2c6";
export const SECONDARY = "#fafafb";
export const SECONDARY_HOVER = "#ffffff";
export const SECONDARY_HOVER_TEXT = "#4AA1FF";
export const SECONDARY_PRESSED_TEXT = "#0D68CA";
export const SECONDARY_DISABLED_TEXT = "#C2C2C6";
export const ERROR = "#E04F50";
export const ERROR_HOVER = "#FFF0EF";
export const ERROR_HOVER_TEXT = "#E8807F";
export const ERROR_PRESSED_TEXT = "#DA1F2F";
export const FONT_PRIMARY = "#383842";
export const WHITE = "#FFFFFF";
export const BLACK = "#000000";
export const ACTIVE_GREEN = "#5BBC76";
export const SUBTITLE = "#383842";
export const SWITCH_ENABLED = "#5ABC76";
export const SWITCH_ENABLED_BACKGROUD = "#BBE1BD";
export const SWITCH_DISABLED = "#E04F50";
export const SWITCH_DISABLED_BACKGROUD = "#DBA4A4";
export const ITEM_LIST_BACKGROUND = "#E1EDFB";
export const SPLIT_BUTTON_BACKGROUND = "#E9F2F8";
export const ROW_HOVER_BACKGROUND_COLOR = "#E6F2FF";
export const GRID_HEADER_BACKGROUND_COLOR = "#EFEEF0";
export const GRID_HEADER_BORDER_COLOR = "#D3D3D6";
export const CHIP_GREEN = "#DEF2E4";
export const CHIP_BLUE = "#E6F2FF";
export const LIST_DIVIDER = "#BFBFBF";
export const ACCORDION_CHILD_BACKGROUND = "#F2F8FF";
export const DATAGRID_TOP_HEADER = "#838387";
export const INPUT_GREY_OUTLINE = "#3C3C43";
export const PENDING_YELLOW_COLOR = "#EBC500";
