import Stack from "@mui/material/Stack"
import Typography from "@mui/material/Typography"
import { useState } from "react"

import { M_DASH_UNICODE } from "../../../utils/constants/constants"
import ChipsCell from "./ChipsCell"

const ChipCellStack = ({ header, items, row }) => {
  const [hover, setHover] = useState(false)

  return (
    <Stack
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      flexDirection="row"
      justifyContent="space-between"
      alignItems="center"
      sx={{
        width: "100%"
      }}
    >
      {items.length === 0 ? (
        <Typography variant="cells">{M_DASH_UNICODE}</Typography>
      ) : (
        <ChipsCell
          name={row.name || ""}
          items={items}
          chipLabelField={header.chipLabelField ?? ""}
          chipIDField={header.chipIDField ?? ""}
          chipCount={header.chipCount ?? 1}
          onChipClick={header.onChipClick}
          toggleVisibilityOnClick={header.toggleVisibilityOnClick}
        />
      )}
      {header.renderOnHover && hover && header.renderOnHover?.(row)}
    </Stack>
  )
}

export default ChipCellStack
